import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PlayerState = {
  isStarted: boolean;
  isFinished: boolean;
  hasError: boolean;
  isPlaying: boolean;
  playbackRate: number;
  volume: number;
  playedSeconds: number;
  bufferedSeconds: number;
  durationSeconds: number;
  isFullscreen: boolean;
};

export const initialState: PlayerState = {
  isStarted: false,
  isFinished: false,
  hasError: false,
  isPlaying: false,
  playbackRate: 1,
  volume: 0.8,
  playedSeconds: 0,
  bufferedSeconds: 0,
  durationSeconds: 0,
  isFullscreen: false,
};

const playbackRates = [0.5, 0.7, 1, 1.2, 1.5, 1.7, 2, 2.5];

const getNextPlaybackRate = (currentRate: number): number => {
  const index = playbackRates.findIndex((rate) => rate === currentRate);
  return playbackRates[index + 1] || playbackRates[0];
};

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    end: (state, action: PayloadAction<number>) => {
      state.durationSeconds = action.payload;
      state.isPlaying = false;
      state.isFinished = true;
    },
    reset: (state) => {
      state.isStarted = false;
      state.isPlaying = false;
      state.isFinished = false;
      state.playedSeconds = 0;
    },
    start: (state) => {
      state.isStarted = true;
      state.isPlaying = true;
      state.isFinished = false;
      state.playedSeconds = 0;
    },
    finish: (state, action: PayloadAction<boolean>) => {
      state.isFinished = action.payload;
    },
    play: (state) => {
      state.isPlaying = true;
    },
    pause: (state) => {
      state.isPlaying = false;
    },
    togglePlay: (state) => {
      state.isPlaying = !state.isPlaying;
    },
    nextPlaybackRate: (state) => {
      state.playbackRate = getNextPlaybackRate(state.playbackRate);
    },
    setDuration: (state, action: PayloadAction<number>) => {
      state.durationSeconds = action.payload;
    },
    setPlayedSeconds: (state, action: PayloadAction<number>) => {
      state.playedSeconds = action.payload;
    },
    setBufferedSeconds: (state, action: PayloadAction<number>) => {
      state.bufferedSeconds = action.payload;
    },
    setVolume: (state, action: PayloadAction<number>) => {
      state.volume = action.payload;
    },
    toggleVolume: (state) => {
      state.volume = state.volume > 0 ? 0 : 0.8;
    },
    error: (state) => {
      state.hasError = true;
    },
    setFullscreen: (state, action: PayloadAction<boolean>) => {
      state.isFullscreen = action.payload;
    },
  },
});

export const { actions, reducer } = playerSlice;

import { FC } from 'react';

import cn from 'classnames';

import { usePlayer, usePlayerActions } from '../../hooks';

export const PlayerBubbleOverlay: FC = () => {
  const { state } = usePlayer();
  const { handleToggle } = usePlayerActions();

  const { isPlaying, isFinished } = state;

  return (
    <div
      onClick={isFinished ? undefined : handleToggle}
      className={cn(
        'absolute inset-0 flex flex-col gap-y-4 items-center justify-center cursor-pointer',
        {
          'bg-base-black/50': !isPlaying,
        }
      )}
    />
  );
};

import React, { useCallback } from 'react';
import { gatedContentModel } from '../model';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../editor-sidebar';

import { ConversionControlItem } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { narrationModel } from '../../narration';

type IProps = {
  isDisabled: boolean;
  isHidden: boolean;
};

export const GatedContentControls: React.FC<IProps> = ({
  isDisabled,
  isHidden,
}) => {
  const dispatch = useDispatch();

  const isBlockShows = useSelector(
    gatedContentModel.selectors.selectGatedContentIsModalOpen
  );

  const toggleIsGatedContentOpen = useCallback(() => {
    dispatch(narrationModel.actions.setPopupOpen(false));
    dispatch(gatedContentModel.actions.setIsModalOpen(!isBlockShows));
  }, [dispatch, isBlockShows]);

  const handleOpenPopUpSettings = () => {
    dispatch(narrationModel.actions.setPopupOpen(false));
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.GATED_CONTENT
      )
    );
  };

  if (isHidden) return null;

  return (
    <ConversionControlItem
      title="Gated content"
      icon={IconMap.GatedContentIcon}
      isOpened={isBlockShows}
      isDisabled={isDisabled}
      onEdit={handleOpenPopUpSettings}
      onPreview={toggleIsGatedContentOpen}
    />
  );
};

import { Task } from '@distribute/shared/types';
import { api } from './instance';

export const tasksApi = {
  async createTask() {
    const { data: responseData } = await api.post<Task>('tasks/create-task');
    return responseData;
  },

  async deleteTask(taskId: string) {
    const { data } = await api.delete<void>(`tasks/${taskId}`);
    return data;
  },
};

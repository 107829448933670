import { pagesModel } from '..';
import { actions } from '../reducer';
import { put, take, delay, race } from 'redux-saga/effects';

export function* setContentNavigation({
  payload,
}: ReturnType<typeof pagesModel.actions.setContentNavigation>) {
  yield put(actions.toggleContentNavigation(payload));

  yield race({
    delay: delay(5000),
    action: take(actions.setCurrentContent.type),
  });

  yield put(actions.toggleContentNavigation(null));
}

import { call, put } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import { finishTeamOnboarding } from './finishTeamOnboarding';
import { subscriptionApi } from '../../../../shared/api';
import { PlanName, SubscriptionInterval } from '@distribute/shared/types';

export function* createSubscriptionTrial({
  teamId,
  interval,
  plan,
}: {
  teamId: number;
  interval: SubscriptionInterval;
  plan: PlanName;
}) {
  try {
    yield put(actions.setIsLoadingCreateSubscriptionTrial(true));
    yield call(subscriptionApi.createSubscription, {
      teamId,
      interval,
      plan,
    });
    yield call(finishTeamOnboarding);
  } catch (error: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to start trial.')
      )
    );
  } finally {
    yield put(actions.setIsLoadingCreateSubscriptionTrial(false));
  }
}

import {
  Folder,
  FolderTeamMembersPermission,
  User,
  UserPermissionToFolderRole,
} from '@distribute/shared/types';
import { TeamPermissions } from '../../teams/lib';

export type FolderPermissions = {
  isCanDelete: boolean;
  isCanRename: boolean;
  isCanManageAccess: boolean;
  isCanInviteMembers: boolean;
  isCanCreatePages: boolean;
  isCanViewPages: boolean;
  isCanEditPages: boolean;
  isCanDuplicatePages: boolean;
  isCanMovePagesToAnotherFolder: boolean;
  isCanMovePagesToAnotherTeam: boolean;
  isCanDeleteAllPages: boolean;
  isCanTransferOwnership: boolean;
  isCanPublishPages: boolean;
  isFolderSharedWithUser: boolean;
};

export const getFolderPermissions = (
  currentUser: User,
  folder: Folder | undefined,
  teamPermissions: TeamPermissions
): FolderPermissions => {
  const currentUserRole = folder?.userPermissionToFolders.find(
    (item) => item.user.id === currentUser.id
  );
  const isOwner = currentUserRole?.role === UserPermissionToFolderRole.OWNER;
  const isOwnerOrEditor = currentUserRole
    ? currentUserRole.role === UserPermissionToFolderRole.OWNER ||
      currentUserRole.role === UserPermissionToFolderRole.EDITOR
    : folder?.teamMembersPermission === FolderTeamMembersPermission.EDIT;
  const isFolderSharedWithUser =
    folder?.teamMembersPermission !== FolderTeamMembersPermission.INVITE_ONLY ||
    !!currentUserRole;

  return {
    isCanDelete: (isOwner || teamPermissions.isOwner) && isFolderSharedWithUser,
    isCanRename: (isOwner || teamPermissions.isOwner) && isFolderSharedWithUser,
    isCanManageAccess:
      (isOwnerOrEditor || teamPermissions.isOwner) && isFolderSharedWithUser,
    isCanInviteMembers:
      (isOwner || teamPermissions.isOwner) && isFolderSharedWithUser,
    isCanCreatePages:
      ((isOwnerOrEditor || teamPermissions.isCanCreatePages) &&
        isFolderSharedWithUser) ||
      !folder,
    isCanViewPages: true,
    isCanEditPages:
      (isOwnerOrEditor || teamPermissions.isOwner) && isFolderSharedWithUser,
    isCanDuplicatePages:
      (isOwnerOrEditor || teamPermissions.isOwner) && isFolderSharedWithUser,
    isFolderSharedWithUser,
    isCanMovePagesToAnotherFolder:
      (isOwner || teamPermissions.isOwner) && isFolderSharedWithUser,
    isCanMovePagesToAnotherTeam: isOwner,
    isCanDeleteAllPages:
      (isOwnerOrEditor || teamPermissions.isOwner) && isFolderSharedWithUser,
    isCanTransferOwnership:
      (isOwner || teamPermissions.isOwner) && isFolderSharedWithUser,
    isCanPublishPages:
      (isOwnerOrEditor || teamPermissions.isOwner) && isFolderSharedWithUser,
  };
};

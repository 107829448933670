import { Dispatch, createContext } from 'react';
import { AnyAction } from '@reduxjs/toolkit';

import { PlayerEntry, PlayerSettings } from '../../types';
import { PlayerState } from './reducer';

export const PlayerContext = createContext<{
  state: PlayerState;
  config: PlayerEntry & PlayerSettings;
  dispatch: Dispatch<AnyAction>;
} | null>(null);

import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { SubscribePopup } from '../../../../features/subscribe-popup';

import {
  GatedContentControls,
  gatedContentModel,
} from '../../../../features/gated-content-block';

import {
  SqueezePageConversionControl,
  squeezePageModel,
} from '../../../../features/squeeze-page';

import { RemoveScroll } from 'react-remove-scroll';

import { pagesModel } from '../../../../features/pages';
import { narrationModel } from '../../../../features/narration';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { conversionKitModel } from '../../../../features/conversion-kit';

type Props = {
  isTemplateMode: boolean;
};

const CONVERSION_CONTROL_ITEM_HEIGHT = 68;
const CONVERSION_CONTROL_ITEMS_GAP = 16;

export const ConversionControls: FC<Props> = () => {
  const isNarrationRecordingSessionActive = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionActive
  );

  const isEditorSidebarOpen = useSelector(
    editorSidebarModel.selectors.selectIsSidebarOpen
  );
  const currentPage = useSelector(pagesModel.selectors.selectCurrentPage);
  const isUploadMenuOpen = useSelector(
    pagesModel.selectors.selectPageCoverImageMenuOpen
  );
  const popUp = useSelector(conversionKitModel.selectors.selectPopUp);
  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContent
  );
  const squeezePage = useSelector(
    conversionKitModel.selectors.selectSqueezePage
  );
  const isGatedContentModalOpen = useSelector(
    gatedContentModel.selectors.selectGatedContentIsModalOpen
  );
  const isSqueezePagePreviewOpen = useSelector(
    squeezePageModel.selectors.selectIsOpen
  );

  const isAnyConversionPanelOpen = useSelector(
    editorSidebarModel.selectors.selectIsAnyConversionPanelOpen
  );

  const hasCoverImage = currentPage?.content.coverImageUrl || isUploadMenuOpen;

  const getTopPosition = useCallback(
    (index: number) => {
      const prevItemsHeight =
        index * (CONVERSION_CONTROL_ITEMS_GAP + CONVERSION_CONTROL_ITEM_HEIGHT);
      if (hasCoverImage) {
        return `${prevItemsHeight + 300}px`;
      }

      return `calc(38% + ${prevItemsHeight}px)`;
    },
    [hasCoverImage]
  );

  const items = useMemo(
    () =>
      [
        {
          id: 'popup',
          isActive: popUp?.isActive,
          Component: SubscribePopup,
          styles: { top: 0, zIndex: 50 },
          isDisabled: isGatedContentModalOpen || isSqueezePagePreviewOpen,
          isHidden: !popUp?.isActive || isAnyConversionPanelOpen,
        },
        {
          id: 'gatedContent',
          isActive: gatedContent?.isActive,
          Component: GatedContentControls,
          styles: { top: 0, zIndex: 30 },
          isDisabled: isSqueezePagePreviewOpen,
          isHidden: !gatedContent?.isActive || isAnyConversionPanelOpen,
        },
        {
          id: 'squeezePage',
          isActive: squeezePage?.isActive,
          Component: SqueezePageConversionControl,
          styles: { top: 0, zIndex: 30 },
          isDisabled: isGatedContentModalOpen,
          isHidden: !squeezePage?.isActive || isAnyConversionPanelOpen,
        },
      ]
        .filter((i) => i.isActive)
        .map((item, index) => ({
          ...item,
          styles: { ...item.styles, top: getTopPosition(index) },
        })),
    [
      popUp?.isActive,
      gatedContent?.isActive,
      squeezePage?.isActive,
      isAnyConversionPanelOpen,
      isGatedContentModalOpen,
      isSqueezePagePreviewOpen,
      getTopPosition,
    ]
  );

  return (
    <>
      {items.map(({ Component, styles, id, isDisabled, isHidden }) => (
        <div
          key={id}
          style={styles}
          className={classNames(
            'fixed -translate-y-1/3  transition-all md:right-4 sm:hidden',
            RemoveScroll.classNames.fullWidth,
            {
              'right-126': isEditorSidebarOpen,
              'right-6': !isEditorSidebarOpen,
            }
          )}
        >
          <Component
            isDisabled={isDisabled}
            isHidden={isHidden || isNarrationRecordingSessionActive}
          />
        </div>
      ))}
    </>
  );
};

import { noAudio, noVideo } from '../../../config';
import { getAudioMedia, getVideoMedia } from './get-media';

type MediaSteamParams = {
  videoId: string;
  audioId: string;
};

type Response = {
  mediaStream: MediaStream | null;
  supportTracks: MediaStreamTrack[];
};

export const getMediaStream = async ({
  videoId,
  audioId,
}: MediaSteamParams): Promise<Response> => {
  if (videoId === noVideo.id) {
    throw new Error('no video id');
  }

  const supportTracks: MediaStreamTrack[] = [];

  const mediaStream = await getVideoMedia(videoId);
  const mediaStreamTracks = mediaStream.getVideoTracks();

  if (!mediaStreamTracks.length) {
    throw new Error('no video media stream');
  }

  if (audioId !== noAudio.id) {
    const audioMedia = await getAudioMedia(audioId);
    const audioMediaTracks = audioMedia.getTracks();

    if (!audioMediaTracks.length) {
      throw new Error('no audio media stream');
    }

    supportTracks.push(...audioMediaTracks);
    mediaStream.addTrack(audioMediaTracks[0]);
  }

  return { mediaStream, supportTracks };
};

import { formatDateShort } from '@distribute/shared/utils';

export const addTasklistData = () => {
  const tasklists = document.querySelectorAll('.custom-tasklist');

  for (let i = 0; i < tasklists.length; i++) {
    const tasklist = tasklists[i];

    const tasklistCompletedText = tasklist.querySelector(
      '.custom-tasklist__completed'
    );

    let tasksAmount = 0;
    let completedTasksAmount = 0;

    const tasklistContent = tasklist.querySelector('.custom-tasklist__content');

    if (tasklistContent) {
      const tasklistItems = tasklistContent.children;

      if (tasklistItems) {
        for (let i = 0; i < tasklistItems.length; i++) {
          const item = tasklistItems[i];
          const isVisible = item.getAttribute('data-visible');
          const isDone = item.getAttribute('data-done');
          const dueDate = item.getAttribute('data-date');

          if (isVisible === 'true') {
            tasksAmount += 1;

            if (isDone === 'true') {
              completedTasksAmount += 1;
            }
          }

          const titleWrapper = item.querySelector(
            '.custom-tasklist__item-title-wrapper'
          );

          if (titleWrapper) {
            const dateDiv = document.createElement('div');
            dateDiv.classList.add('custom-tasklist-item__date-wrapper');
            const dateText = document.createElement('span');
            const calendarIcon = document.createElement('img');

            if (dueDate) {
              calendarIcon.setAttribute(
                'src',
                'https://storage.googleapis.com/distribute-production-storage/general/calendar-gray.svg'
              );
              dateDiv.classList.add('custom-tasklist-item__date-wrapper--full');
              dateText.innerHTML = formatDateShort(dueDate);
            } else {
              calendarIcon.setAttribute(
                'src',
                'https://storage.googleapis.com/distribute-production-storage/general/calendar-light.svg'
              );
              dateDiv.classList.add(
                'custom-tasklist-item__date-wrapper--empty'
              );
              dateText.innerHTML = 'none';
            }

            dateDiv.appendChild(calendarIcon);
            dateDiv.appendChild(dateText);

            titleWrapper.appendChild(dateDiv);
          }
        }
      }
    }

    if (tasklistCompletedText) {
      tasklistCompletedText.innerHTML = `${completedTasksAmount}/${tasksAmount} completed`;
    }
  }
};

import { Node, mergeAttributes } from '@tiptap/react';

const TOGGLE_ICON =
  'https://storage.googleapis.com/distribute-production-storage/general/toggle-icon.svg';

export const CustomTaskListExt = Node.create({
  name: 'customTaskList',
  group: 'block',
  content: 'customTaskListItem+',
  isolating: true,
  selectable: true,

  addAttributes() {
    return {
      isOpen: {
        default: true,
      },
      title: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options?.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
        class: 'custom-tasklist',
        'data-open': node.attrs.isOpen,
      }),
      [
        'div',
        { class: 'custom-tasklist__heading-wrapper' },
        [
          'div',
          { class: 'custom-tasklist__title-wrapper' },
          [
            'button',
            { class: 'custom-tasklist__toggle-btn' },
            [
              'img',
              {
                src: TOGGLE_ICON,
                class: `custom-tasklist__toggle ${
                  node.attrs.isOpen && 'custom-tasklist__toggle--rotated'
                }`,
              },
            ],
          ],
          [
            'p',
            {
              class: `custom-tasklist__title ${
                !node.attrs.title && 'custom-tasklist__title--placeholder'
              }`,
            },
            node.attrs.title || 'Task list name',
          ],
        ],
        ['span', { class: 'custom-tasklist__completed' }],
      ],
      [
        'hr',
        {
          class: `custom-tasklist__hr ${
            !node.attrs.isOpen && 'custom-tasklist__hr--hidden'
          }`,
        },
      ],
      [
        'div',
        {
          class: `custom-tasklist__content ${
            !node.attrs.isOpen && 'custom-tasklist__content--hidden'
          }`,
        },
        0,
      ],
    ];
  },
});

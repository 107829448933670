import { call, delay, put, select } from 'redux-saga/effects';

import { RT } from '../../../../shared/types';
import { NarrationLogicState } from '../types';

import { videoNarrationApi } from '../../../../shared/api';

import { logger } from '../../../../shared/lib';

import { checkIsNarrationReady } from '@distribute/shared/utils';

import { INVERTAL_DELAY } from '../../config';
import { narrationModel } from '../';

export function* watchNarrationStatus() {
  try {
    while (true) {
      //prettier-ignore
      const { logicState }: RT<typeof narrationModel.selectors.selectNarration> = yield select(
        narrationModel.selectors.selectNarration
      );
      const narration: RT<typeof narrationModel.selectors.selectNarrationData> =
        yield select(narrationModel.selectors.selectNarrationData);

      if (logicState !== NarrationLogicState.Idle) {
        yield delay(INVERTAL_DELAY);
        continue;
      }

      if (!narration) {
        yield delay(INVERTAL_DELAY);
        continue;
      }

      if (checkIsNarrationReady(narration)) {
        yield delay(INVERTAL_DELAY);
        continue;
      }

      const data: RT<typeof videoNarrationApi.get> = yield call(
        videoNarrationApi.get,
        narration
      );

      yield put(narrationModel.actions.setNarration({ ...narration, ...data }));
      yield delay(INVERTAL_DELAY);
    }
  } catch (e: unknown) {
    logger.error(e);
  }
}

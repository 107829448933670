import { useEffect, useRef, useCallback } from 'react';
import { useIsMounted } from './useIsMouned';

export function useScrollIntoView(
  ref: React.RefObject<HTMLElement>,
  alignToTop = true
) {
  const isVisible = useRef(false);
  const isMounted = useIsMounted();

  const scrollToTop = useCallback(() => {
    const shouldScroll = isMounted && !isVisible.current && ref.current;

    if (shouldScroll) {
      ref.current.scrollIntoView(alignToTop);
    }
  }, [ref, isMounted, alignToTop]);

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        isVisible.current = entry.isIntersecting;
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref]);

  return { scrollToTop };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PlayerBubbleState = {
  isMinimized: boolean;
};

export const initialState: PlayerBubbleState = {
  isMinimized: false,
};

export const playerBubbleSlice = createSlice({
  name: 'playerBubble',
  initialState,
  reducers: {
    setMinify: (state, action: PayloadAction<boolean>) => {
      state.isMinimized = action.payload;
    },
  },
});

export const { actions, reducer } = playerBubbleSlice;

import { mergeAttributes, Node } from '@tiptap/react';
import { COPY_ICON, mapTypeWithIcon } from './lib/icons';
import { linkifyContact } from './lib/linkifyContact';
import { calcInitials } from './lib/calcInitials';
import { deserializeContacts } from './lib/serializer';

export const ContactCardExt = Node.create({
  addAttributes() {
    return {
      company: { default: '' },
      description: { default: '' },
      isDescriptionBlockVisible: { default: true },
      isPhotoBlockVisible: { default: true },
      jobTitle: { default: '' },
      name: { default: '' },
      photoUrl: { default: '' },
      initialName: { isRequired: true },
      serializedContacts: { default: JSON.stringify([]) },
    };
  },
  name: 'contactCard',
  group: 'block',

  parseHTML() {
    return [{ tag: `div[data-type="${this.name}"]` }];
  },

  renderHTML({ node, HTMLAttributes }) {
    const contacts = deserializeContacts(node.attrs.serializedContacts).map(
      (contact) => [
        'div',
        { class: 'contact-card__contact-item' },
        [
          'div',
          { class: 'contact-card__contact-icon' },
          [
            'img',
            {
              src: mapTypeWithIcon[contact.type],
            },
          ],
        ],
        [
          'a',
          {
            href: linkifyContact(contact.type, contact.value),
            class: 'contact-card__contact-link',
            target: '_blank',
            rel: 'noreferrer',
          },
          contact.label ?? contact.value,
        ],
        [
          'div',
          {
            class: 'contact-card__copy-button',
            'data-text': contact.value,
          },
          ['img', { class: 'contact-card__copy-contact', src: COPY_ICON }],
          ['span', { class: 'contact-card__copy_tooltip' }, 'Copy'],
        ],
      ]
    );

    return [
      'div',
      mergeAttributes(this.options?.HTMLAttributes, HTMLAttributes, {
        class: 'contact-card',
        'data-type': this.name,
      }),
      [
        'div',
        {
          class: [
            'contact-card__description',
            !node.attrs.isDescriptionBlockVisible
              ? ' contact-card__description--hidden'
              : '',
          ].join(' '),
        },
        [
          'p',
          {
            class: 'contact-card__description-text',
          },
          node.attrs.description,
        ],
      ],
      [
        'div',
        { class: 'contact-card__content' },
        [
          'div',
          { class: 'contact-card__personal-info' },
          [
            'span',
            {
              class: [
                'avatar',
                node.attrs.photoUrl ? 'avatar--src' : '',
                'contact-card__avatar',
                !node.attrs.isPhotoBlockVisible
                  ? ' contact-card__avatar--hidden'
                  : '',
              ].join(' '),
            },
            node.attrs.photoUrl
              ? [
                  'img',
                  {
                    class: 'avatar__img',
                    src: node.attrs.photoUrl,
                    alt: 'User profile avatar',
                  },
                ]
              : [
                  'span',
                  {
                    class: 'avatar__initials',
                  },
                  calcInitials(node.attrs.name || node.attrs.initialName, true),
                ],
          ],
          [
            'div',
            { class: 'contact-card__basic-info' },
            ['span', { class: 'contact-card__name' }, node.attrs.name],
            [
              'span',
              {
                class: `contact-card__company-info${
                  !node.attrs.jobTitle && !node.attrs.company
                    ? ' contact-card__company-info--hidden'
                    : ''
                }`,
              },
              [node.attrs.jobTitle, node.attrs.company]
                .filter(Boolean)
                .join(' • '),
            ],
          ],
        ],
        ['div', { class: 'contact-card__contact-list' }, ...contacts],
      ],
    ];
  },
});

export const setCorrectColumnWidth = () => {
  const columns = document.querySelectorAll<HTMLElement>('.column');
  for (let i = 0; i < columns.length; i++) {
    const columnsAmount = columns[i]?.parentElement?.children.length;
    if (!columnsAmount) return;

    const summaryGapWidth = (columnsAmount - 1) * 24;

    const currentWidth = columns[i].style.width.replace('%', '') as any;

    columns[i].style.width = `calc((100% - ${summaryGapWidth}px) * ${(
      currentWidth / 100
    ).toFixed(4)})`;
  }
};

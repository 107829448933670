import { api } from './instance';
import {
  SavePageAsTemplateResponseType,
  SavePageAsTemplateRequestType,
  GetTemplatesResponseType,
  DeleteTemplateResponseType,
  TemplateExtended,
  CreateBlankTemplateResponseType,
  CreateBlankTemplateRequestType,
  GetTemplateResponseType,
  GetPublicTemplatesQueryParamsType,
  DuplicateTemplateResponseType,
  DuplicateTemplateRequestType,
} from '@distribute/shared/api-types/templates';

export const templatesApi = {
  async getTemplates(teamId: string) {
    const { data } = await api.get<GetTemplatesResponseType>(
      `templates?teamId=${teamId}`
    );
    return data;
  },

  async getPublicTemplates({
    category,
    query,
  }: GetPublicTemplatesQueryParamsType) {
    const { data } = await api.get<GetTemplatesResponseType>(
      `templates/public?category=${category}${query ? `&query=${query}` : ''}`
    );
    return data;
  },

  async getPublicTemplate({ id }: { id: string }) {
    const { data } = await api.get<GetTemplateResponseType>(
      `templates/public/${id}`
    );
    return data;
  },

  async getRecentTemplates(teamId: string) {
    const { data } = await api.get<GetTemplatesResponseType>(
      `templates/recent?teamId=${teamId}`
    );
    return data;
  },

  async getTopTemplates() {
    const { data } = await api.get<GetTemplatesResponseType>('templates/top');
    return data;
  },

  async savePageAsTemplate(data: SavePageAsTemplateRequestType) {
    const { data: responseData } =
      await api.post<SavePageAsTemplateResponseType>('templates', data);
    return responseData;
  },

  async duplicateTemplate(id: number, data: DuplicateTemplateRequestType) {
    const { data: responseData } =
      await api.post<DuplicateTemplateResponseType>(
        `templates/${id}/duplicate`,
        data
      );
    return responseData;
  },

  async createBlankTemplate(data: CreateBlankTemplateRequestType) {
    const { data: responseData } =
      await api.post<CreateBlankTemplateResponseType>('templates/blank', data);
    return responseData;
  },

  async updateTemplate(templateId: number, template: TemplateExtended) {
    const { data } = await api.patch<TemplateExtended>(
      `templates/${templateId}`,
      template
    );
    return data;
  },

  async deleteTemplate(templateId: number) {
    const { data } = await api.delete<DeleteTemplateResponseType>(
      `templates/${templateId}`
    );
    return data;
  },

  async deleteTemplateCoverImage(documentContentId: number) {
    return api.delete(`templates/${documentContentId}/delete-template-cover`);
  },
};

import { call, put, select } from 'redux-saga/effects';
import { Pagination } from 'nestjs-typeorm-paginate';
import { actions } from '../reducer';
import {
  selectSearch,
  selectOrderBy,
  selectFilterByUserId,
} from '../selectors';
import { pagesOverviewApi } from '../../../../shared/api';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { teamsModel } from '../../../teams';
import { PageOverview } from '@distribute/shared/types';

type orderByType = 'leastViewed' | 'lastCreated' | 'name' | undefined;

export function* getPagesOverview({
  payload: { page = 1, limit = 10 },
}: ReturnType<typeof actions.getPagesOverview>) {
  yield put(actions.setIsLoadingData(true));

  const { id: currentTeamId }: { id: number } = yield select(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const search: string = yield select(selectSearch);
  const orderBy: orderByType = yield select(selectOrderBy);
  const filterByUserId: string = yield select(selectFilterByUserId);

  try {
    const data: Pagination<PageOverview> = yield call(
      pagesOverviewApi.getPagesOverview,
      {
        page,
        limit,
        teamId: currentTeamId,
        search,
        orderBy,
        filterByUserId,
      }
    );

    yield put(
      actions.setPagesOverviewData({
        data: data.items,
        meta: {
          totalItems: data.meta.totalItems,
          totalPages: data.meta.totalPages,
          currentPage: data.meta.currentPage,
        },
      })
    );
  } catch (err: unknown) {
    logger.error(err);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to show overview`)
      )
    );
  } finally {
    yield put(actions.setIsLoadingData(false));
  }
}

import {
  TypeOfWorkForUsagePlanWork,
  UsagePlan,
} from '@distribute/shared/types';
import { ReviewItem, UsagePlanOption } from '../types';

export const REVIEWS: ReviewItem[] = [
  {
    text: '"We started using Distribute pages to speed up our pace in distributing good content. We are still early but so far our reps are booking meetings using the help of Distribute Templates."',
    name: 'Mark Kosoglow',
    position: 'RO at Catalyst',
    photoUrl: '../../../assets/images/onboarding/user-1.png',
  },
  {
    text: '"I love being able to show that I am a trusted advisor to my prospects my providing them with valuable content vs. spamming them all day with cold emails."',
    name: 'Annie Roche',
    position: 'Director of Sales at Attentive',
    photoUrl: '../../../assets/images/onboarding/user-2.png',
  },
  {
    text: (
      <div>
        "We've decreased the time it takes to get lead magnets out from 2 weeks
        to 20 minutes with Distribute. And now we have a library <br /> of
        content."
      </div>
    ),
    name: 'Lee Hacohen',
    position: 'VP of Sales at Outreach',
    photoUrl: '../../../assets/images/onboarding/user-3.png',
  },
];

export const USAGE_PLAN_OPTIONS: UsagePlanOption[] = [
  {
    id: UsagePlan.WORK,
    label: 'For Work',
    description: '',
  },
  {
    id: UsagePlan.EDUCATION,
    label: 'For Education',
    description:
      'Teachers use Distribute to record lesson plans, teach remotely, and connect with students asynchronously.',
  },
  {
    id: UsagePlan.PERSONAL_PROJECTS,
    label: 'For Personal Projects',
    description:
      'People use Distribute to send simple web pages to friends and family, start their side hustles, and more.',
  },
];

export const TYPE_OF_WORK_FOR_USAGE_PLAN_WORK_TO_TEXT: Record<
  TypeOfWorkForUsagePlanWork,
  string
> = {
  [TypeOfWorkForUsagePlanWork.SALES]: 'Sales',
  [TypeOfWorkForUsagePlanWork.MARKETING]: 'Marketing',
  [TypeOfWorkForUsagePlanWork.OPERATIONS]: 'Operations',
  [TypeOfWorkForUsagePlanWork.CUSTOMER_SUCCESS]: 'Customer Success',
  [TypeOfWorkForUsagePlanWork.EXECUTIVE]: 'Executive',
  [TypeOfWorkForUsagePlanWork.HUMAN_RESOURCES]: 'Human Resources',
  [TypeOfWorkForUsagePlanWork.OTHER]: 'Other',
};

export const SKIP_EXTENSION = 'skip-extension';

import { InputProps } from '../../../../../shared/ui/Input';
import { Input } from '../../../../../shared/ui';
import { forwardRef } from 'react';

interface Props extends Omit<InputProps, 'type'> {
  label: string;
}

const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ label, ...inputProps }, ref) => (
    <div className="flex flex-row gap-x-2 items-center">
      <span className="flex-[1]">{label}</span>
      <div className="flex-[3]">
        <Input type="text" {...inputProps} ref={ref} />
      </div>
    </div>
  )
);

export default TextInput;

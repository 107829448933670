import { FC } from 'react';
import {
  DeserializedContact,
  linkifyContact,
} from '@distribute/shared/generate-html';
import { IconMap } from '../../../../../shared/sprite';
import { Button, Icon, Tooltip } from '../../../../../shared/ui';

const mapIconWithContactType = {
  calendar: IconMap.CalendarPlus01,
  email: IconMap.AtSign,
  facebook: IconMap.FacebookLine,
  linkedin: IconMap.LinkedInLine,
  phone: IconMap.Phone,
  twitter: IconMap.XSocial,
  website: IconMap.Globe01,
};

function onCopyToClipboard(value: string) {
  return () => {
    navigator.clipboard.writeText(value);
  };
}

export const ContactLink: FC<DeserializedContact> = ({
  label,
  type,
  value,
}) => (
  <div className="flex flex-row flex-nowrap gap-x-3 items-center group sm:text-sm text-base">
    <Icon glyph={mapIconWithContactType[type]} width={20} />
    <a
      href={linkifyContact(type, value)}
      className="flex-1 !no-underline group-hover:!underline truncate"
      target="_blank"
      rel="noreferrer"
    >
      {label ?? value}
    </a>
    <Tooltip
      hideArrow
      className="text-center py-2 px-3 text-xs font-semibold"
      sideOffset={5}
      triggerClassNames="flex flex-col items-center"
      trigger={
        <Button
          className="opacity-0 group-hover:opacity-100 transition-opacity !bg-transparent"
          variant="icon"
          size="xs"
          color="transparent-with-actions"
          iconLeftName={IconMap.Copy03}
          iconLeftWidth={20}
          onClick={onCopyToClipboard(value)}
        />
      }
    >
      <div>Copy</div>
    </Tooltip>
  </div>
);

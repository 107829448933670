import ReactPlayer from 'react-player';

import { RefObject } from 'react';
import { usePlayer } from './use-player';

import { checkIsInf } from '../lib';

import { actions } from '../provider/player';

export const usePlayerActions = () => {
  const { dispatch } = usePlayer();

  const handleStart = () => dispatch(actions.start());

  const handlePlay = () => dispatch(actions.play());

  const handlePause = () => dispatch(actions.pause());

  const handleToggle = () => dispatch(actions.togglePlay());

  const handleToggleVolume = () => dispatch(actions.toggleVolume());

  const handleSetVolume = ([value]: [number]) =>
    dispatch(actions.setVolume(value));

  const handleSetPlaybackRate = () => dispatch(actions.nextPlaybackRate());

  const handleSetFullscreen = (open: boolean) =>
    dispatch(actions.setFullscreen(open));

  const handleProgress = (progress: {
    playedSeconds: number;
    loaded: number;
  }) => {
    dispatch(actions.setPlayedSeconds(progress.playedSeconds));
    dispatch(actions.setBufferedSeconds(progress.loaded));
  };

  const handleSetTime = (ref: RefObject<ReactPlayer>, value: number) => {
    const duration = ref.current?.getDuration() || 0;

    if (checkIsInf(duration) || duration <= 0) return;

    const diff = Math.abs(duration - value);
    const newValue = diff < 0.1 ? duration : value;

    ref.current?.seekTo(newValue, 'seconds');
    dispatch(actions.setPlayedSeconds(newValue));
    dispatch(actions.finish(newValue === duration));
  };

  const handleDuration = (duration: number) => {
    dispatch(actions.setDuration(duration));
  };

  const handleEnd = (ref: RefObject<ReactPlayer>) => {
    const payload = ref.current?.getDuration() || 0;
    dispatch(actions.end(payload));
  };

  const handleReset = (ref: RefObject<ReactPlayer>) => {
    ref.current?.seekTo(0);
    dispatch(actions.reset());
  };

  const handleRetry = (ref: RefObject<ReactPlayer>) => {
    ref.current?.seekTo(0);
    dispatch(actions.start());
  };

  return {
    handleStart,
    handlePlay,
    handlePause,
    handleToggle,
    handleToggleVolume,
    handleSetVolume,
    handleSetPlaybackRate,
    handleSetFullscreen,
    handleProgress,
    handleSetTime,
    handleDuration,
    handleEnd,
    handleReset,
    handleRetry,
  };
};

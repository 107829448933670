import { TimelineExt } from '@distribute/shared/generate-html';
import { NodeViewProps, ReactNodeViewRenderer } from '@tiptap/react';
import { TimelineNodeView } from './Timeline.renderer';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    timeline: {
      insertTimeline: () => ReturnType;
    };
  }
}
export type IProps = NodeViewProps;

export const Timeline = TimelineExt.extend({
  addCommands() {
    return {
      insertTimeline:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'timeline',
            content: [
              {
                type: 'timelineItem',
                content: [
                  {
                    type: 'heading',
                    attrs: { level: 4, textAlign: 'left' },
                  },
                  {
                    type: 'paragraph',
                  },
                ],
              },
            ],
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(TimelineNodeView);
  },
});

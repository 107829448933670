import cn from 'classnames';
import { checkIsInf, toPrettySeconds } from '../../../lib';
import { usePlayer } from '../../../hooks';

export const PlayerDuration = () => {
  const { state } = usePlayer();

  const { playedSeconds, durationSeconds, isFullscreen } = state;

  const isInf = checkIsInf(durationSeconds);

  return (
    <div
      className={cn(
        'select-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-base-white font-base text-sm font-semibold flex gap-x-2 items-center whitespace-nowrap',
        {
          hidden: !durationSeconds,
          'text-sm': !isFullscreen,
          'text-xl': isFullscreen,
        }
      )}
    >
      {isInf ? (
        <span>{toPrettySeconds(playedSeconds)}</span>
      ) : (
        <>
          <span>{toPrettySeconds(playedSeconds)}</span>
          <span>/</span>
          <span>{toPrettySeconds(durationSeconds)}</span>
        </>
      )}
    </div>
  );
};

import React, { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { Dropdown, Icon, Tag, PageItemAvatar } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Page } from '@distribute/shared/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  redirectActions,
  WORKSPACE_RECENT_VIEWERS_ROUTE,
} from '../../../../entities/history';
import { pagesModel, usePagePermissions } from '../../../../features/pages';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { navigate } from '../../../../processes/navigation';
import { getFullPageUrl } from '../../../../shared/lib';
import { teamsModel, useTeamPermissions } from '../../../../features/teams';
import { useWindowWidth } from '@distribute/frontend/utils';
import {
  foldersModel,
  useFolderPermissions,
} from '../../../../features/folders';
import { customDomainsModel } from '../../../../features/custom-domains';
import { createPageQueryRandomValue } from '../../../../features/pages/lib';
import { CreatedByRow } from './CreatedByPageRow';
import { DropdownItem } from '../../../../shared/ui/Dropdown';

type PageItemProps = {
  page: Page;
  onDelete: (id: string) => void;
  onMoveToFolder: (id: string) => void;
  onSharePage: (id: string) => void;
};

export const PageItem: React.FC<PageItemProps> = ({
  page,
  onDelete,
  onMoveToFolder,
  onSharePage,
}) => {
  const {
    id,
    content: { title },
    views,
    sequenceNumber,
    pageSlug,
    folderId,
    isBranded,
  } = page;
  const { domain } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const customDomain = useSelector(
    customDomainsModel.selectors.selectVerifiedCustomDomain
  );
  const currentFolder = useSelector(foldersModel.selectors.selectCurrentFolder);
  const folderPermissions = useFolderPermissions(currentFolder);
  const pagePermissions = usePagePermissions(page);
  const { isGuest } = useTeamPermissions();

  const pageUrl = getFullPageUrl({
    slug: pageSlug,
    domain,
    customDomain,
  });

  const { isMobile } = useWindowWidth();

  const dispatch = useDispatch();

  const handlePageEditorClick = useCallback(() => {
    if (isMobile) {
      dispatch(redirectActions.toEditorPage({ sequenceNumber }));
      dispatch(pagesModel.actions.setIsEditorPreview(true));
    } else {
      dispatch(redirectActions.toEditorPage({ sequenceNumber }));
    }
  }, [dispatch, sequenceNumber, isMobile]);

  const onDuplicate = useCallback(() => {
    dispatch(
      pagesModel.actions.duplicatePage({
        id,
        folderId,
        source: 'workspace',
      })
    );
  }, [dispatch, folderId, id]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(`${pageUrl}?${createPageQueryRandomValue()}`);
    dispatch(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'The link has been copied to your clipboard'
        )
      )
    );
  }, [dispatch, pageUrl]);

  const onOpenAnalytics = useCallback(() => {
    dispatch(
      navigate({
        to: generatePath(WORKSPACE_RECENT_VIEWERS_ROUTE, {
          sequenceNumber,
        }),
      })
    );
  }, [dispatch, sequenceNumber]);

  const teamUsers = useSelector(teamsModel.selectors.selectCurrentTeamMembers);
  const teamUsersInviteAccepted = useMemo(
    () => teamUsers.filter((user) => !user.isWaitingForInviteAcceptance),
    [teamUsers]
  );

  const onRequestToEdit = useCallback(() => {
    dispatch(pagesModel.actions.requestToEditPage({ pageId: id }));
  }, [dispatch, id]);

  const pageOptions: DropdownItem[] = useMemo(
    () =>
      [
        {
          id: 'Editor',
          label: 'Editor',
          onClick: handlePageEditorClick,
          iconName: IconMap.MenuEdit,
          iconWidth: 16,
          style: 'sm:hidden',
          isShow: pagePermissions.isCanEditDocumentContent,
        },
        {
          id: 'Preview',
          label: 'Preview',
          onClick: handlePageEditorClick,
          iconName: IconMap.Eye,
          iconWidth: 16,
          isShow: !pagePermissions.isCanEditDocumentContent,
        },
        {
          id: 'Analytics',
          label: 'Analytics',
          onClick: onOpenAnalytics,
          iconName: IconMap.AnalyticsIcon,
          isSeparatedFromTop: isMobile,
          iconWidth: 16,
          isShow: page.published && !isGuest,
        },
        {
          id: 'Ask to edit',
          label: 'Ask to edit',
          onClick: onRequestToEdit,
          iconName: IconMap.Edit05,
          iconWidth: 16,
          isShow: !pagePermissions.isCanEditDocumentContent,
        },
        {
          id: 'Share',
          label: 'Share',
          onClick: () => onSharePage(page.id),
          iconName: IconMap.Share07,
          iconWidth: 16,
          isShow:
            ((teamUsersInviteAccepted.length > 1 &&
              (pagePermissions.isCanInviteMembers ||
                pagePermissions.isCanManageAccess)) ||
              pagePermissions.isCanPublishPage) &&
            !isGuest,
          isSeparatedFromTop: true,
        },
        {
          id: 'Duplicate',
          label: 'Duplicate',
          onClick: onDuplicate,
          iconName: IconMap.MenuCopy,
          isShow: folderPermissions.isCanDuplicatePages,
          isSeparatedFromTop: isBranded,
          iconWidth: 16,
        },
        {
          id: 'CopyLink',
          label: 'Copy Link',
          onClick: handleCopyLink,
          iconName: IconMap.Link,
          iconWidth: 16,
        },
        {
          id: 'MoveToFolder',
          label: 'Move to Folder',
          onClick: () => onMoveToFolder(page.id),
          iconName: IconMap.MenuFolder,
          iconWidth: 16,
          isShow: folderPermissions.isCanMovePagesToAnotherFolder,
        },
        {
          id: 'Delete',
          label: 'Delete',
          onClick: () => onDelete(page.id),
          iconName: IconMap.Delete,
          iconWidth: 16,
          isSeparatedFromTop: true,
          isShow: pagePermissions.isCanDelete,
        },
      ].filter((i) => i.isShow !== false),
    [
      handleCopyLink,
      handlePageEditorClick,
      onDelete,
      onDuplicate,
      onMoveToFolder,
      onOpenAnalytics,
      onSharePage,
      onRequestToEdit,
      page.id,
      isMobile,
      folderPermissions.isCanDuplicatePages,
      folderPermissions.isCanMovePagesToAnotherFolder,
      pagePermissions.isCanDelete,
      pagePermissions.isCanManageAccess,
      pagePermissions.isCanInviteMembers,
      pagePermissions.isCanPublishPage,
      pagePermissions.isCanEditDocumentContent,
      page.published,
      isBranded,
      teamUsersInviteAccepted,
      isGuest,
    ]
  );

  const unpublishedPageOptions = pageOptions.filter(
    (item) => item.id !== 'CopyLink'
  );

  const pageItems = page.published ? pageOptions : unpublishedPageOptions;

  return (
    <article className="w-1/3 px-4 xl:w-1/2 sm:w-full max-w-104">
      <div
        className="flex flex-col justify-between gap-4 p-6 mb-8 border border-gray-300 cursor-pointer rounded-xl bg-base-white hover:bg-gray-50 active:bg-gray-100"
        role="button"
        onClick={handlePageEditorClick}
      >
        <div>
          <div className="flex flex-row items-center justify-between">
            <PageItemAvatar page={page} />
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                listStyles="shadow-lg"
                triggerComponent={
                  <DropdownMenu.Trigger className="flex items-center justify-center w-10 h-10 -mr-2 rounded-lg focus:outline-none hover:bg-gray-50">
                    <Icon glyph={IconMap.DotsVertical} width={20} />
                  </DropdownMenu.Trigger>
                }
                items={pageItems}
              />
            </div>
          </div>
          <div className="mt-4">
            <p className="h-12 font-medium text-gray-700 break-words text-md text-ellipsis line-clamp-2">
              {title || 'Untitled'}
            </p>
          </div>
        </div>

        <div>
          <CreatedByRow page={page} />
          <div className="flex flex-row items-center justify-between mt-4">
            <div className="flex items-center gap-2 text-gray-700">
              <Icon glyph={IconMap.EyeBold} />
              <p className="text-sm font-normal leading-6">{views} views</p>
            </div>
            {page.published && (
              <Tag
                color="green"
                className="!py-0.25 !px-2.5 rounded-full font-medium border border-success-200"
              >
                Public
              </Tag>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

import { mergeAttributes, Node } from '@tiptap/react';

export const UploadFieldExt = Node.create({
  addAttributes() {
    return {
      serializedFiles: JSON.stringify([]),
    };
  },
  name: 'uploadField',
  group: 'block',

  parseHTML() {
    return [{ tag: `div[data-type="${this.name}"]` }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options?.HTMLAttributes, HTMLAttributes, {
        class: 'upload-field',
        'data-type': this.name,
      }),
    ];
  },
});

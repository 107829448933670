import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import useCountDown from 'react-countdown-hook';

import { useDispatch } from 'react-redux';

import { narrationModel } from '../../model';

import { formatToSeconds, play } from '../../../../shared/lib';
import startMp3 from '../../../../shared/audio/start.mp3';

const COUNTDOWN_DURATION = 3_000;

export enum CountdownState {
  Idle = 'idle',
  Running = 'running',
  Paused = 'paused',
  Completed = 'completed',
  Exit = 'exit',
}

export const NarrationCountdown: FC = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState<CountdownState>(CountdownState.Idle);
  const [timeLeft, actions] = useCountDown(COUNTDOWN_DURATION, 1000);

  const onClickCountdown = () => {
    if (state === CountdownState.Paused) {
      actions.start(timeLeft);

      setState(CountdownState.Running);
      return;
    }

    if (state === CountdownState.Running) {
      actions.pause();

      setState(CountdownState.Paused);
      return;
    }
  };

  useEffect(() => {
    if (state === CountdownState.Idle) {
      setState(CountdownState.Running);
      actions.start();
      return;
    }

    if (state === CountdownState.Completed) {
      setState(CountdownState.Exit);

      play(startMp3, () =>
        dispatch(narrationModel.actions.startUploadingSession())
      );
      return;
    }

    if (state === CountdownState.Exit) {
      return;
    }

    if (timeLeft === 0) {
      setState(CountdownState.Completed);
      return;
    }
  }, [state, timeLeft, actions, dispatch]);

  //prettier-ignore
  const isCountdownInActive = [CountdownState.Idle, CountdownState.Completed, CountdownState.Exit].includes(state);

  if (isCountdownInActive) return null;

  return (
    <div
      className={cn(
        'flex items-center justify-center pointer-events-auto',
        'absolute inset-0 bg-base-black/50'
      )}
    >
      <div
        onClick={onClickCountdown}
        className={cn(
          'flex flex-col items-center justify-center',
          'cursor-pointer w-70 h-70 p-2 bg-base-white/25 text-base-white rounded-[32px] backdrop-blur-[225px]'
        )}
      >
        <p
          key={`${timeLeft}-${state}`}
          className={cn('font-bold text-[128px] leading-[128px] mb-6', {
            countdown: state === CountdownState.Running,
          })}
        >
          {formatToSeconds(timeLeft)}
        </p>
        {state === CountdownState.Running && (
          <span className="text-md font-medium">Click to pause countdown</span>
        )}
        {state === CountdownState.Paused && (
          <span className="text-md font-medium">Click to resume countdown</span>
        )}
      </div>
    </div>
  );
};

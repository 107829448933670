import { logger } from './';

export const play = (name?: string, cb?: () => void) => {
  try {
    const handler = () => {
      cb?.();

      audio.removeEventListener('ended', handler);
    };

    const audio = new Audio(name);
    audio.volume = 0.2;

    audio.addEventListener('ended', handler);
    audio.play();
  } catch {
    logger.error(`Can't play the ${name}`);
  }
};

import { Node } from '@tiptap/core';

export const TimelineItemExt = Node.create({
  name: 'timelineItem',

  content: 'heading paragraph',
  isolating: true,
  group: 'timelineItem',
  draggable: true,

  addAttributes() {
    return {
      progressIcon: {
        default: '➡️',
      },
      readyIcon: {
        default: '✅',
      },
      dueDate: {
        default: '',
      },
      isDone: {
        default: false,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'div[data-type="timelineItem"]' }];
  },

  renderHTML({ node }) {
    return [
      'div',
      { class: 'timeline-item', 'data-date': node.attrs.dueDate },
      [
        'div',
        {
          class: `timeline-item__icon-wrapper ${
            node.attrs.isDone
              ? 'timeline-item__icon-wrapper--ready'
              : 'timeline-item__icon-wrapper--progress'
          }`,
        },
        [
          'span',
          { class: 'timeline-item__icon' },
          node.attrs.isDone ? node.attrs.readyIcon : node.attrs.progressIcon,
        ],
      ],
      [
        'div',
        { class: 'timeline-item__content-wrapper' },
        ['div', { class: 'timeline-item__content' }, 0],
      ],
    ];
  },
});

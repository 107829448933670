import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Modal } from '../../../../shared/ui';
import { subscriptionModel } from '../../model';
import { redirectActions } from '../../../../entities/history';
import { IconMap } from '../../../../shared/sprite';

export const PaywallModal: FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    subscriptionModel.selectors.selectIsShowPaywallModal
  );

  const handleClose = () => {
    dispatch(subscriptionModel.actions.togglePaywall());
  };

  const handleUpgrade = () => {
    handleClose();
    dispatch(redirectActions.toSubscriptionsClick());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="It's Time to Upgrade!"
      className="!w-120 sm:!w-full"
      dialogClassName="!z-55"
      actionButton={
        <Button
          fullWidth
          onClick={handleUpgrade}
          color="primary"
          variant="text"
        >
          Upgrade
        </Button>
      }
    >
      <div className="flex flex-col gap-5 text-sm text-gray-600">
        <div>
          Discover a multitude of possibilities within the Distribute app. Opt
          for either the Pro or Team plan, and gain access to a host of features
          including:
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex gap-2">
            <Icon
              glyph={IconMap.CheckCircle}
              className="text-primary-600"
              width={20}
            />
            Unlimited Published Pages
          </div>
          <div className="flex gap-2">
            <Icon
              glyph={IconMap.CheckCircle}
              className="text-primary-600"
              width={20}
            />
            Unlimited AI Responses
          </div>
          <div className="flex gap-2">
            <Icon
              glyph={IconMap.CheckCircle}
              className="text-primary-600"
              width={20}
            />
            Unlimited Quick Recordings
          </div>
        </div>
        <div>...and more!</div>
      </div>
    </Modal>
  );
};

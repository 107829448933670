import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { templatesApi } from '../../../../shared/api/axios/templates';
import { teamsModel } from '../../../teams';
import { TeamFiltered, Template } from '@distribute/shared/types';
import { templatesModel } from '..';
import { logger } from '../../../../shared/lib';
import { setTemplatesData } from '../../lib';

export function* duplicateTemplate({
  payload: { templateId },
}: ReturnType<typeof templatesModel.actions.duplicateTemplate>) {
  try {
    const templates: Template[] = yield select(
      templatesModel.selectors.selectTemplates
    );
    const template = templates.find((template) => template.id === templateId);

    if (!template) {
      throw new Error(`Template ${templateId} not found`);
    }

    const { id: teamId }: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeam
    );

    const notification = createNotification(
      'success',
      `Duplicating "${template.name || 'Untitled'}"`
    );
    yield put(snackbarModel.actions.addNotificationAction(notification));

    yield templatesApi.duplicateTemplate(templateId, { teamId });

    yield call(setTemplatesData);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'The template has been duplicated!')
      )
    );
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to duplicate the template')
      )
    );
  }
}

import { Editor, JSONContent } from '@tiptap/react';

export const checkIsEmptyContent = (pageContent: JSONContent | undefined) => {
  if (!pageContent) return true;

  return (
    (pageContent.content?.length ?? 0) <= 1 &&
    !pageContent.content?.[0].content &&
    pageContent.content?.[0].type === 'paragraph'
  );
};

export const getIsContentEmpty = (editor: Editor | null) => {
  const pageContent = editor?.getJSON();
  return checkIsEmptyContent(pageContent);
};

import { Node } from '@tiptap/core';

const CHECK_ICON =
  'https://storage.googleapis.com/distribute-production-storage/general/check.svg';

export const CustomTaskListItemExt = Node.create({
  name: 'customTaskListItem',

  content: 'paragraph',
  isolating: true,
  group: 'customTaskList',

  addAttributes() {
    return {
      dueDate: {
        default: '',
      },
      isVisible: {
        default: true,
      },
      isDone: {
        default: false,
      },
      id: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'div[data-type="customTaskListItem"]' }];
  },

  renderHTML({ node }) {
    return [
      'div',
      {
        class: `custom-tasklist__item ${
          !node.attrs.isVisible && 'custom-tasklist__item--hidden'
        }`,
        'data-date': node.attrs.dueDate,
        'data-done': node.attrs.isDone ? 'true' : 'false',
        'data-visible': node.attrs.isVisible ? 'true' : 'false',
        'data-id': node.attrs.id,
      },
      [
        'div',
        { class: `custom-tasklist__item-title-wrapper` },
        [
          'div',
          {
            class: `custom-tasklist__item-name-wrapper ${
              node.attrs.isDone
                ? 'custom-tasklist__item-name-wrapper--checked'
                : 'custom-tasklist__item-name-wrapper--unchecked'
            }`,
          },
          [
            'div',
            {
              class: `${
                node.attrs.isDone
                  ? 'custom-tasklist__icon--checked'
                  : 'custom-tasklist__icon--unchecked'
              } custom-tasklist__icon`,
            },
            [
              'img',
              {
                src: CHECK_ICON,
              },
            ],
          ],
          [
            'div',
            {
              class: `${
                node.attrs.isDone
                  ? 'custom-tasklist__name--checked'
                  : 'custom-tasklist__name--unchecked'
              } custom-tasklist__name`,
            },
            0,
          ],
        ],
      ],
    ];
  },
});

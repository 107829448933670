export const contactTypes = [
  'email',
  'phone',
  'website',
  'linkedin',
  'twitter',
  'facebook',
  'calendar',
] as const;

export type ContactType = (typeof contactTypes)[number];

export interface ContactCardAttrs {
  company: string;
  description: string;
  isDescriptionBlockVisible: boolean;
  isPhotoBlockVisible: boolean;
  jobTitle: string;
  name: string;
  initialName: string;
  photoUrl: string;
  serializedContacts: string;
}

export interface DeserializedContact {
  type: ContactType;
  label?: string;
  value: string;
  order: number;
  id: number;
}

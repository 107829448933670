import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VideoNarrationDetail } from '@distribute/shared/types';

import { globalActions } from '../../../app/model/actions';

import { messageActions } from './message-action';

import {
  DevicePermission,
  NarrationAction,
  NarrationCountdownState,
  NarrationLogicState,
  NarrationMediaState,
  NarrationState,
  NarrationTime,
} from './types';

import { noAudio, noVideo } from '../config';

const initialState: NarrationState = {
  narration: null,
  narrationActive: null,

  popupOpen: false,

  videoPermission: DevicePermission.PROMPT,
  videos: [noVideo],
  videoId: noVideo.id,

  audioPermission: DevicePermission.PROMPT,
  audios: [noAudio],
  audioId: noAudio.id,

  time: {} as NarrationTime,
  mediaState: NarrationMediaState.Idle,
  logicState: NarrationLogicState.Idle,
  countdownState: NarrationCountdownState.Idle,

  messageAction: messageActions.onIdle(),

  modal: null,
};
export const { reducer, actions: reducerActions } = createSlice({
  name: 'narration',
  initialState,
  reducers: {
    setNarration: (
      state,
      action: PayloadAction<NarrationState['narration']>
    ) => ({
      ...state,
      narration: action.payload,
    }),
    setNarrationActive: (
      state,
      action: PayloadAction<NarrationState['narrationActive']>
    ) => ({
      ...state,
      narrationActive: action.payload,
    }),
    setPopupOpen: (
      state,
      action: PayloadAction<NarrationState['popupOpen']>
    ) => ({
      ...state,
      popupOpen: action.payload,
    }),
    setAudioPermission: (
      state,
      action: PayloadAction<NarrationState['audioPermission']>
    ) => ({
      ...state,
      audioPermission: action.payload,
    }),
    setAudios: (state, action: PayloadAction<NarrationState['audios']>) => ({
      ...state,
      audios: action.payload,
    }),
    setAudioId: (state, action: PayloadAction<NarrationState['audioId']>) => ({
      ...state,
      audioId: action.payload,
    }),
    setVideoPermission: (
      state,
      action: PayloadAction<NarrationState['videoPermission']>
    ) => ({
      ...state,
      videoPermission: action.payload,
    }),
    setVideos: (state, action: PayloadAction<NarrationState['videos']>) => ({
      ...state,
      videos: action.payload,
    }),
    setVideoId: (state, action: PayloadAction<NarrationState['videoId']>) => ({
      ...state,
      videoId: action.payload,
    }),
    setMediaState: (
      state,
      action: PayloadAction<NarrationState['mediaState']>
    ) => ({
      ...state,
      mediaState: action.payload,
    }),
    setLogicState: (
      state,
      action: PayloadAction<NarrationState['logicState']>
    ) => ({
      ...state,
      logicState: action.payload,
    }),
    setCountdownState: (
      state,
      action: PayloadAction<NarrationState['countdownState']>
    ) => ({
      ...state,
      countdownState: action.payload,
    }),
    setMessageAction: (
      state,
      action: PayloadAction<NarrationState['messageAction']>
    ) => ({
      ...state,
      messageAction: action.payload,
    }),
    setTime: (
      state,
      action: PayloadAction<Partial<NarrationState['time']>>
    ) => ({
      ...state,
      time: { ...state.time, ...action.payload },
    }),
    setModal: (
      state,
      action: PayloadAction<Partial<NarrationState['modal']>>
    ) => ({
      ...state,
      modal: action.payload,
    }),
    resetTime: (state) => ({ ...state, time: {} as NarrationTime }),
    resetRecorder: (state) => ({
      ...state,
      time: {} as NarrationTime,
      mediaState: NarrationMediaState.Idle,
      logicState: NarrationLogicState.Idle,
      countdownState: NarrationCountdownState.Idle,
    }),
    resetAll: () => ({ ...initialState }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

//prettier-ignore
export const actions = {
  ...reducerActions,
  deleteNarration: createAction(NarrationAction.DeleteNarration),

  updateAudio: createAction<{ audios: NarrationState['audios']; audioPermission: NarrationState['audioPermission']}>(NarrationAction.UpdateAudio),
  updateVideo: createAction<{ videos: NarrationState['videos']; videoPermission: NarrationState['videoPermission']}>(NarrationAction.UpdateVideo),

  activateRecorder: createAction(NarrationAction.ActivateRecorder),
  destroyRecorder: createAction<{ uploadId?: string }>(NarrationAction.DestroyRecorder),

  start: createAction(NarrationAction.Start),
  startUploadingSession: createAction(NarrationAction.StartUploadingSession),

  startRecording: createAction<VideoNarrationDetail>(NarrationAction.StartRecording),
  restartRecording: createAction(NarrationAction.RestartRecording),
  resumeRecording: createAction(NarrationAction.ResumeRecording),
  pauseRecording: createAction(NarrationAction.PauseRecording),
  stopRecording: createAction(NarrationAction.StopRecording),
  cancelRecording: createAction(NarrationAction.CancelRecording),

  cancelUploadSession: createAction<{ uploadId: string }>(NarrationAction.CancelUploadSession),
  completeUploadSession: createAction<{  uploadId: string }>(NarrationAction.CompleteUploadSession),
};

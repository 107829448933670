export const getDevicesPermissionStatus = async () => {
  const [audioPermissionStatus, videoPermissionStatus] = await Promise.all([
    navigator?.permissions
      ?.query({ name: 'microphone' as PermissionName })
      .then((response) => response),
    navigator?.permissions
      ?.query({ name: 'camera' as PermissionName })
      .then((response) => response),
  ]);

  return { audioPermissionStatus, videoPermissionStatus };
};

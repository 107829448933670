import { createSlice, createAction } from '@reduxjs/toolkit';
import { PageOverview } from '@distribute/shared/types';
import { GetPagesOverviewDataAction } from './types';

type State = {
  search: string;
  orderBy: string;
  filterByUserId?: string;
  isLoadingData: boolean;
  isLoadingTotal: boolean;
  totalPages: number;
  totalPageViews: number;
  totalLeads: number;
  page: 0;
  data: PageOverview[];
  meta: {
    totalItems: number;
    totalPages: number;
    currentPage: number;
  };
};

const initialState: State = {
  search: '',
  orderBy: 'leastViewed',
  filterByUserId: undefined,
  isLoadingData: false,
  isLoadingTotal: false,
  totalPages: 0,
  totalPageViews: 0,
  totalLeads: 0,
  page: 0,
  data: [],
  meta: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
  },
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'pagesOverview',
  initialState,
  reducers: {
    setIsLoadingData: (state, { payload: isLoadingData }) => ({
      ...state,
      isLoadingData,
    }),
    setIsLoadingTotal: (state, { payload: isLoadingTotal }) => ({
      ...state,
      isLoadingTotal,
    }),
    setTotal: (state, { payload: total }) => ({
      ...state,
      ...total,
    }),
    setPagesOverviewData: (state, { payload: { data, meta } }) => ({
      ...state,
      data,
      meta,
    }),
    setSearching: (state, { payload: search }) => ({
      ...state,
      search,
    }),
    setOrderBy: (state, { payload: orderBy }) => ({
      ...state,
      orderBy,
    }),
    setFilterByUserId: (state, { payload: filterByUserId }) => ({
      ...state,
      filterByUserId,
    }),
    reset: () => ({
      ...initialState,
    }),
  },
});

export const actions = {
  ...reducerActions,
  getPagesOverview: createAction<GetPagesOverviewDataAction>(
    'pagesOverview/getPagesOverview'
  ),
  getPagesTotal: createAction('pagesOverview/getPagesTotal'),
};

import { call, put, select } from 'redux-saga/effects';
import {
  AnalyticsEvents,
  PlanName,
  SubscriptionInterval,
  TeamFiltered,
} from '@distribute/shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';
import { actions } from '../reducer';
import { onboardingModel } from '..';
import { teamMembersModel } from '../../../team-members';
import { logger } from '../../../../shared/lib';
import { handleError } from '../../../../utils/handleError';
import { analytics } from '../../../../entities/analytics';
import { teamsModel } from '../../../teams';
import { createSubscriptionTrial } from './createSubscriptionTrial';

export function* inviteTeamMembers({
  payload: { emails, teamId },
}: ReturnType<typeof actions.inviteTeamMembers>) {
  try {
    yield put(onboardingModel.actions.setIsLoadingInviteTeamMembers(true));
    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    yield call(teamMembersModel.sagas.inviteTeamMembersSaga, emails, teamId);

    analytics.track(AnalyticsEvents.ONBOARDING_INVITATION_SENT, {
      teamId,
      invitedUserEmails: emails,
    });
    yield call(createSubscriptionTrial, {
      teamId: currentTeam.id,
      plan: PlanName.PRO,
      interval: SubscriptionInterval.MONTH,
    });
  } catch (error: unknown) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'error',
          handleError(error, 'Failed to send team invites')
        )
      )
    );
  } finally {
    yield put(onboardingModel.actions.setIsLoadingInviteTeamMembers(false));
  }
}

import { VideoNarrationDetailType } from '@distribute/shared/api-types/video-narration';
import { VideoNarrationStatusConvert } from '@distribute/shared/types';

export const checkIsNarrationReady = (
  narration: VideoNarrationDetailType | null
) =>
  narration
    ? [
        VideoNarrationStatusConvert.COMPLETED,
        VideoNarrationStatusConvert.FAILED,
      ].includes(narration?.statusConvert)
    : false;

export const checkIsNarrationError = (
  narration: VideoNarrationDetailType | null
) =>
  narration
    ? [VideoNarrationStatusConvert.FAILED].includes(narration?.statusConvert)
    : false;

import { FC } from 'react';
import cn from 'classnames';

const PlayPreview: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M37 25.732C38.3333 24.9622 38.3333 23.0377 37 22.2679L16 10.1436C14.6667 9.37379 13 10.336 13 11.8756V36.1244C13 37.664 14.6667 38.6262 16 37.8564L37 25.732Z"
        fill="currentColor"
      />
    </svg>
  );
};

const Error: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 2V22H6.8C5.11984 22 4.27976 22 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2L2 6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2L17.2 2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V12M7 22V17M7 7V2M17 7V2M2 7H22M2 17H12M16.5 16.5L19 19M19 19L21.5 21.5M19 19L21.5 16.5M19 19L16.5 21.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Play: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.5 12.866C19.1667 12.4811 19.1667 11.5189 18.5 11.134L8 5.0718C7.33333 4.6869 6.5 5.16802 6.5 5.93782V18.0622C6.5 18.832 7.33333 19.3131 8 18.9282L18.5 12.866Z"
        fill="currentColor"
      />
    </svg>
  );
};

const Pause: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8 5C7.44772 5 7 5.44772 7 6V18C7 18.5523 7.44772 19 8 19H9C9.55228 19 10 18.5523 10 18V6C10 5.44772 9.55228 5 9 5H8Z"
        fill="currentColor"
      />
      <path
        d="M15 5C14.4477 5 14 5.44772 14 6V18C14 18.5523 14.4477 19 15 19H16C16.5523 19 17 18.5523 17 18V6C17 5.44772 16.5523 5 16 5H15Z"
        fill="currentColor"
      />
    </svg>
  );
};

const Retry: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70711 2.29289C9.09763 2.68342 9.09763 3.31658 8.70711 3.70711L7.41421 5H12.2548C16.567 5 20 8.617 20 13C20 17.383 16.567 21 12.2548 21C8.98067 21 6.20698 18.9075 5.06836 15.9884C4.86767 15.4739 5.12208 14.8941 5.63661 14.6934C6.15114 14.4927 6.73094 14.7471 6.93164 15.2616C7.79427 17.4732 9.86753 19 12.2548 19C15.3932 19 18 16.349 18 13C18 9.65101 15.3932 7 12.2548 7H7.41421L8.70711 8.29289C9.09763 8.68342 9.09763 9.31658 8.70711 9.70711C8.31658 10.0976 7.68342 10.0976 7.29289 9.70711L4.29289 6.70711C3.90237 6.31658 3.90237 5.68342 4.29289 5.29289L7.29289 2.29289C7.68342 1.90237 8.31658 1.90237 8.70711 2.29289Z"
        fill="currentColor"
      />
    </svg>
  );
};

const VolumeOn: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.5733 4.4073L7 8.00058H5C3.89543 8.00058 3 8.89601 3 10.0006V14.0006C3 15.1052 3.89543 16.0006 5 16.0006H7L11.5733 19.5939C12.5575 20.3672 14 19.666 14 18.4144V5.58678C14 4.33513 12.5575 3.634 11.5733 4.4073Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2981 8.64182C16.591 8.34893 17.0659 8.34893 17.3588 8.64182C19.2137 10.4968 19.2137 13.5043 17.3588 15.3593C17.0659 15.6522 16.591 15.6522 16.2981 15.3593C16.0052 15.0664 16.0052 14.5916 16.2981 14.2987C17.5673 13.0295 17.5673 10.9717 16.2981 9.70248C16.0052 9.40959 16.0052 8.93472 16.2981 8.64182Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9697 5.97025C19.2626 5.67736 19.7374 5.67736 20.0303 5.97025C23.3608 9.30071 23.3608 14.7005 20.0303 18.0309C19.7374 18.3238 19.2626 18.3238 18.9697 18.0309C18.6768 17.738 18.6768 17.2631 18.9697 16.9703C21.7143 14.2256 21.7143 9.77558 18.9697 7.03091C18.6768 6.73802 18.6768 6.26315 18.9697 5.97025Z"
        fill="currentColor"
      />
    </svg>
  );
};

const VolumeOff: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.5733 4.4073C12.2358 3.88673 13.1061 4.03433 13.6041 4.56825L13.5858 4.58636L3.23329 14.9389C3.08438 14.659 3 14.3397 3 14.0006V10.0006C3 8.89601 3.89543 8.00058 5 8.00058H7L11.5733 4.4073Z"
        fill="currentColor"
      />
      <path
        d="M11.5733 19.5939L7.46391 16.3651L14 9.829V18.4144C14 19.666 12.5575 20.3672 11.5733 19.5939Z"
        fill="currentColor"
      />
      <path
        d="M15.5303 6.5309C15.8232 6.23801 15.8232 5.76314 15.5303 5.47024C15.2374 5.17735 14.7626 5.17735 14.4697 5.47024L2.46967 17.4702C2.17678 17.7631 2.17678 18.238 2.46967 18.5309C2.76256 18.8238 3.23744 18.8238 3.53033 18.5309L15.5303 6.5309Z"
        fill="currentColor"
      />
    </svg>
  );
};

const FullscreenOn: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.25 6C13.25 6.41421 13.5858 6.75 14 6.75H16.1894L13.4697 9.46968C13.1768 9.76257 13.1768 10.2374 13.4697 10.5303C13.7626 10.8232 14.2374 10.8232 14.5303 10.5303L17.25 7.81066V10C17.25 10.4142 17.5858 10.75 18 10.75C18.4142 10.75 18.75 10.4142 18.75 10V5.25H14C13.5858 5.25 13.25 5.58579 13.25 6Z"
        fill="currentColor"
      />
      <path
        d="M10 18.75C10.4142 18.75 10.75 18.4142 10.75 18C10.75 17.5858 10.4142 17.25 10 17.25H7.81066L10.5303 14.5303C10.8232 14.2374 10.8232 13.7626 10.5303 13.4697C10.2374 13.1768 9.76257 13.1768 9.46967 13.4697L6.75 16.1893V14C6.75 13.5858 6.41421 13.25 6 13.25C5.58579 13.25 5.25 13.5858 5.25 14V18.75H10Z"
        fill="currentColor"
      />
    </svg>
  );
};

const FullscreenOff: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 10.75C18.4142 10.75 18.75 10.4142 18.75 10C18.75 9.58579 18.4142 9.25 18 9.25H15.8106L18.5303 6.53033C18.8232 6.23744 18.8232 5.76256 18.5303 5.46967C18.2374 5.17678 17.7626 5.17678 17.4697 5.46967L14.75 8.18934V6C14.75 5.58579 14.4142 5.25 14 5.25C13.5858 5.25 13.25 5.58579 13.25 6V10.75H18Z"
        fill="currentColor"
      />
      <path
        d="M6 13.25C5.58579 13.25 5.25 13.5858 5.25 14C5.25 14.4142 5.58579 14.75 6 14.75H8.18934L5.46967 17.4697C5.17678 17.7626 5.17678 18.2374 5.46967 18.5303C5.76257 18.8232 6.23744 18.8232 6.53033 18.5303L9.25 15.8107V18C9.25 18.4142 9.58579 18.75 10 18.75C10.4142 18.75 10.75 18.4142 10.75 18V13.25H6Z"
        fill="currentColor"
      />
    </svg>
  );
};

const Chrome: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_2551_11548)">
        <path
          d="M10.0003 6.66602C8.15938 6.66602 6.66699 8.1584 6.66699 9.99935C6.66699 11.8403 8.15938 13.3327 10.0003 13.3327C11.8413 13.3327 13.3337 11.8403 13.3337 9.99935C13.3337 8.1584 11.8413 6.66602 10.0003 6.66602ZM10.0003 6.66602H17.642M3.29199 5.04935L7.11699 11.666M9.06699 18.2827L12.8837 11.666M18.3337 9.99935C18.3337 14.6017 14.6027 18.3327 10.0003 18.3327C5.39795 18.3327 1.66699 14.6017 1.66699 9.99935C1.66699 5.39698 5.39795 1.66602 10.0003 1.66602C14.6027 1.66602 18.3337 5.39698 18.3337 9.99935Z"
          stroke="#424242"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2551_11548">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Sound: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.71551 2.93771L4.66667 5.33323H3.33333C2.59695 5.33323 2 5.93019 2 6.66657V9.33324C2 10.0696 2.59695 10.6666 3.33333 10.6666H4.66667L7.71551 13.0621C8.37164 13.5776 9.33333 13.1102 9.33333 12.2758V3.72403C9.33333 2.8896 8.37164 2.42218 7.71551 2.93771Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8654 5.76073C11.0607 5.56547 11.3772 5.56547 11.5725 5.76073C12.8092 6.99739 12.8092 9.00241 11.5725 10.2391C11.3772 10.4343 11.0607 10.4343 10.8654 10.2391C10.6701 10.0438 10.6701 9.72723 10.8654 9.53196C11.7115 8.68583 11.7115 7.31397 10.8654 6.46783C10.6701 6.27257 10.6701 5.95599 10.8654 5.76073Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6464 3.97968C12.8417 3.78442 13.1583 3.78442 13.3536 3.97968C15.5739 6.19999 15.5739 9.79981 13.3536 12.0201C13.1583 12.2154 12.8417 12.2154 12.6464 12.0201C12.4512 11.8249 12.4512 11.5083 12.6464 11.313C14.4762 9.48323 14.4762 6.51657 12.6464 4.68679C12.4512 4.49152 12.4512 4.17494 12.6464 3.97968Z"
        fill="white"
      />
    </svg>
  );
};

const PlayTooltip: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.71551 2.93795L2.66667 5.33348H1.33333C0.596954 5.33348 0 5.93043 0 6.66681V9.33349C0 10.0699 0.596954 10.6668 1.33333 10.6668H2.66667L5.71551 13.0623C6.37164 13.5779 7.33333 13.1104 7.33333 12.276V3.72427C7.33333 2.88984 6.37164 2.42242 5.71551 2.93795Z"
        fill="white"
      />
      <defs>
        <g id="icon1"></g>
        <g id="icon2">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.8654 5.76097C9.06066 5.56571 9.37724 5.56571 9.5725 5.76097C10.8092 6.99763 10.8092 9.00266 9.5725 10.2393C9.37724 10.4346 9.06066 10.4346 8.8654 10.2393C8.67014 10.0441 8.67014 9.72747 8.8654 9.53221C9.71153 8.68607 9.71153 7.31421 8.8654 6.46808C8.67014 6.27282 8.67014 5.95623 8.8654 5.76097Z"
            fill="white"
          />
        </g>
        <g id="icon3">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6464 3.97992C10.8417 3.78466 11.1583 3.78466 11.3536 3.97992C13.5739 6.20023 13.5739 9.80006 11.3536 12.0204C11.1583 12.2156 10.8417 12.2156 10.6464 12.0204C10.4512 11.8251 10.4512 11.5085 10.6464 11.3133C12.4762 9.48348 12.4762 6.51681 10.6464 4.68703C10.4512 4.49177 10.4512 4.17519 10.6464 3.97992Z"
            fill="white"
          />
        </g>
        <g id="icon4">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1738 1.87766C12.3649 1.6783 12.6814 1.67158 12.8807 1.86266C13.0212 1.99733 13.1571 2.13678 13.2882 2.28076C14.6619 3.79033 15.5 5.79788 15.5 8.00001C15.5 10.1525 14.6993 12.1191 13.3802 13.6165C13.2098 13.8099 13.0309 13.9954 12.8438 14.1725C12.6433 14.3624 12.3268 14.3538 12.137 14.1533C11.9471 13.9528 11.9557 13.6364 12.1562 13.4465C12.3214 13.2901 12.4794 13.1262 12.6298 12.9555C13.7942 11.6337 14.5 9.89989 14.5 8.00001C14.5 6.0564 13.7613 4.28648 12.5486 2.95381C12.4329 2.82668 12.3129 2.70353 12.1888 2.58461C11.9894 2.39354 11.9827 2.07703 12.1738 1.87766Z"
            fill="white"
          />
        </g>
      </defs>

      <use href="#icon1" visibility="visible">
        <animate
          attributeName="visibility"
          values="visible;hidden;hidden;hidden"
          dur="2s"
          repeatCount="indefinite"
        />
      </use>
      <use href="#icon2" visibility="hidden">
        <animate
          attributeName="visibility"
          values="hidden;visible;hidden;hidden"
          dur="2s"
          repeatCount="indefinite"
        />
      </use>
      <use href="#icon3" visibility="hidden">
        <animate
          attributeName="visibility"
          values="hidden;hidden;visible;hidden"
          dur="2s"
          repeatCount="indefinite"
        />
      </use>
      <use href="#icon4" visibility="hidden">
        <animate
          attributeName="visibility"
          values="hidden;hidden;hidden;visible"
          dur="2s"
          repeatCount="indefinite"
        />
      </use>
    </svg>
  );
};

const Cross: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Edit: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.9997 8.33326L11.6664 4.99993M2.08301 17.9166L4.90331 17.6032C5.24789 17.5649 5.42018 17.5458 5.58121 17.4937C5.72408 17.4474 5.86005 17.3821 5.98541 17.2994C6.12672 17.2062 6.2493 17.0836 6.49445 16.8385L17.4997 5.83326C18.4202 4.91279 18.4202 3.4204 17.4997 2.49993C16.5792 1.57945 15.0868 1.57945 14.1664 2.49992L3.16112 13.5052C2.91596 13.7503 2.79339 13.8729 2.70021 14.0142C2.61753 14.1396 2.55219 14.2755 2.50594 14.4184C2.4538 14.5794 2.43466 14.7517 2.39637 15.0963L2.08301 17.9166Z"
        stroke="#424242"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Icons = {
  PlayPreview,
  PlayTooltip,
  Error,
  Play,
  Pause,
  Retry,
  FullscreenOn,
  FullscreenOff,
  VolumeOn,
  VolumeOff,
  Chrome,
  Sound,
  Cross,
  Edit,
};

const sizes = {
  xss: 'w-4 h-4',
  xs: 'w-5 h-5',
  sm: 'w-6 h-6',
  md: 'w-9 h-9',
  lg: 'w-12 h-12',
} as const;

type Props = {
  svg: React.VFC<React.SVGProps<SVGSVGElement>>;
  size?: keyof typeof sizes;
  className?: string;
};

export const Icon: FC<Props> = ({ svg: Svg, size, className = '' }) => {
  return <Svg className={cn(size && sizes[size], className)} />;
};

import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, Button, LinkAsButton } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { ActionTypeAlertBar, AlertBar } from '@distribute/shared/types';
import { subscribePopupModel } from '../../subscribe-popup';
import { PagePermissions } from '../../pages/lib/getPagePermissions';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../editor-sidebar';
import { pagesModel } from '../../pages';
import { conversionKitModel } from '../../conversion-kit';
import { parseHtml } from '../../../shared/lib/parseHtml';
import { narrationModel } from '../../narration';

type Props = {
  pagePermissions: PagePermissions;
  alertBar: AlertBar;
};

export const AlertBarComponent: React.FC<Props> = ({
  pagePermissions,
  alertBar,
}) => {
  const dispatch = useDispatch();
  const isAnyConversionPanelOpen = useSelector(
    editorSidebarModel.selectors.selectIsAnyConversionPanelOpen
  );
  const popUp = useSelector(conversionKitModel.selectors.selectPopUpWithError);

  const isNarrationRecordingSessionActive = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionActive
  );

  const setIsSubscribePopupOpen = useCallback(() => {
    dispatch(subscribePopupModel.actions.setIsModalOpen(true));
  }, [dispatch]);

  const isEditorPreview = useSelector(
    pagesModel.selectors.selectIsEditorPreview
  );

  const handleOpenAlertBarSettings = () => {
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.ALERT_BAR
      )
    );
  };

  const activeConversionSettingsPanel = useSelector(
    editorSidebarModel.selectors.selectActiveConversionSettingsPanel
  );

  const isAlertBarPanelOpen =
    activeConversionSettingsPanel === ActiveConversionSettingsPanel.ALERT_BAR;

  if (!alertBar.isActive && !isAlertBarPanelOpen) {
    return null;
  }

  return (
    <div
      className={classNames(
        'group flex justify-center bg-brand-900 border-2 border-transparent hover:border-primary-600'
      )}
    >
      <div className="max-w-212 w-full text-brand-text md:px-8 sm:px-4">
        <div className="flex items-center justify-between md:gap-1 py-1.5 font-paragraph">
          {alertBar.description ? (
            parseHtml(alertBar.description)
          ) : (
            <p className="opacity-50">Text</p>
          )}
          <div className="relative">
            {alertBar.actionType === ActionTypeAlertBar.LINK && (
              <LinkAsButton
                size="md"
                color="brand"
                fontBold
                href={alertBar.buttonUrl}
                target="_blank"
              >
                {alertBar.buttonLabelLinkType || 'Submit'}
              </LinkAsButton>
            )}

            {alertBar.actionType === ActionTypeAlertBar.POPUP && (
              <Button
                size="md"
                color="brand"
                variant="text"
                fontBold
                onClick={popUp.isActive ? setIsSubscribePopupOpen : undefined}
              >
                {alertBar.buttonLabelPopupType || 'Submit'}
              </Button>
            )}
            {pagePermissions?.isCanEditConversionTools &&
              !isAnyConversionPanelOpen && (
                <div
                  onClick={handleOpenAlertBarSettings}
                  className={classNames(
                    'bg-primary-600 pl-0.5 text-base-white w-9 h-9 items-center justify-center rounded-full border-none hover:bg-primary-700 cursor-pointer absolute -bottom-6 -right-9 hidden md:group-hover:hidden z-10',
                    {
                      'group-hover:flex': !isEditorPreview,
                      '!hidden': isNarrationRecordingSessionActive,
                    }
                  )}
                >
                  <Icon glyph={IconMap.Edit2Icon} width={20} />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

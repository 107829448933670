import React from 'react';
import moment from 'moment';

import { CONVERSION_SOURCE_NAME, Viewer } from '@distribute/shared/types';
import { Column } from '../config';
import { Icon, Tooltip } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { formatMsToTimePassed } from '@distribute/shared/utils';

export const formatRow = (column: Column, data: Viewer) => {
  const conversions = data['conversions'] ?? [];
  const lastConversion = conversions[conversions.length - 1];

  switch (column) {
    case 'email':
      return (
        <span className="flex items-center gap-2">
          <span className="truncate">{data[column] ?? 'Anonymous'}</span>
          {data[column] && data.isEmailVerified && (
            <Tooltip
              hideArrow
              trigger={
                <span className="flex items-center justify-center w-5 h-5 bg-primary-50 cursor-pointer rounded-full">
                  <Icon
                    glyph={IconMap.VerifiedCheck}
                    width={10}
                    height={9}
                    className="text-primary-600 group-hover:block"
                  />
                </span>
              }
              sideOffset={4}
            >
              <span className="font-semibold">Email verified</span>
            </Tooltip>
          )}
        </span>
      );
    case 'views':
      return data[column] || 1;
    case 'source':
      //eslint-disable-next-line
      return CONVERSION_SOURCE_NAME[lastConversion?.[column]!] ?? '-';
    case 'overallActivity':
      return data[column] ? formatMsToTimePassed(data[column]) : '-';
    case 'createdAt':
    case 'lastVisitAt':
      return moment(data[column]).format('MMM D, YYYY, h:mm A');
    default:
      return data[column] ?? null;
  }
};

export const hideColumn = (column: Column, isLeadCategory: boolean) => {
  return isLeadCategory
    ? ['views', 'lastVisitAt'].includes(column)
    : ['source', 'createdAt'].includes(column);
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { selectWithError } from '../../../shared/lib';

import { selectUserWithError } from '../../../entities/auth-user/model/selector';

import { getTeamPermissions } from '../../teams/lib';
import { getFolderPermissions } from '../../folders';
import { getPagePermissions } from '../lib/getPagePermissions';

export const selectPages = ({ pages }: RootState) => pages.pages;
export const selectCreatePageIsLoading = ({ pages }: RootState) =>
  pages.createPageIsLoading;

export const selectCurrentPage = ({ pages }: RootState) => pages.currentPage;
export const selectCurrentContent = ({ pages }: RootState) =>
  pages.currentContent;
export const selectOpenedContentId = ({ pages }: RootState) =>
  pages.openedContentItemId;
export const selectMovePageToFolderIsLoading = ({ pages }: RootState) =>
  pages.movePageToFolderIsLoading;

export const selectDeletePageIsLoading = ({ pages }: RootState) =>
  pages.deletePageIsLoading;

export const selectChangePageIconIsLoading = ({ pages }: RootState) =>
  pages.changePageIconIsLoading;

export const selectChangePageContentIsLoading = ({ pages }: RootState) =>
  pages.changePageContentIsLoading;

export const selectIsPageSettingsModalOpen = ({ pages }: RootState) =>
  pages.isPageSettingsModalOpen;

export const selectIsEditorPreview = ({ pages }: RootState) =>
  pages.isEditorPreview;

export const selectPagesWithError = selectWithError(selectPages, 'pages');

export const selectCurrentPageWithError = selectWithError(
  selectCurrentPage,
  'currentPage'
);

export const selectPagesStatus = ({ pages }: RootState) => pages.pagesStatus;

export const selectCurrentPageStatus = ({ pages }: RootState) =>
  pages.currentPageStatus;

export const selectCurrentPageIsError = ({ pages }: RootState) =>
  pages.currentPageIsError;

export const selectPublishedPagesCount = ({ pages }: RootState) =>
  pages.publishedPagesCount;

export const selectPageEditorDataIsLoading = ({ pages }: RootState) =>
  pages.pageEditorDataIsLoading;

export const selectIsEditorBlocked = ({ pages }: RootState) =>
  pages.isEditorBlocked;

export const selectIsEditorSidebarOpen = ({ pages }: RootState) =>
  pages.isEditorSidebarOpen;

export const selectEditorPreviewMode = ({ pages }: RootState) =>
  pages.editorPreviewMode;

export const selectExistingEditorData = ({ pages }: RootState) =>
  pages.existingEditorData;

export const selectCreateUserPermissionIsLoading = ({ folders }: RootState) =>
  folders.createUserPermissionIsLoading;

export const selectSlashMenuOpen = ({ pages }: RootState) =>
  pages.isSlashMenuOpen;

export const selectSlashMenuPositionTop = ({ pages }: RootState) =>
  pages.slashMenuPositionTop;

export const selectSlashMenuPositionLeft = ({ pages }: RootState) =>
  pages.slashMenuPositionLeft;

export const selectPageCoverImageMenuOpen = ({ pages }: RootState) =>
  pages.isPageCoverImageMenuOpen;

export const selectIsPublishSettingsDropdownOpen = ({ pages }: RootState) =>
  pages.isPublishSettingsDropdownOpen;

export const selectIsBrandLogoDeleting = ({ pages }: RootState) =>
  pages.isBrandLogoDeleting;

export const selectCurrentActionPreview = ({ pages }: RootState) =>
  pages.currentActionPreview;

export const selectGeneratePagePromptData = ({ pages }: RootState) =>
  pages.generatePagePromptData;

export const selectPagesToEdit = createSelector(
  [
    ({ folders }: RootState) => folders.folders,
    ({ pages }: RootState) => pages.pages,
    ({ teams }: RootState) => teams.currentTeamMembers,
    selectUserWithError,
  ],
  (folders, pages, teamMembers, user) => {
    const teamPermissions = getTeamPermissions(user, teamMembers);

    return pages.filter((p) => {
      const folder = folders.find((f) => f.id === p.folderId);
      const folderPermission = getFolderPermissions(
        user,
        folder,
        teamPermissions
      );
      return getPagePermissions(user, folderPermission, p)
        .isCanEditDocumentContent;
    });
  }
);

export const selectContentNavigationData = ({ pages }: RootState) =>
  pages.contentNavigation;

export const selectContentItemById =
  (contentItemId?: number) =>
  ({ pages }: RootState) => {
    return (
      contentItemId &&
      pages.currentPage?.content.contentItems.find(
        (item) => item.id === contentItemId
      )
    );
  };

import { Sender, assign, createMachine, send } from 'xstate';
import {
  PermissionsMachineActions,
  PermissionsMachineContext,
  PermissionsMachineEvent,
} from './types';

import { DevicePermission } from '../../../model/types';
import { executeDevicesPermission, getDevicesPermissionStatus } from '../lib';

export const permissionsMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlwgBswBiCAe0LPwDc6BrMEtLPQ08qggKtM6AC64GAbQAMAXVlzEoAA51YuCQ2UgAHogBMMgGwkZAFgCc5gIwBmC7YAcBpwHYANCACeiJzZI7AwNrO2NjAzs7d0sAX1ivbhwCYjJKGjAAJ0y6TJIVCnEAM1zULgxkvjTBYTpRLXxFRR01DQadfQQjUwtre0cbF3cvXwQbNzsSAFZLYymp4xMDQfc3eMSK3lSsCWZOARp6RlqOcp4U0h3cPeqwIRY68UlG+WakEFbNZ47DYwDe2wOczOVyeHyIKYTaaWOyWKZ2Nw2KZOBFOdYgJJbS6YXb7dLULI5PIFYqlM6VbY4654moPerPJryFrqL7ad6dAx-MxWQEDIZg0bGSwGEjmYxuObhJziuxi+IJED4OgQOA6TEXZltb7sxAAWmMIz1xnR6qqB01rPwPwQ5gMhoQMOmRhkEsGQssEzsJs2FxIVz2FvaOptlnttvMoqMbhm0rhsPm3vOVX9NLAge1oE6HvtyKmJDclhsMkG5mRMk5awVpspuJImDoqAKYDEkHTbMziEWk2iMiCbkRMkLnJzyOmE0HTkhThdNkTFOxtfrjaoLYgAHVNNgAKLZXJtq3BruBad9gdDg3ghDGJyWEhOe8GWzzIswudYkiEvfvT5BjsISH2m4053oirhTDIqxyvKQA */
  tsTypes: {} as import('./machine.typegen').Typegen0,
  schema: {
    context: {} as PermissionsMachineContext,
    events: {} as PermissionsMachineActions,
  },
  context: {
    videoPermissionStatus: null,
    videoPermission: DevicePermission.PROMPT,
    audioPermissionStatus: null,
    audioPermission: DevicePermission.PROMPT,
  } as PermissionsMachineContext,
  predictableActionArguments: true,
  initial: 'idle',
  states: {
    idle: {
      invoke: {
        src: getDevicesPermissionStatus,
        onDone: {
          target: 'active',
          actions: assign({
            videoPermissionStatus: (_, event) =>
              event.data.videoPermissionStatus,
            audioPermissionStatus: (_, event) =>
              event.data.audioPermissionStatus,
          }),
        },
        onError: 'error',
      },
    },
    active: {
      invoke: {
        src:
          (context: PermissionsMachineContext) =>
          (callback: Sender<PermissionsMachineActions>) => {
            const { videoPermissionStatus, audioPermissionStatus } = context;

            //prettier-ignore
            const videoHandler = (e: any) =>
              callback({ type: PermissionsMachineEvent.UPDATE_VIDEO, data: e?.target?.state });

            //prettier-ignore
            const audioHandler = (e: any) =>
              callback({ type: PermissionsMachineEvent.UPDATE_AUDIO, data: e?.target?.state });

            videoPermissionStatus?.addEventListener('change', videoHandler);
            audioPermissionStatus?.addEventListener('change', audioHandler);

            //prettier-ignore
            return () => {
              videoPermissionStatus?.removeEventListener('change', videoHandler);
              audioPermissionStatus?.removeEventListener('change', audioHandler);
            };
          },
      },
      initial: 'idle',
      states: {
        idle: {
          invoke: {
            src: async (context: PermissionsMachineContext) => {
              const { videoPermissionStatus, audioPermissionStatus } = context;

              if (!videoPermissionStatus || !audioPermissionStatus) {
                throw new Error('navigator.permission does not exist');
              }

              if (
                videoPermissionStatus.state === audioPermissionStatus.state &&
                videoPermissionStatus.state !== DevicePermission.PROMPT
              ) {
                return {
                  videoPermission: videoPermissionStatus.state,
                  audioPermission: audioPermissionStatus.state,
                };
              }

              //prettier-ignore
              return executeDevicesPermission({
                videoPermission: videoPermissionStatus.state as DevicePermission,
                audioPermission: audioPermissionStatus.state as DevicePermission,
              });
            },
            onDone: {
              actions: assign({
                videoPermission: (_, event) => event.data.videoPermission,
                audioPermission: (_, event) => event.data.audioPermission,
              }),
              target: 'completed',
            },
            onError: 'completedWithError',
          },
        },
        completed: {
          entry: [
            send((context) => ({
              type: PermissionsMachineEvent.UPDATE_VIDEO,
              data: context.videoPermission,
            })),
            send((context) => ({
              type: PermissionsMachineEvent.UPDATE_AUDIO,
              data: context.audioPermission,
            })),
          ],
        },
        completedWithError: {
          type: 'final',
        },
      },
    },
    error: {
      type: 'final',
    },
  },
});

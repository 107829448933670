import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '../../../shared/ui';
import { authUserModel } from '../../../entities/auth-user';
import { workspaceModel } from '../model';

export const MobileWalkthroughModal: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(authUserModel.selectors.selectUserWithError);
  const [isShow, setIsShow] = useState(user.isShowMobileWalkthroughModal);

  const handleClose = () => {
    dispatch(workspaceModel.actions.closeMobileWalkthroughModal());
    setIsShow(false);
  };

  return (
    <Modal
      isOpen={isShow}
      isShowCancelButton={false}
      onClose={() => null}
      className="!max-w-100 !p-0 mx-2 relative !min-h-113 overflow-hidden !pb-6"
    >
      <img
        src={`../../../assets/images/mobile-onboarding-cover.png`}
        className="w-full h-56 object-cover"
        alt="Walkthrough modal"
      />
      <div className="px-6 mt-5">
        <h1 className="text-2xl font-medium text-gray-900 text-center">
          Explore on Desktop
        </h1>
        <p className="text-gray-600 text-center mt-3">
          Unlock a richer app experience on desktop, as some features like
          editor are restricted on mobile or tablet.
        </p>
        <Button
          variant="text"
          size="lg"
          color="primary"
          className="mx-auto mt-6 w-33"
          onClick={handleClose}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};

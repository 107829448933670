import {
  GetPageAnalyticsResponseType,
  DeletePageAnalyticsResponseType,
  GetPageAnalyticsQueryParamsType,
} from '@distribute/shared/api-types/analytics';
import { api } from './instance';
import { getQueryString } from '../../lib';

export const analyticsApi = {
  async getPageAnalytics(
    pageId: string,
    options: GetPageAnalyticsQueryParamsType
  ) {
    const { from, to } = options;
    const { data } = await api.get<GetPageAnalyticsResponseType>(
      `analytics/${pageId}?${getQueryString({
        from: from?.toISOString(),
        to: to?.toISOString(),
      })}`
    );

    return data;
  },
  async resetPageAnalytics(pageId: string) {
    const { data } = await api.delete<DeletePageAnalyticsResponseType>(
      `analytics/${pageId}`
    );

    return data;
  },
};

import React, { FC } from 'react';
import classNames from 'classnames';

type Props = {
  size: 'small' | 'medium' | 'large';
  isDisabled?: boolean;
  isError?: boolean;
} & React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export const Link: FC<Props> = ({
  size,
  isDisabled,
  isError = false,
  onClick,
  className,
  children,
  ...rest
}) => (
  <a
    href={rest.href}
    onClick={onClick}
    className={classNames('font-medium !no-underline', className, {
      'text-primary-700 hover:text-primary-600 active:text-primary-500 hover:cursor-pointer':
        !isDisabled && !isError,
      'text-gray-300 hover:cursor-not-allowed': isDisabled,
      'text-xs': size === 'small',
      'text-s': size === 'medium',
      'text-md': size === 'large',
      'text-error-500 hover:text-error-500 active:text-error-500 hover:cursor-pointer':
        !isDisabled && isError,
    })}
    {...rest}
  >
    {children}
  </a>
);

import React from 'react';
import { TrendCategory } from '@distribute/shared/types';
import cn from 'classnames';
import { IconMap } from '../../../../shared/sprite';
import { Icon } from '../../../../shared/ui';

type Props = {
  trend: TrendCategory;
  isPublic: boolean;
};

const badgeByTrend = {
  [TrendCategory.NEW]: {
    name: 'New',
    styles: 'bg-success-50 text-success-700 border-success-200',
    icon: IconMap.Dot,
    iconStyles: 'text-success-500',
  },
  [TrendCategory.ACCELERATING]: {
    name: 'Heating',
    styles: 'bg-warning-50 text-warning-700 border-warning-200',
    icon: IconMap.Sun,
    iconStyles: 'text-warning-500',
  },
  [TrendCategory.ENGAGED]: {
    name: 'Hot',
    styles: 'bg-error-50 text-error-700 border-error-200',
    icon: IconMap.Fire,
    iconStyles: 'text-error-500',
  },
  [TrendCategory.DECELERATING]: {
    name: 'Cooling',
    styles: 'bg-blue-75 text-blue-500 border-blue-300',
    icon: IconMap.Wind02,
    iconStyles: 'text-blue-400',
  },
  [TrendCategory.STAGNANT]: {
    name: 'Cold',
    styles: 'bg-purple-200 text-purple-700 border-purple-300',
    icon: IconMap.SnowFlake01,
    iconStyles: 'text-purple-500',
  },
};

const notPublicBadge = {
  name: 'Not public',
  styles: 'bg-gray-50 text-gray-700 border-gray-200',
  icon: IconMap.Dot,
  iconStyles: 'text-gray-500',
};

export const TrendBadge: React.FC<Props> = ({ trend, isPublic }) => {
  const currentBadge = isPublic ? badgeByTrend[trend] : notPublicBadge;
  if (!currentBadge) return null;

  return (
    <span
      className={cn(
        'flex gap-1 items-center border rounded-2xl py-1 px-2 w-fit-content text-xs font-medium whitespace-nowrap',
        `${currentBadge.styles}`
      )}
    >
      <Icon
        width={12}
        glyph={currentBadge.icon}
        className={currentBadge.iconStyles}
      />
      {currentBadge.name}
    </span>
  );
};

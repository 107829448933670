import { FC, useRef } from 'react';
import ReactPlayer from 'react-player';

import cn from 'classnames';

import {
  usePlayer,
  usePlayerActions,
  usePlayerBubble,
  usePlayerBubbleActions,
  useRetry,
} from '../../hooks';

import { PlayerBubbleOverlay } from './PlayerBubbleOverlay';
import { PlayerBubblePreview } from './PlayerBubblePreview';
import { PlayerBubbleTooltip } from './PlayerBubbleTooltip';

import { PlayerBubbleControls } from './PlayerBubbleControls';
import { Icon, Icons } from '../../icons';

export const PlayerBubble: FC = () => {
  const playerRef = useRef<ReactPlayer>(null);

  const { state, config } = usePlayer();

  const {
    state: stateBubble,
    config: { interactive },
  } = usePlayerBubble();

  const {
    handleStart,
    handlePlay,
    handlePause,
    handleEnd,
    handleDuration,
    handleProgress,
  } = usePlayerActions();

  const { handleMinify } = usePlayerBubbleActions();

  const { handleError } = useRetry(playerRef);

  const { url, aspectRatio } = config;
  const { isPlaying, playbackRate, volume, isStarted } = state;
  const { isMinimized } = stateBubble;

  const isShowMinButton = interactive && !isStarted && !isMinimized;
  const isShowMaxButton = interactive && !isStarted && isMinimized;

  return (
    <div
      className={cn(
        'relative cursor-auto outline-none transition-all duration-150 group transform-gpu',

        {
          'w-16 h-16 overflow-hidden rounded-full':
            !isStarted && isShowMaxButton,
          'sm:w-36 sm:h-36 sm:hover:w-40 sm:hover:h-40 w-56 h-56 hover:w-60 hover:h-60':
            !isStarted && !isShowMaxButton,
          '!w-80 !h-80': isStarted,
        }
      )}
      style={{ aspectRatio: config.aspectRatio }}
    >
      <div className="absolute inset-0 overflow-hidden rounded-xl">
        <ReactPlayer
          className="relative bg-base-black scale-x-[-1]"
          ref={playerRef}
          url={url}
          width="100%"
          height="100%"
          style={{ visibility: isStarted ? 'visible' : 'hidden' }}
          playbackRate={playbackRate}
          playsinline
          playing={isPlaying}
          volume={volume}
          onDuration={handleDuration}
          onProgress={handleProgress}
          onError={handleError}
          onPause={handlePause}
          onPlay={handlePlay}
          onEnded={() => handleEnd(playerRef)}
          config={{
            file: {
              attributes: {
                style: { aspectRatio },
                className: 'w-full bg-base-black',
                controlsList: 'nodownload',
              },
            },
          }}
        />
        <PlayerBubbleOverlay />
        <PlayerBubbleControls playerRef={playerRef} />
        <PlayerBubblePreview />
      </div>
      <div
        className={cn('absolute -top-2 -right-2 z-[1]', {
          invisible: !isShowMinButton,
        })}
      >
        <button
          onClick={handleMinify}
          type="button"
          className={
            'rounded-full grid place-items-center w-9 h-9 bg-primary-solid'
          }
        >
          <Icon svg={Icons.Cross} size="sm" className="text-base-white/90" />
        </button>
      </div>

      <div
        className={cn('absolute inset-0 grid place-items-center z-[1]', {
          invisible: !isShowMaxButton,
        })}
      >
        <button
          onClick={handleStart}
          type="button"
          className={
            'rounded-full grid place-items-center w-16 h-16 bg-primary-solid'
          }
        >
          <Icon svg={Icons.Sound} size="sm" className="text-base-white" />
        </button>
      </div>
      <PlayerBubbleTooltip />
    </div>
  );
};

import { FC, useContext, useMemo } from 'react';
import context from './context';
import {
  addNewEmptyContact,
  ContactType,
  contactTypes,
  deserializeContacts,
  serializeContacts,
} from '@distribute/shared/generate-html';
import { Icon, Select } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import ContactInput, { contactConfigTypeConfig } from './ContactInput';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

const Contacts: FC = () => {
  const { form } = useContext(context);

  const state = form.watch();

  const contacts = useMemo(
    () => deserializeContacts(state.serializedContacts),
    [state.serializedContacts]
  );

  const onAddEmptyContact = (contactType: string) => {
    form.setValue(
      'serializedContacts',
      addNewEmptyContact(state.serializedContacts, contactType as ContactType)
    );
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return;
    }

    const [removed] = contacts.splice(result.source.index, 1);
    contacts.splice(result.destination.index, 0, removed);

    contacts.forEach((contact, index) => {
      contact.order = index;
    });

    form.setValue('serializedContacts', serializeContacts(contacts));
  };

  return (
    <div className="flex flex-col gap-y-4 pb-6">
      <span className="font-semibold">Contacts</span>
      <DragDropContext onDragEnd={onDragEnd} enableDefaultSensors={true}>
        <Droppable droppableId="contacts" direction="vertical">
          {(provided) => (
            <div
              className="flex flex-col gap-y-2"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {contacts.map((contact) => (
                <ContactInput
                  {...contact}
                  isDragDisabled={contacts.length === 1}
                  key={contact.id}
                />
              ))}
              {provided.placeholder}
              <Select
                className="w-min"
                items={contactTypes.map((type) => ({
                  id: type,
                  title: contactConfigTypeConfig[type].title,
                  iconName: contactConfigTypeConfig[type].icon,
                }))}
                label="Add Contact"
                onChange={onAddEmptyContact}
                hideLabel={true}
                hideArrow={true}
                currentValue={{
                  id: 'add-contact',
                  title: (
                    <span className="text-sm flex flex-row flex-nowrap gap-x-1">
                      <Icon glyph={IconMap.Plus} width={20} /> Add contact item
                    </span>
                  ),
                }}
                value=" "
              />
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Contacts;

import { call, put, select } from 'redux-saga/effects';
import { actions } from '../reducer';
import { pagesOverviewApi } from '../../../../shared/api';
import { createNotification, snackbarModel } from '../../../snackbar';
import { logger } from '../../../../shared/lib';
import { teamsModel } from '../../../teams';
import { PageOverviewTotal } from '@distribute/shared/types';

export function* getPagesTotal() {
  const { id: currentTeamId }: { id: number } = yield select(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  yield put(actions.setIsLoadingTotal(true));

  try {
    const data: PageOverviewTotal = yield call(pagesOverviewApi.getPagesTotal, {
      teamId: currentTeamId,
    });

    yield put(actions.setTotal(data));
  } catch (err: unknown) {
    logger.error(err);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to show total`)
      )
    );
  } finally {
    yield put(actions.setIsLoadingTotal(false));
  }
}

import { all, call, put, select } from 'redux-saga/effects';
import { teamsModel } from '../../teams';
import { logger } from '../../../shared/lib';
import { snippetsModel } from '../model';
import {
  GetSnippetsResponseType,
  GetSnippetsCategoriesResponseType,
} from '@distribute/shared/api-types/snippets';
import { snippetsApi } from '../../../shared/api/axios/snippets';
import { authUserModel } from '../../../entities/auth-user';
import { User } from '@distribute/shared/types';

export function* setSnippetsData() {
  try {
    const { id: currentTeamId } = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    const currentUser: User = yield select(
      authUserModel.selectors.selectUserWithError
    );

    const [
      snippetsCategories,
      personalSnippets,
      draftSnippets,
      recentSnippets,
    ]: [
      GetSnippetsCategoriesResponseType,
      GetSnippetsResponseType,
      GetSnippetsResponseType,
      GetSnippetsResponseType
    ] = yield all([
      call(snippetsApi.getSnippetsCategories, currentTeamId),
      call(snippetsApi.getPersonalSnippets, currentUser.id, currentTeamId),
      call(snippetsApi.getDraftSnippets, currentUser.id, currentTeamId),
      call(snippetsApi.getRecentSnippets),
    ]);

    yield put(snippetsModel.actions.setCategories(snippetsCategories));
    yield put(snippetsModel.actions.setPersonalSnippets(personalSnippets));
    yield put(snippetsModel.actions.setDraftSnippets(draftSnippets));
    yield put(snippetsModel.actions.setRecentSnippets(recentSnippets));
  } catch (err) {
    logger.error(err);
  }
}

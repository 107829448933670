export const formatNumber = (
  value: number,
  locales: string | string[] = 'en-US'
) => new Intl.NumberFormat(locales).format(value);

export const formatAmountToCurrency = (
  value: number,
  locales: string | string[] = 'en-US',
  currency = 'USD'
) =>
  new Intl.NumberFormat(locales, { currency, style: 'currency' }).format(value);

export const formatToPositiveNumberOrZero = (value: number) =>
  value < 0 ? 0 : value;

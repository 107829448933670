import {
  Page,
  PageTeamMembersPermission,
  User,
  UserPermissionToPageRole,
} from '@distribute/shared/types';
import { FolderPermissions } from '../../folders';

export type PagePermissions = {
  isCanEditDocumentContent: boolean;
  isCanEditPageStyles: boolean;
  isCanDelete: boolean;
  isCanViewPages: boolean;
  isCanEditConversionTools: boolean;
  isCanEditVideoNarration: boolean;
  isCanEditPageSettings: boolean;
  isCanManageAccess: boolean;
  isCanInviteMembers: boolean;
  isCanTransferOwnership: boolean;
  isCanPublishPage: boolean;
};

export const getPagePermissions = (
  currentUser: User,
  folderPermissions: FolderPermissions,
  page?: Page
): PagePermissions => {
  const currentUserRole = page?.userPermissionToPages.find((item) =>
    item.user
      ? item.user.id === currentUser.id
      : item.externalUserEmail === currentUser.email
  );
  const isOwner = currentUserRole?.role === UserPermissionToPageRole.OWNER;
  const isOwnerOrEditor = currentUserRole
    ? currentUserRole.role === UserPermissionToPageRole.OWNER ||
      currentUserRole.role === UserPermissionToPageRole.EDITOR
    : page?.teamMembersPermission === PageTeamMembersPermission.EDIT;

  return {
    isCanEditDocumentContent:
      isOwnerOrEditor || folderPermissions.isCanEditPages,
    isCanEditPageStyles: isOwnerOrEditor || folderPermissions.isCanEditPages,
    isCanDelete: folderPermissions.isCanDeleteAllPages || isOwner,
    isCanViewPages: true,
    isCanEditVideoNarration:
      isOwnerOrEditor || folderPermissions.isCanEditPages,
    isCanEditConversionTools:
      isOwnerOrEditor || folderPermissions.isCanEditPages,
    isCanEditPageSettings: isOwnerOrEditor || folderPermissions.isCanEditPages,
    isCanManageAccess: folderPermissions.isCanManageAccess || isOwnerOrEditor,
    isCanInviteMembers: folderPermissions.isCanInviteMembers || isOwnerOrEditor,
    isCanTransferOwnership: folderPermissions.isCanTransferOwnership || isOwner,
    isCanPublishPage: folderPermissions.isCanPublishPages || isOwnerOrEditor,
  };
};

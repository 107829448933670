import { ReactNodeViewRenderer } from '@tiptap/react';
import { UploadFieldExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    uploadField: {
      insertUploadField: () => ReturnType;
    };
  }
}

export const UploadField = UploadFieldExt.extend({
  addCommands() {
    return {
      insertUploadField:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'uploadField',
            attrs: {
              serializedFiles: JSON.stringify([]),
            },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(() => null);
  },
});

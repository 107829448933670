import { Sender } from 'xstate';

import { sleep } from '../../../../../shared/lib';

import {
  concatArrayBuffers,
  getStartEndBytes,
  checkIsDivisible,
} from '../lib/buffers';

import {
  SUCCESS_CHUNK_STATUS,
  SUCCESS_FINAL_CHUNK_STATUS,
  TIME_TO_WAIT,
} from '../config';

import { MediaRecorderProxy } from '../lib/media-recorder-proxy';

import {
  NarrationMachineActions,
  NarrationMachineContext,
  NarrationMachineEvent,
} from './types';

import { videoNarrationUploadApi } from '../../../../../shared/api';

export const createMediaRecorder =
  (context: NarrationMachineContext) =>
  (callback: Sender<NarrationMachineActions>) => {
    const handler = () => callback({ type: NarrationMachineEvent.STOP });

    const mediaRecorderProxy = new MediaRecorderProxy({
      stream: context.mediaStream!,
      timeSlice: 5000,
      ondataavailable: (event) => {
        if (event.data.size) {
          callback({ type: NarrationMachineEvent.BLOB, data: event.data });
        }
      },
    });

    const videoTrack = context.mediaStream?.getVideoTracks()[0];
    videoTrack?.addEventListener('ended', handler);

    callback({ type: NarrationMachineEvent.CREATED, data: mediaRecorderProxy });

    return () => {
      mediaRecorderProxy.destroy();
      videoTrack?.removeEventListener('ended', handler);
    };
  };

export const transformChunks = async (context: NarrationMachineContext) => {
  const { lastBlobIndex, blobs, buffer } = context;

  if (blobs.length - 1 < lastBlobIndex)
    return {
      buffer,
      lastBlobIndex,
    };

  const arrayBuffers = await Promise.all(
    context.blobs.slice(lastBlobIndex, blobs.length).map((b) => b.arrayBuffer())
  );

  return {
    buffer: new Uint8Array([...buffer, ...concatArrayBuffers(...arrayBuffers)]),
    lastBlobIndex: blobs.length,
  };
};

export const uploadChunkFinal = async (context: NarrationMachineContext) => {
  const { lastBufferChunk, buffer, uploadUrl } = context;

  if (buffer.byteLength === 0)
    return {
      isFinal: true,
      lastBufferChunk,
    };

  const { start, end } = getStartEndBytes(lastBufferChunk);

  const chunk = buffer.slice(start, end);

  if (!checkIsDivisible(chunk.byteLength)) {
    const status = await videoNarrationUploadApi.chunkUpload({
      chunk,
      url: uploadUrl,
      range: `bytes ${start}-${buffer.byteLength - 1}/${buffer.byteLength}`,
    });

    if (!SUCCESS_FINAL_CHUNK_STATUS.includes(status)) {
      throw new Error('Incorrect status for the chunk upload.');
    }

    return {
      isFinal: true,
      lastBufferChunk,
    };
  }

  const status = await videoNarrationUploadApi.chunkUpload({
    chunk,
    url: uploadUrl,
    range: `bytes ${start}-${end - 1}/*`,
  });

  if (!SUCCESS_CHUNK_STATUS.includes(status)) {
    throw new Error('Incorrect status for the chunk upload.');
  }

  return {
    isFinal: false,
    lastBufferChunk: lastBufferChunk + 1,
  };
};

export const uploadChunk = async (context: NarrationMachineContext) => {
  const { lastBufferChunk, buffer, uploadUrl } = context;

  if (buffer.byteLength === 0) {
    await sleep(TIME_TO_WAIT);

    return {
      lastBufferChunk,
    };
  }
  const { start, end } = getStartEndBytes(lastBufferChunk);

  const chunk = buffer.slice(start, end);

  if (!checkIsDivisible(chunk.byteLength)) {
    await sleep(TIME_TO_WAIT);

    return {
      lastBufferChunk,
    };
  }

  const status = await videoNarrationUploadApi.chunkUpload({
    chunk,
    url: uploadUrl,
    range: `bytes ${start}-${end - 1}/*`,
  });

  if (!SUCCESS_CHUNK_STATUS.includes(status)) {
    throw new Error('Incorrect status for the chunk upload.');
  }

  return {
    lastBufferChunk: lastBufferChunk + 1,
  };
};

export const generateUploadSession = async ({
  pageId,
}: NarrationMachineContext) => {
  if (!pageId) {
    throw new Error('Required inputs not provided.');
  }

  return videoNarrationUploadApi.generateSession({ pageId });
};

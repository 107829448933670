import { FC } from 'react';

import cn from 'classnames';

import { PlayerButton } from './PlayerButton';
import { Slider } from './Slider';
import { Icon, Icons } from '../../../icons';
import { PlayerTooltip } from './PlayerTooltip';
import { PlayerKey } from './PlayerKey';

import { usePlayer, usePlayerActions, useSize } from '../../../hooks';

type Props = { isSliderVisible?: boolean };

export const PlayerVolume: FC<Props> = ({ isSliderVisible }) => {
  const { state, config } = usePlayer();
  const { handleToggleVolume, handleSetVolume } = usePlayerActions();

  const { size } = useSize();

  const { volume, isFullscreen } = state;
  const { hotKeys } = config;

  const sizeControl = isFullscreen ? 'md' : 'sm';

  return (
    <div className="flex flex-nowrap gap-x-2 items-center group/volume">
      <PlayerTooltip
        isOpen={isFullscreen || hotKeys ? undefined : false}
        size={sizeControl}
        trigger={
          <PlayerButton
            size={sizeControl}
            variant="transparent"
            onClick={handleToggleVolume}
          >
            <Icon
              svg={volume === 0 ? Icons.VolumeOff : Icons.VolumeOn}
              size={sizeControl}
            />
          </PlayerButton>
        }
      >
        <span>{volume === 0 ? 'Unmute' : 'Mute'}</span>
        <PlayerKey size={sizeControl}>M</PlayerKey>
      </PlayerTooltip>
      {isSliderVisible && (
        <div
          className={cn('invisible group-hover/volume:visible', {
            'w-20': size === 'md',
            'w-10': size === 'sm',
          })}
        >
          <Slider
            variant="range"
            id="volume"
            min={0}
            max={1}
            step={0.05}
            value={[volume]}
            onValueChange={handleSetVolume}
            className="h-3"
            size={sizeControl}
          />
        </div>
      )}
    </div>
  );
};

import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import '../../../../entities/tiptap-editor/styles/main-editor.css';
import { EditorsPageWrapper } from '../../../../shared/ui';
import { LineActionMenuProvider } from '../../../../entities/tiptap-editor/extensions/LineActionMenu/lib/LineActionMenuContext';

import {
  EditorInsertBottomLine,
  TiptapEditor,
} from '../../../../entities/tiptap-editor';
import { TiptapTextBubbleMenu } from '../../../../entities/tiptap-editor/ui/basic-editor/TiptapTextBubbleMenu';
import { TableMenu } from '../../../../entities/tiptap-editor/extensions/Table';
import { TiptapCalloutBubbleMenu } from '../../../../entities/tiptap-editor/ui/basic-editor/TiptapCalloutBubbleMenu';
import { HoverInsertAndDragMenu } from '../../../../entities/tiptap-editor/ui/hovering-buttons';
import { MenuListWrapper } from '../../../../entities/tiptap-editor/extensions/TriggerMenu/MenuListWrapper';
import classNames from 'classnames';
import { aiModel } from '../../../../features/ai';
import { EmptyPageShortcuts } from '../../../editor/ui/components/EmptyPageShortcuts';

type Props = {
  isSidebarOpen: boolean;
};

export const SnippetTiptapEditorPage: React.FC<Props> = ({ isSidebarOpen }) => {
  const isAIInputRendered = useSelector(
    aiModel.selectors.selectIsAIInputRendered
  );

  const emptyPageShortcutsRef = useRef<HTMLDivElement | null>(null);

  return (
    <EditorsPageWrapper isEditorSidebarOpen={isSidebarOpen}>
      <TiptapTextBubbleMenu />
      <TableMenu />
      <TiptapCalloutBubbleMenu />
      <LineActionMenuProvider>
        <HoverInsertAndDragMenu />
      </LineActionMenuProvider>
      <MenuListWrapper isHideSwitcher />
      <div className="flex justify-center flex-grow mt-10">
        <div
          className={classNames(
            'flex flex-col w-full gap-6 max-w-212 md:px-8 sm:px-4',
            {
              'pb-[600px]': isAIInputRendered,
              'pb-[max(30vh,180px)]': !isAIInputRendered,
              'xl:pl-20 xl:pr-5': isSidebarOpen,
            }
          )}
        >
          <div className="">
            <EmptyPageShortcuts
              ref={emptyPageShortcutsRef}
              onOpenTemplatesModal={() => {
                return;
              }}
              isSnippetMode={true}
              isTemplateMode={false}
            />
            <TiptapEditor className="main-editor-wrapper" />
            <EditorInsertBottomLine />
          </div>
        </div>
      </div>
    </EditorsPageWrapper>
  );
};

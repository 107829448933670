import { FC } from 'react';
import { useSelector } from 'react-redux';

import { recordingsModel } from '../../model';
import { authUserModel } from '../../../../entities/auth-user';

import { RecordsReached } from './RecordGetUnlimited';
import { subscriptionModel } from '../../../subscription';

export const RecordStatus: FC = () => {
  const { maxVideoRecords } = useSelector(
    subscriptionModel.selectors.selectSubscriptionLimits
  );

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const records = useSelector(
    recordingsModel.selectors.selectRecordsMy(currentUser.id)
  );

  const isAlmostReached =
    records.length && records.length / maxVideoRecords >= 0.7;
  const isReached = maxVideoRecords <= records.length;

  return isReached || isAlmostReached ? (
    <RecordsReached
      value={records.length}
      max={maxVideoRecords}
      isReached={isReached}
    />
  ) : null;
};

import { FC } from 'react';
import { PlayerBubbleLoader } from '@distribute/frontend/ui/player';

export const NarrationBubbleLoader: FC = () => {
  return (
    <div className="w-56 h-56 overflow-hidden rounded-xl relative">
      <div className="absolute inset-0">
        <PlayerBubbleLoader />
      </div>
    </div>
  );
};

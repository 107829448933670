import React, { PropsWithChildren, ReactElement } from 'react';

interface State {
  hasError: boolean;
}

interface PlayerErrorBoundaryProps {
  fallback: ReactElement;
}

export class PlayerErrorBoundary extends React.Component<
  PropsWithChildren<PlayerErrorBoundaryProps>,
  State
> {
  constructor(props: PlayerErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  override componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error('Uncaught player error:', error, errorInfo);
  }

  override render() {
    return this.state.hasError ? this.props.fallback : this.props.children;
  }
}

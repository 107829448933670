import { MuxMetadata } from './mux';

export enum VideoNarrationStatus {
  UPLOADING = 'uploading',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum VideoNarrationStatusVerification {
  IDLE = 'idle',
  CANCELLED_DUE_MAX_DURATION_LIMIT = 'cancelled_due_max_duration_limit',
  CANCELLED_DUE_MAX_SIZE_LIMIT = 'cancelled_due_max_size_limit',
  PASSED = 'passed',
  FAILED = 'failed',
}

export enum VideoNarrationStatusConvert {
  IDLE = 'idle',
  CONVERTING = 'converting',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum VideoNarrationStatusDeleteRequest {
  IDLE = 'idle',
  DELETING = 'deleting',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

export type VideoNarrationMetadata = {
  mux: MuxMetadata;
};

export type VideoNarration = {
  id: string;
  videoSessionLimit: number;
  prefix: string;
  plan: string;
  uploadId: string;
  metadata: VideoNarrationMetadata;
  muxId: string | null;
  playbackId: string | null;
  pageId: string | null;
  _pageId: string;
  status: VideoNarrationStatus;
  statusConvert: VideoNarrationStatusConvert;
  statusDeleteRequest: VideoNarrationStatusDeleteRequest;
  statusVerification: VideoNarrationStatusVerification;
  createdAt: Date;
  updatedAt: Date;
};

export type VideoNarrationDetail = Pick<
  VideoNarration,
  'id' | 'statusConvert' | 'metadata' | 'playbackId'
> & { pageId: string };

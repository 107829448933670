import { ReactNodeViewRenderer } from '@tiptap/react';
import { CustomTaskListItemNodeView } from './CustomTaskListItem.renderer';
import { CustomTaskListItemExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    taskListItem: {
      insertTaskListItem: () => ReturnType;
      toggleTaskListItem: () => ReturnType;
    };
  }
}
export const CustomTaskListItem = CustomTaskListItemExt.extend({
  addCommands() {
    return {
      toggleTaskListItem:
        () =>
        ({ commands }) => {
          return commands.toggleWrap(this.name);
        },
      insertTaskListItem:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            content: [
              {
                type: 'paragraph',
              },
            ],
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomTaskListItemNodeView);
  },
});

import { FC, useEffect } from 'react';
import '../../../../shared/styles/generate-html.css';
import {
  addBreaks,
  addTasklistData,
  addTimelineDueDate,
  handleHtmlContentElementClick,
  highlightCodeBlock,
  setCorrectColumnWidth,
} from '@distribute/shared/generate-html';
import('@distribute/frontend/web-components');

type Props = {
  htmlContent: string;
};

export const EditorHtmlContent: FC<Props> = ({ htmlContent }) => {
  useEffect(() => {
    highlightCodeBlock();
    setCorrectColumnWidth();
    addBreaks();
    addTimelineDueDate();
    addTasklistData();
  }, []);

  return (
    <div
      className="rich-text-content"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      onClick={handleHtmlContentElementClick}
    ></div>
  );
};

import {
  VideoNarrationGenerateSessionParamsType,
  VideoNarrationGenerateSessionResponseType,
  VideoNarrationSessionParamsType,
} from '@distribute/shared/api-types/video-narration';

import { api as apiAuth } from './instance';

import axios, { AxiosError } from 'axios';

export const api = axios.create({
  timeout: 60000,
});

export type ChunkUploadParams = {
  url: string;
  chunk: Uint8Array;
  range: string;
};

export const videoNarrationUploadApi = {
  async generateSession({ pageId }: VideoNarrationGenerateSessionParamsType) {
    const { data: responseData } = await apiAuth.post(
      `video-narration-upload/${pageId}/generate-session`
    );
    return responseData as VideoNarrationGenerateSessionResponseType;
  },

  async cancelSession({ uploadId }: VideoNarrationSessionParamsType) {
    try {
      await apiAuth.post(`video-narration-upload/${uploadId}/cancel-session`);
    } catch (e: unknown) {
      return;
    }
  },
  async completeSession({ uploadId }: VideoNarrationSessionParamsType) {
    try {
      await apiAuth.post(`video-narration-upload/${uploadId}/complete-session`);
    } catch (e: unknown) {
      return;
    }
  },

  async chunkUpload({ chunk, range, url }: ChunkUploadParams) {
    try {
      const response = await api.put(url, chunk, {
        headers: {
          'Content-Range': range,
        },
      });

      return response.status;
    } catch (_e: unknown) {
      const e = _e as AxiosError;

      return e.response?.status || 500;
    }
  },
};

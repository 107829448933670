import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Icon, Tooltip } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { pagesModel } from '../../../features/pages';

export const MoveAlertBar = () => {
  const dispatch = useDispatch();
  const navigationData = useSelector(
    pagesModel.selectors.selectContentNavigationData
  );
  const currentTab = useSelector(
    pagesModel.selectors.selectContentItemById(navigationData?.tab?.id)
  );
  const handleNavigate = () => {
    if (currentTab) {
      dispatch(pagesModel.actions.setCurrentContent(currentTab));
    }
  };

  if (!navigationData) return null;

  const isBack = navigationData.direction === 'back';
  const directionText = isBack ? 'Return' : 'Go to';

  return (
    <div className="sticky bottom-16 left-0 w-full flex justify-center z-100">
      <div className="flex gap-2 bg-gray-800 rounded-lg text-base-white py-1.5 px-3 max-w-11/12">
        <p className="truncate">Moved to {navigationData.tab.name}</p>
        <span className="text-base-white/10">|</span>
        <div className="flex gap-2 text-gray-300">
          <Tooltip
            sideOffset={8}
            triggerClassNames="flex"
            hideArrow
            trigger={
              <button onClick={handleNavigate}>
                <Icon
                  className={cn({ 'transform rotate-180': !isBack })}
                  glyph={IconMap.ArrowSquareLeft}
                />
              </button>
            }
          >
            {directionText}
          </Tooltip>
          {/*TODO: will be implemented later*/}
          {/*<Tooltip*/}
          {/*  sideOffset={8}*/}
          {/*  triggerClassNames="flex"*/}
          {/*  hideArrow*/}
          {/*  trigger={*/}
          {/*    <button>*/}
          {/*      <Icon glyph={IconMap.ReverseLeft} />*/}
          {/*    </button>*/}
          {/*  }>*/}
          {/*  Undo*/}
          {/*</Tooltip>*/}
        </div>
      </div>
    </div>
  );
};

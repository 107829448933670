import { fork, put, takeEvery } from 'redux-saga/effects';

import { recorderSagaWorker } from './recorder-sagas';
import { devicesSagaWorker } from './devices-sagas';

import { watchNarrationStatus } from './watch-narration-status';
import { narrationModel } from '../';

import { deleteNarration } from '../../lib';

export function* narrationWorker() {
  yield put(narrationModel.actions.resetAll());

  yield fork(watchNarrationStatus);
  yield fork(recorderSagaWorker);
  yield fork(devicesSagaWorker);

  yield takeEvery(narrationModel.actions.deleteNarration, deleteNarration);
}

import { useEffect, useRef, useState } from 'react';

export function useIsMounted(isForceUpdate = false): boolean {
  const isMounted = useRef(false);
  const [, forceUpdate] = useState<unknown>(null);

  useEffect(() => {
    isMounted.current = true;

    if (isForceUpdate) {
      forceUpdate({});
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted.current;
}

import React from 'react';
import { Modal, Button, Select } from '../../../shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { Folder, Page } from '@distribute/shared/types';
import { foldersModel } from '../../folders';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../model';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  page: Page;
};

type DuplicateToFolderModalForm = {
  folderId: string;
};

const validationSchema = object().shape({
  folderId: string().required('Folder id is required'),
});

export const DuplicatePageToFolderModal: React.FC<Props> = ({
  isOpen,
  onClose,
  page,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    pagesModel.selectors.selectMovePageToFolderIsLoading
  );

  const foldersToMove: Folder[] = useSelector(
    foldersModel.selectors.selectFoldersToMove
  );

  const foldersList = [...foldersToMove];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<DuplicateToFolderModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const {
    field: { value, onChange },
  } = useController({
    control,
    defaultValue: foldersList[0].id,
    name: 'folderId',
    rules: { required: true },
  });

  const currentValue = foldersList.find((i) => i.id === value);

  const onModalClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit = (data: DuplicateToFolderModalForm) => {
    dispatch(
      pagesModel.actions.duplicatePage({
        id: page.id,
        folderId: data.folderId,
        source: 'editor',
        callback: () => {
          onModalClose();
        },
      })
    );
  };

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Duplicate Page"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          loading={isLoading}
        >
          Duplicate
        </Button>
      }
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Select
          label="Folder"
          value={value}
          onChange={onChange}
          items={foldersList}
          currentValue={currentValue}
        />
      </form>
    </Modal>
  );
};

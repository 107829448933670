import { put, call, select } from 'redux-saga/effects';
import { analyticsModel } from '../';
import { pagesModel } from '../../../pages';
import { Page } from '@distribute/shared/types';

export function* onChangeRange({
  payload: range,
}: ReturnType<typeof analyticsModel.actions.onChangeRange>) {
  const page: Page = yield select(
    pagesModel.selectors.selectCurrentPageWithError
  );
  yield put(analyticsModel.actions.setDateRange(range));
  yield call(analyticsModel.sagas.setAnalyticsData, page, true);
}

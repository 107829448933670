import { FC } from 'react';
import cn from 'classnames';

import { Icon, Icons } from '../../icons';
import { usePlayer, usePlayerActions, useSize } from '../../hooks';

const styles = {
  sm: {
    outerBorder: 'border-2',
    button: 'border-2 w-16 h-16',
    icon: 'w-9 h-9',
  },
  md: {
    outerBorder: 'border-4',
    button: 'w-22 h-22 border-4',
    icon: 'w-12 h-12',
  },
} as const;

export const PlayerPreview: FC = () => {
  const { config } = usePlayer();
  const { handleStart } = usePlayerActions();

  const { size } = useSize();

  const { posterUrl } = config;

  return (
    <div className="relative flex bg-base-black h-full" onClick={handleStart}>
      <div className="absolute inset-0 grid place-items-center bg-base-black/50">
        <div
          className={cn(
            'border-base-white/50 rounded-full',
            styles[size].outerBorder
          )}
        >
          <button
            type="button"
            className={cn(
              'rounded-full border-base-white bg-primary-500 hover:bg-primary-600 grid place-items-center',
              styles[size].button
            )}
          >
            <Icon
              svg={Icons.PlayPreview}
              className={cn('text-base-white', styles[size].icon)}
            />
          </button>
        </div>
      </div>
      <img
        src={posterUrl}
        alt="poster"
        className="block w-full h-full object-contain"
      />
    </div>
  );
};

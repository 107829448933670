import {
  GetPageResponseType,
  CreatePageRequestType,
  CreatePageResponseType,
  DeletePageResponseType,
  MoveToFolderResponseType,
  MoveToFolderRequestType,
  ChangePublicStatusResponseType,
  ChangePublicStatusRequestType,
  UpdatePageRequestType,
  UpdatePageResponseType,
  CheckPageSlugIsUniqueResponseType,
  CheckPageSlugIsUniqueRequestType,
  PageDuplicateResponseType,
  PageDuplicateRequestType,
  UpdateTeamMemberPermissionRequestType,
  UpdateTeamMemberPermissionResponseType,
  CreateUserPermissionRequestType,
  CreateUserPermissionResponseType,
  UpdateUserPermissionResponseType,
  UpdateUserPermissionRequestType,
  DeleteUserPermissionResponseType,
  ChangePagePublicAccessResponseType,
  ChangePagePublicAccessRequestType,
  GetPagePublicInfoResponseType,
  AcceptRequestToEditPageResponseType,
  DeclineRequestToEditPageResponseType,
} from '@distribute/shared/api-types/pages';
import { api } from './instance';
import type { DocumentContentItem } from '@distribute/shared/types';

export const pagesApi = {
  async getPages(teamId: number) {
    const { data } = await api.get<GetPageResponseType>(
      `pages?teamId=${teamId}`
    );
    return data;
  },
  async createPage(data: CreatePageRequestType) {
    const { data: responseData } = await api.post<CreatePageResponseType>(
      'pages',
      data
    );
    return responseData;
  },
  async createPrefilledPage(data: CreatePageRequestType) {
    const { data: responseData } = await api.post<CreatePageResponseType>(
      'pages/prefilled',
      data
    );
    return responseData;
  },
  async deletePage(id: string) {
    const { data: responseData } = await api.delete<DeletePageResponseType>(
      `pages/${id}`
    );
    return responseData;
  },
  async movePageToFolder(pageId: string, data: MoveToFolderRequestType) {
    const { data: responseData } = await api.post<MoveToFolderResponseType>(
      `pages/${pageId}/move-to-folder`,
      {
        folderId: data.folderId,
      }
    );
    return responseData;
  },
  async createPageContentItem({
    pageId,
    data,
  }: {
    pageId: string;
    data: Partial<DocumentContentItem>;
  }) {
    const { data: responseData } = await api.post<UpdatePageResponseType>(
      `pages/${pageId}/content-item`,
      data
    );
    return responseData;
  },
  async updatePageContentItem({
    pageId,
    contentItemId,
    data,
  }: {
    pageId: string;
    contentItemId: number;
    data: Partial<DocumentContentItem>;
  }) {
    const { data: responseData } = await api.patch<UpdatePageResponseType>(
      `pages/${pageId}/content-item/${contentItemId}`,
      data
    );
    return responseData;
  },
  async bulkUpdatePageContentItems({
    pageId,
    data,
  }: {
    pageId: string;
    data: Partial<DocumentContentItem>[];
  }) {
    const { data: responseData } = await api.patch<UpdatePageResponseType>(
      `pages/${pageId}/content-item/bulk`,
      {
        contentItems: data,
      }
    );
    return responseData;
  },
  async duplicatePageContentItem({
    pageId,
    contentItemId,
  }: {
    pageId: string;
    contentItemId: number;
  }) {
    const { data: responseData } = await api.post<UpdatePageResponseType>(
      `pages/${pageId}/content-item/${contentItemId}`
    );
    return responseData;
  },
  async deletePageContentItem({
    pageId,
    contentItemId,
  }: {
    pageId: string;
    contentItemId: number;
  }) {
    const { data: responseData } = await api.delete<UpdatePageResponseType>(
      `pages/${pageId}/content-item/${contentItemId}`
    );
    return responseData;
  },
  async updatePage(id: string, data: UpdatePageRequestType) {
    const { data: responseData } = await api.patch<UpdatePageResponseType>(
      `pages/${id}`,
      data
    );
    return responseData;
  },

  async checkPageSlug(data: CheckPageSlugIsUniqueRequestType) {
    const { data: responseData } =
      await api.post<CheckPageSlugIsUniqueResponseType>(
        `pages/check-slug`,
        data
      );

    return responseData;
  },

  async duplicatePage(id: string, data: PageDuplicateRequestType) {
    const { data: responseData } = await api.post<PageDuplicateResponseType>(
      `pages/${id}/duplicate`,
      {
        folderId: data.folderId,
      }
    );
    return responseData;
  },

  async changePublicStatus(
    pageId: string,
    data: ChangePublicStatusRequestType
  ) {
    const { data: responseData } =
      await api.patch<ChangePublicStatusResponseType>(
        `pages/${pageId}/change-public-status`,
        {
          published: data.published,
        }
      );
    return responseData;
  },

  async updateTeamMemberPermission(
    id: string,
    data: UpdateTeamMemberPermissionRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateTeamMemberPermissionResponseType>(
        `pages/${id}/team-member-permission`,
        data
      );

    return responseData;
  },

  async changePublicAccess(
    pageId: string,
    data: ChangePagePublicAccessRequestType
  ) {
    const { data: responseData } =
      await api.patch<ChangePagePublicAccessResponseType>(
        `pages/${pageId}/change-public-access`,
        {
          permission: data.permission,
        }
      );
    return responseData;
  },

  async createUserPermission(
    id: string,
    data: CreateUserPermissionRequestType
  ) {
    const { data: responseData } =
      await api.post<CreateUserPermissionResponseType>(
        `pages/${id}/user-permission`,
        data
      );

    return responseData;
  },

  async updateUserPermission(
    pageId: string,
    userPermissionId: number,
    data: UpdateUserPermissionRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateUserPermissionResponseType>(
        `pages/${pageId}/user-permission/${userPermissionId}`,
        data
      );

    return responseData;
  },

  async deleteUserPermission(pageId: string, userPermissionId: number) {
    const { data } = await api.delete<DeleteUserPermissionResponseType>(
      `pages/${pageId}/user-permission/${userPermissionId}`
    );

    return data;
  },

  async resendUserPermissionInvitation(
    pageId: string,
    userPermissionId: number
  ) {
    await api.post(
      `pages/${pageId}/user-permission/${userPermissionId}/resend-invitation`
    );
  },

  async deletePageCoverImage(pageId: string) {
    return api.delete(`pages/${pageId}/delete-page-cover`);
  },

  async deletePageBrandLogo(pageId: string) {
    return api.delete(`pages/${pageId}/delete-page-brand-logo`);
  },

  async getPagePublicInfo(sequenceNumber: string) {
    const res = await api.get<GetPagePublicInfoResponseType>(
      `pages/page/${sequenceNumber}/public-info`
    );
    return res.data;
  },

  async requestToEditPage(pageId: string) {
    const res = await api.post(`pages/${pageId}/request-to-edit`);
    return res.data;
  },

  async acceptRequestToEditPage(requestId: string) {
    const res = await api.post<AcceptRequestToEditPageResponseType>(
      `pages/approve-request-to-edit-page/${requestId}`
    );

    return res.data;
  },

  async declineRequestToEditPage(requestId: string) {
    const res = await api.post<DeclineRequestToEditPageResponseType>(
      `pages/decline-request-to-edit-page/${requestId}`
    );

    return res.data;
  },
};

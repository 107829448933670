import { useSelector } from 'react-redux';
import { narrationModel } from '../../model';
import { NarrationModal as NarrationModalEnum } from '../../model/types';
import { NarrationPermissionModal } from './narration-permission-modal';

export const NarrationModal = () => {
  const { modal } = useSelector(narrationModel.selectors.selectNarration);

  const isDenied = useSelector(
    narrationModel.selectors.checkIsPermissionsDenied
  );

  if (modal === NarrationModalEnum.Permission && isDenied) {
    return <NarrationPermissionModal />;
  }

  return null;
};

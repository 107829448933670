import { videoNarrationApi } from '../../../shared/api';
import { call, put, select } from 'redux-saga/effects';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';
import { RT } from '../../../shared/types';
import { narrationModel } from '../model';

export function* deleteNarration() {
  const { narration }: RT<typeof narrationModel.selectors.selectNarration> =
    yield select(narrationModel.selectors.selectNarration);

  if (!narration) {
    //prettier-ignore
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', 'Failed to delete narration')));
    return;
  }

  yield put(narrationModel.actions.setNarration(null));

  try {
    yield call(videoNarrationApi.delete, narration);
  } catch (e: unknown) {
    logger.error(e);
    //prettier-ignore
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', 'Failed to delete narration')));
  }
}

import { put, select } from 'redux-saga/effects';

import { Page } from '@distribute/shared/types';
import { pagesModel } from '../index';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { pagesApi } from '../../../../shared/api/axios/pages';
import { APP_HOST } from '../../../../shared/config';

export function* duplicatePage({
  payload: { id, folderId, source, callback },
}: ReturnType<typeof pagesModel.actions.duplicatePage>) {
  try {
    const pages: Page[] = yield select(pagesModel.selectors.selectPages);
    const page = pages.find((page) => page.id === id);

    if (!page) {
      throw new Error(`Page ${id} not found`);
    }
    const notification = createNotification(
      'success',
      `Duplicating "${page.content.title || 'Untitled'}"`
    );

    yield put(snackbarModel.actions.addNotificationAction(notification));

    const newPage: Page = yield pagesApi.duplicatePage(id, { folderId });

    yield put(pagesModel.actions.setPages([...pages, newPage]));

    yield put(
      snackbarModel.actions.upsertNotificationAction({
        id: notification.id,
        type: 'success',
        message: `Duplicated "${page.content.title || 'Untitled'}"`,
        isResetTimer: true,
      })
    );

    if (source === 'editor') {
      window.open(
        `https://${APP_HOST}/editor/${newPage.sequenceNumber}`,
        '_blank'
      );
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'The page has not been duplicated')
      )
    );
  }

  if (callback) {
    callback();
  }
}

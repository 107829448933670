import moment from 'moment';

import { Analytics } from '@distribute/shared/types';
import { ChartData, ChartDataItem, EmptyState, Total } from '../model/types';
import { formatMsToTimePassed } from '@distribute/shared/utils';
import { AnalyticsConversionSource } from '../types';

type TabView = {
  id: number;
  name: string;
  value: number;
  duration: number;
};

type TabViewsById = Record<number, TabView>;

type ConversionsCount = Record<string, number>;
type ConversionData = Record<AnalyticsConversionSource, ConversionsCount>;

const DATE_FORMAT = 'YYYY-MM-DD';

const conversionTypes: Record<string, AnalyticsConversionSource> = {
  Popup: 'alertBar',
  'CTA Section': 'cta',
  'Alert Bar': 'popUp',
  'Gated content': 'gatedContent',
  'Squeeze Page': 'squeezePage',
  'Required email': 'requiredEmail',
};

export const round = (value: number, precision = 0): number => {
  const multiplier = Math.pow(10, precision);

  return Math.round(value * multiplier) / multiplier;
};

const convertMsToSecondsForChart = (ms: number): number => {
  return Math.round(moment.duration(ms).asSeconds());
};

const getDatesFromRange = (startDate: Date, endDate = new Date()): string[] => {
  const start = moment(startDate).hour(0).minutes(0).second(0).millisecond(0);
  const end = moment(endDate);
  const diffDays = end.diff(start, 'days');
  const days = [start.format(DATE_FORMAT)];

  for (let i = 1; i <= diffDays; i++) {
    days.push(moment(start).add(i, 'd').format(DATE_FORMAT));
  }

  return days;
};

const calculateConversionRate = (
  conversions: number,
  visitors: number
): number => {
  const value = (conversions * 100) / visitors;

  return Number.isFinite(value) ? value : 0;
};

export const calculateEmptyState = (data: Analytics): EmptyState => {
  const isConversionsEmpty = data.conversions.length === 0;

  const isConversionsRateEmpty = data.conversions.length === 0;
  return {
    pageViews: data.pageViews.length === 0,
    uniqueVisitors: data.uniqueVisitors.length === 0,
    conversions: isConversionsEmpty,
    conversionRate: isConversionsEmpty,
    averageTimeOnPage: data.pageViews.length === 0,
    averageTimeToConvert: isConversionsRateEmpty,
  };
};

export const calculateTotal = (
  data: Analytics,
  emptyState: EmptyState
): Total => {
  const uniqueVisitors = data.uniqueVisitors.length;
  const pageViews = data.pageViews.length;
  const conversions = data.conversions.length;
  const conversionRate = calculateConversionRate(conversions, pageViews);
  const averageTimeOnPage = data.pageViews?.reduce((acc, view) => {
    const duration = view.duration ?? 0;
    return acc + duration;
  }, 0);
  const totalAverageTimeOnPage = averageTimeOnPage / pageViews || 0;
  const totalAverageTimeToConvert =
    data.conversions.reduce((acc, page) => {
      return acc + page.timeToConvert;
    }, 0) / data.conversions.length || 1;

  return {
    uniqueVisitors,
    conversions,
    pageViews,
    conversionRate: emptyState.conversionRate
      ? '-'
      : `${round(conversionRate, 1)}%`,
    averageTimeOnPage: emptyState.averageTimeOnPage
      ? '-'
      : formatMsToTimePassed(totalAverageTimeOnPage),
    averageTimeToConvert: emptyState.averageTimeToConvert
      ? '-'
      : formatMsToTimePassed(totalAverageTimeToConvert),
  };
};

export const calculateChartData = (data: Analytics, from?: Date): ChartData => {
  const firstViewDate = new Date(data.pageViews[0]?.createdAt);
  const dateFrom = from ?? firstViewDate;
  const dates = getDatesFromRange(dateFrom);

  const pageViewsData = data.pageViews.reduce((acc, view) => {
    const dateKey = moment(view.createdAt).format(DATE_FORMAT);
    const value = (acc[dateKey] ?? 0) + 1;
    return { ...acc, [dateKey]: value };
  }, {} as Record<string, number>);

  const uniqueVisitorsData = data.uniqueVisitors.reduce((acc, view) => {
    const dateKey = moment(view.createdAt).format(DATE_FORMAT);
    const value = (acc[dateKey] ?? 0) + 1;
    return { ...acc, [dateKey]: value };
  }, {} as Record<string, number>);

  const averageTimeOnPageEntries = Object.entries(
    data.pageViews.reduce((acc, view) => {
      const dateKey = moment(view.createdAt).format(DATE_FORMAT);
      const value = {
        total: (acc[dateKey]?.total ?? 0) + (view.duration ?? 0),
        count: (acc[dateKey]?.count ?? 0) + 1,
      };
      return { ...acc, [dateKey]: value };
    }, {} as Record<string, { total: number; count: number }>)
  ).map(([key, { total, count }]) => [key, total / count]);
  const averageTimeOnPageData = Object.fromEntries(averageTimeOnPageEntries);

  const tabViewsEntries = Object.entries(
    data.tabViews.reduce((acc, tab) => {
      const dateKey = moment(tab.createdAt).format(DATE_FORMAT);
      const { id, name } = tab.contentItem;
      const items = acc[dateKey] ?? ({} as Record<number, TabViewsById>);
      items[id] = {
        id,
        name,
        value: (items[id]?.value ?? 0) + 1,
        duration: (items[id]?.duration ?? 0) + (tab.duration ?? 0),
      };
      return { ...acc, [dateKey]: items };
    }, {} as Record<string, TabViewsById>)
  ).map(([key, value]) => [key, Object.values(value)]);
  const tabViewsData = Object.fromEntries(tabViewsEntries);

  const conversions = data.conversions.reduce((acc, conversion) => {
    const dateKey = moment(conversion.createdAt).format(DATE_FORMAT);
    const { source } = conversion;
    const type = conversionTypes[source];

    if (!acc[type]) {
      acc[type] = {};
    }

    acc[type][dateKey] = (acc[type][dateKey] ?? 0) + 1;

    return acc;
  }, {} as ConversionData);

  const timeToConvert = data.conversions.reduce((acc, conversion) => {
    const dateKey = moment(conversion.createdAt).format(DATE_FORMAT);
    const { source } = conversion;
    const type = conversionTypes[source];

    if (!acc[type]) {
      acc[type] = {};
    }
    acc[type][dateKey] = (acc[type][dateKey] ?? 0) + conversion.timeToConvert;

    return acc;
  }, {} as ConversionData);

  return dates.map<ChartDataItem>((date) => {
    const pageViewsByDate = pageViewsData[date] ?? 0;
    const pageViewsDivider = Math.max(pageViewsByDate, 1);
    const alertBarCount = conversions.alertBar?.[date];
    const ctaCount = conversions.cta?.[date];
    const popUpCount = conversions.popUp?.[date];
    const gatedContentCount = conversions.gatedContent?.[date];
    const squeezePageCount = conversions.squeezePage?.[date];
    const requiredEmailCount = conversions.requiredEmail?.[date];

    return {
      date,
      pageViews: pageViewsByDate,
      uniqueVisitors: uniqueVisitorsData[date] ?? 0,
      conversions: {
        alertBar: alertBarCount ?? 0,
        cta: ctaCount ?? 0,
        popUp: popUpCount ?? 0,
        gatedContent: gatedContentCount ?? 0,
        squeezePage: squeezePageCount ?? 0,
        requiredEmail: requiredEmailCount ?? 0,
      },
      conversionRate: {
        alertBar: calculateConversionRate(alertBarCount ?? 0, pageViewsDivider),
        cta: calculateConversionRate(ctaCount ?? 0, pageViewsDivider),
        popUp: calculateConversionRate(popUpCount ?? 0, pageViewsDivider),
        gatedContent: calculateConversionRate(
          gatedContentCount ?? 0,
          pageViewsDivider
        ),
        squeezePage: calculateConversionRate(
          squeezePageCount ?? 0,
          pageViewsDivider
        ),
        requiredEmail: calculateConversionRate(
          requiredEmailCount ?? 0,
          pageViewsDivider
        ),
      },
      averageTimeOnPage: convertMsToSecondsForChart(
        averageTimeOnPageData[date] ?? 0
      ),
      averageTimeToConvert: {
        alertBar: convertMsToSecondsForChart(
          (timeToConvert.alertBar?.[date] ?? 0) / (alertBarCount ?? 1)
        ),
        cta: convertMsToSecondsForChart(
          (timeToConvert.cta?.[date] ?? 0) / (ctaCount ?? 1)
        ),
        popUp: convertMsToSecondsForChart(
          (timeToConvert.popUp?.[date] ?? 0) / (popUpCount ?? 1)
        ),
        gatedContent: convertMsToSecondsForChart(
          (timeToConvert.gatedContent?.[date] ?? 0) / (gatedContentCount ?? 1)
        ),
        squeezePage: convertMsToSecondsForChart(
          (timeToConvert.squeezePage?.[date] ?? 0) / (squeezePageCount ?? 1)
        ),
        requiredEmail: convertMsToSecondsForChart(
          (timeToConvert.requiredEmail?.[date] ?? 0) / (requiredEmailCount ?? 1)
        ),
      },
      tabViews: tabViewsData[date] ?? [],
    };
  });
};

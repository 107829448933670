import { createContext, Dispatch } from 'react';
import { PlayerBubbleState } from './reducer';
import { PlayerBubbleSettings } from '../../types';

import { AnyAction } from '@reduxjs/toolkit';

export const PlayerBubbleContext = createContext<{
  state: PlayerBubbleState;
  config: PlayerBubbleSettings;
  dispatch: Dispatch<AnyAction>;
} | null>(null);

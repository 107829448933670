import { FC, PropsWithChildren, useReducer } from 'react';
import { PlayerContext } from './PlayerContext';

import { PlayerEntry, PlayerSettings } from '../../types';
import { initialState, reducer } from './reducer';

export const PlayerProvider: FC<
  PropsWithChildren<PlayerEntry & PlayerSettings>
> = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PlayerContext.Provider value={{ state, dispatch, config: props }}>
      {children}
    </PlayerContext.Provider>
  );
};

import { createAction } from '@reduxjs/toolkit';

export enum NarrationMessage {
  OnIdle = 'narration/onIdle',
  OnInit = 'narration/onInit',
  OnStartUploadingSession = 'narration/onStartUploadingSession',
  OnStartRecording = 'narration/onStartRecording',
  OnResumeRecording = 'narration/onResumeRecording',
  OnPauseRecording = 'narration/onPauseRecording',
  OnStopRecording = 'narration/onStopRecording',
  OnCancelRecording = 'narration/onCancelRecording',
  OnRestartRecording = 'narration/onRestartRecording',
}

//prettier-ignore
export const messageActions = {
  onIdle: createAction<void, NarrationMessage.OnIdle>(NarrationMessage.OnIdle),
  onInit: createAction<{ pageId: string, videoId: string, audioId: string }, NarrationMessage.OnInit>(NarrationMessage.OnInit),
  onStartRecording: createAction<void, NarrationMessage.OnStartRecording>(NarrationMessage.OnStartRecording),
  onStartUploadingSession: createAction<void, NarrationMessage.OnStartUploadingSession>(NarrationMessage.OnStartUploadingSession),
  onRestartRecording: createAction<void, NarrationMessage.OnRestartRecording>(NarrationMessage.OnRestartRecording),
  onResumeRecording: createAction<void, NarrationMessage.OnResumeRecording>(NarrationMessage.OnResumeRecording),
  onPauseRecording: createAction<void, NarrationMessage.OnPauseRecording>(NarrationMessage.OnPauseRecording),
  onStopRecording: createAction<void, NarrationMessage.OnStopRecording>(NarrationMessage.OnStopRecording),
  onCancelRecording: createAction<void, NarrationMessage.OnCancelRecording>(NarrationMessage.OnCancelRecording),
};

export type NarrationMessageAction = ReturnType<
  (typeof messageActions)[keyof typeof messageActions]
>;

import { Icon, Icons } from '../../icons';

export const PlayerError = () => {
  return (
    <div className="bg-base-black flex flex-col md:gap-y-2 gap-y-4 items-center justify-center h-full">
      <Icon
        svg={Icons.Error}
        className="text-base-white w-6.5 h-6.5 flex-shrink-0"
      />
      <span className="text-base-white !text-md font-semibold !m-0 whitespace-nowrap">
        This video is unavailable
      </span>
    </div>
  );
};

import { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  ContactType,
  DeserializedContact,
  removeContact,
  updateContact,
} from '@distribute/shared/generate-html';
import context from './context';
import { IconMap } from '../../../../../shared/sprite';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { Button, Checkbox, Icon, Input } from '../../../../../shared/ui';
import { Draggable } from 'react-beautiful-dnd';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { shape } from './validationSchema';

export const contactConfigTypeConfig: Record<
  ContactType,
  {
    icon: (typeof IconMap)[keyof typeof IconMap];
    title: string;
  }
> = {
  calendar: { icon: IconMap.CalendarPlus01, title: 'Calendar' },
  email: { icon: IconMap.AtSign, title: 'Email' },
  facebook: { icon: IconMap.FacebookLine, title: 'Facebook' },
  linkedin: { icon: IconMap.LinkedInLine, title: 'Linkedin' },
  phone: { icon: IconMap.Phone, title: 'Phone' },
  twitter: { icon: IconMap.XSocial, title: 'X (Twitter)' },
  website: { icon: IconMap.Globe01, title: 'Website' },
};

const ContactInput: FC<DeserializedContact & { isDragDisabled?: boolean }> = ({
  isDragDisabled,
  ...contact
}) => {
  const { form } = useContext(context);
  const [isEditing, setIsEditing] = useState(!contact.value);
  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    register,
    watch,
    setFocus,
  } = useForm<DeserializedContact & { withLabel: boolean }>({
    defaultValues: { ...contact, withLabel: !!contact.label },
    resolver: yupResolver(Yup.object().shape({ value: shape[contact.type] })),
  });

  useEffect(() => {
    if (isEditing) {
      setTimeout(() => {
        setFocus('value');
      }, 0);
    }
  }, [isEditing, setFocus]);

  const onSubmit = handleSubmit((data) => {
    if (data.value) {
      form.setValue(
        'serializedContacts',
        updateContact(form.getValues().serializedContacts, contact.id, data)
      );

      setIsEditing(false);
    }
  });

  const switchEditingState = useCallback(
    () => setIsEditing((prev) => !prev),
    []
  );

  const onRemoveInput = useCallback(() => {
    form.setValue(
      'serializedContacts',
      removeContact(form.getValues().serializedContacts, contact.id)
    );
  }, [form, contact.id]);

  const onCancel = useCallback(() => {
    if (!contact.value) {
      form.setValue(
        'serializedContacts',
        removeContact(form.getValues().serializedContacts, contact.id)
      );
    } else {
      setIsEditing(false);
      reset();
    }
  }, [form, contact.id, reset, contact.value]);

  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      draggableId={contact.id.toString()}
      index={contact.order}
    >
      {(provided) => (
        <form
          className={cn(
            'flex flex-row border border-1 border-gray-300 bg-base-white rounded-lg p-3 gap-x-3',
            {
              'p-5 flex-col gap-y-4': isEditing,
            }
          )}
          onSubmit={onSubmit}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {!isEditing && (
            <Icon
              className={cn('cursor-grab', {
                hidden: isDragDisabled,
              })}
              glyph={IconMap.Drag}
              width={20}
              height={20}
            />
          )}
          <span
            className={cn('flex flex-row flex-nowrap gap-x-2 items-center', {
              'text-lg font-semibold': isEditing,
            })}
          >
            <Icon
              glyph={contactConfigTypeConfig[contact.type].icon}
              width={20}
              height={20}
            />
            <span>{contactConfigTypeConfig[contact.type].title}</span>
          </span>
          {!isEditing && (
            <span className="flex-1 text-right text-gray-500 truncate">
              "{contact.label ?? contact.value}"
            </span>
          )}
          {!isEditing && (
            <Button
              variant="icon"
              size="xs"
              color="transparent-with-actions"
              iconLeftName={IconMap.Edit02}
              iconLeftWidth={20}
              onClick={switchEditingState}
            />
          )}
          {!isEditing && (
            <Button
              variant="icon"
              size="xs"
              color="transparent-with-actions"
              iconLeftName={IconMap.Delete}
              iconLeftWidth={20}
              onClick={onRemoveInput}
            />
          )}
          {isEditing && (
            <Input
              type="text"
              {...register('value')}
              name="value"
              isError={isDirty && !!errors.value?.message}
              messageText={isDirty ? errors.value?.message : undefined}
            />
          )}
          {isEditing && !['email', 'phone'].includes(contact.type) && (
            <div className="flex flex-col gap-y-2">
              <label className="select-none flex flex-row gap-x-2 text-sm">
                <Checkbox
                  {...register('withLabel')}
                  checked={watch('withLabel')}
                />
                Show label instead of link
              </label>
              {watch('withLabel') && (
                <Input type="text" {...register('label')} />
              )}
            </div>
          )}
          {isEditing && (
            <div className="flex flex-row-reverse gap-x-2">
              <Button color="brand" variant="text" type="submit">
                Save
              </Button>
              <Button color="secondary" variant="text" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          )}
        </form>
      )}
    </Draggable>
  );
};

export default ContactInput;

import { FC, useRef } from 'react';

import { PlayerBubbleSettings, PlayerSettings } from '../../types';
import { PlayerProvider } from '../../provider/player';

import { PlayerErrorBoundary } from '../PlayerErrorBoundary';
import { PlayerBubbleLoader } from './PlayerBubbleLoader';
import { PlayerBubbleView } from './PlayerBubbleView';
import { PlayerBubbleProvider } from '../../provider/player-bubble';

type Props = {
  bubble: PlayerBubbleSettings;
};

export const PlayerBubble: FC<PlayerSettings & Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { bubble: bubbleProps, ...playerProps } = props;

  return (
    <PlayerErrorBoundary fallback={<PlayerBubbleLoader />}>
      <PlayerBubbleProvider {...bubbleProps}>
        <PlayerProvider {...playerProps} wrapperRef={ref}>
          <PlayerBubbleView />
        </PlayerProvider>
      </PlayerBubbleProvider>
    </PlayerErrorBoundary>
  );
};

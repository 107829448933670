import { FC } from 'react';

import { usePlayer } from '../../hooks';

import { PlayerBubble } from './PlayerBubble';
import { PlayerBubbleLoader } from './PlayerBubbleLoader';

export const PlayerBubbleView: FC = () => {
  const { state, config } = usePlayer();

  if (config.loading || config.error || state.hasError) {
    return (
      <div className="sm:w-36 sm:h-36 w-56 h-56 relative cursor-auto outline-none">
        <PlayerBubbleLoader />
      </div>
    );
  }

  return <PlayerBubble />;
};

import { FC } from 'react';
import cn from 'classnames';

import {
  formatToHumanSeconds,
  formatToPositiveNumberOrZero,
} from '../../../../shared/lib';

import { subscriptionModel } from '../../../subscription';
import { useSelector } from 'react-redux';

type Props = {
  duration: number;
  step: 'active' | 'inactive';
};

const styles = {
  base: 'text-md font-semibold text-end w-12',
  active: 'text-gray-100',
  inactive: 'text-gray-500',
} as const;

export const NarrationToolbarDuration: FC<Props> = ({ step, duration }) => {
  const { videoNarrationUnlimitedDuration, maxVideoNarrationDuration } =
    useSelector(subscriptionModel.selectors.selectSubscriptionLimits);

  return (
    <p className={cn(styles.base, styles[step])}>
      {formatToHumanSeconds(
        videoNarrationUnlimitedDuration
          ? duration
          : formatToPositiveNumberOrZero(
              maxVideoNarrationDuration * 60 - duration
            )
      )}
    </p>
  );
};

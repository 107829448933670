import React, { useEffect, useMemo, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string, boolean } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Button,
  DebounceSlugInput,
  Icon,
  ImageInput,
  Input,
} from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

import { pagesModel } from '../../pages';
import { pageSettingsModel } from '../model';
import { InputBlock } from './components';

type PageSettingsProps = {
  isOpen: boolean;
  onClose: () => void;
};

type PageSettingsForm = {
  isSlugValid: boolean;

  titleTag: string;
  metaDescription: string;
};

const validationSchema = object().shape({
  isSlugValid: boolean(),
  titleTag: string(),
  metaDescription: string(),
});

export const PageSettings: React.FC<PageSettingsProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const seoConfiguration = useSelector(
    pageSettingsModel.selectors.selectSeoConfigurationWithError
  );
  const isLoading = useSelector(
    pageSettingsModel.selectors.selectUpdateSeoConfigurationIsLoading
  );

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );
  const isUploading = useSelector(
    pageSettingsModel.selectors.selectIsUploadImageLoading
  );
  const uploadProgress = useSelector(
    pageSettingsModel.selectors.selectUploadingImageProgress
  );
  const isRemoving = useSelector(
    pageSettingsModel.selectors.selectIsRemoveImageLoading
  );
  const [slug, setSlug] = useState(currentPage.pageSlug);
  const [validateMessage, setValidateMessage] = useState('');

  const { register, handleSubmit, watch, reset, setValue } =
    useForm<PageSettingsForm>({
      resolver: yupResolver(validationSchema),
      defaultValues: useMemo(
        () => ({
          isSlugValid: true,
          titleTag: seoConfiguration.title,
          metaDescription: seoConfiguration.description,
        }),
        [seoConfiguration.description, seoConfiguration.title]
      ),
    });

  const { titleTag, metaDescription } = watch();
  const titleTagCount = titleTag?.length || 0;
  const metaDescriptionCount = metaDescription?.length || 0;

  const onCloseAndReset = () => {
    onClose();
    reset();
  };

  const [isPageSlugError, setIsPageSlugError] = useState(false);
  const [isFormSubmittedFirstTime, setIsFormSubmittedFirstTime] =
    useState(false);

  const isSlugValid = watch().isSlugValid;

  const handleFormSubmit = (data: PageSettingsForm) => {
    setIsFormSubmittedFirstTime(true);
    if (validateMessage) return;
    setIsPageSlugError(!isSlugValid);

    dispatch(
      pageSettingsModel.actions.updateSeoConfiguration({
        documentContentId: currentPage.content.id,
        pageId: currentPage.id,
        title: data.titleTag,
        description: data.metaDescription,
        pageSlug: slug,
        callback: () => onClose(),
      })
    );
  };

  const onUpdateImage = (image: File) => {
    dispatch(
      pageSettingsModel.actions.changeSeoConfigurationsImage({
        documentContentId: currentPage.content.id,
        pageId: currentPage.id,
        image,
      })
    );
  };

  const onRemoveImage = () => {
    dispatch(
      pageSettingsModel.actions.removeSeoConfigurationsImage({
        documentContentId: currentPage.content.id,
        pageId: currentPage.id,
      })
    );
  };

  useEffect(() => {
    setSlug(currentPage.pageSlug);
  }, [currentPage.pageSlug]);

  useEffect(() => {
    if (isFormSubmittedFirstTime) {
      setIsPageSlugError(!isSlugValid);
    }
  }, [isFormSubmittedFirstTime, isSlugValid]);

  return (
    <Dialog
      className="relative z-50 w-full"
      open={isOpen}
      onClose={onCloseAndReset}
    >
      <div className="fixed inset-0 flex items-center justify-center p-4 bg-bg-200">
        <Dialog.Panel className="sm:w-full w-212 max-h-180 h-full shadow-xl rounded-xl bg-base-white overflow-y-scroll scrollbar-hide relative">
          <div className="flex items-center justify-between p-4 border-b border-gray-200 sticky top-0 z-10 bg-base-white">
            <div className="flex items-center gap-2">
              <Icon glyph={IconMap.ToolbarSettings} height={20} width={17} />
              <p className="font-medium text-md text-900">Page Settings</p>
            </div>
            <Button
              className="outline-none"
              size="sm"
              onClick={onCloseAndReset}
              variant="icon"
              color="secondary"
            >
              <Icon glyph={IconMap.CrossClose} className="text-gray-500" />
            </Button>
          </div>

          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="p-4">
              <div className="pb-6 border-b border-gray-200">
                <p className="text-gray-900 text-s font-medium mb-1.5">Slug</p>
                <DebounceSlugInput
                  validateMessage={validateMessage}
                  setValidateMessage={setValidateMessage}
                  slug={slug}
                  setSlug={setSlug}
                  setIsSlugValid={(val) => setValue('isSlugValid', val)}
                  isLoading={isLoading}
                  currentSlug={currentPage.pageSlug}
                  isError={isPageSlugError}
                  showCopy={!!currentPage.published}
                />
              </div>

              <div className="flex flex-col gap-6 py-6 border-b border-gray-200">
                <InputBlock
                  title="Title Tag"
                  text="Title the page. Optimal title length is approximately 55 characters."
                >
                  <Input
                    {...register('titleTag')}
                    type="text"
                    maxCharacters={55}
                    isCounterShow={false}
                    messageText={`${titleTagCount} characters`}
                    placeholder="Untitled"
                  />
                </InputBlock>

                <InputBlock
                  title="Meta Description"
                  text=" Describe the page. Optimal description length is 155 to 300 characters."
                >
                  <Input
                    {...register('metaDescription')}
                    isTextArea
                    textAreaRows={3}
                    type="text"
                    maxCharacters={300}
                    isCounterShow={false}
                    messageText={`${metaDescriptionCount} characters`}
                  />
                </InputBlock>
              </div>

              <div className="pt-6">
                <InputBlock
                  title="Open Graph Image"
                  text="The info that shows up when sharing content on Facebook, Twitter, LinkedIn, and Pinterest. Make sure your images are at least 1200px by 630px and have a 1.91:1 aspect ratio."
                >
                  <ImageInput
                    imageUrl={seoConfiguration.ogImage}
                    isUploading={isUploading}
                    uploadProgress={uploadProgress}
                    isRemoving={isRemoving}
                    onUpdateImage={onUpdateImage}
                    onRemoveImage={onRemoveImage}
                    isLabel={false}
                  />
                </InputBlock>
              </div>
            </div>
            <div className="bg-base-white flex justify-end items-center gap-2 p-4 border-t border-gray-200 sticky bottom-0 z-10">
              <Button
                size="sm"
                variant="text"
                color="secondary"
                title="Cancel"
                onClick={onCloseAndReset}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="text"
                color="primary"
                title="Save"
                loading={isLoading}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

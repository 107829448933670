import { usePlayerBubble } from './use-player-bubble';
import { actions } from '../provider/player-bubble';

export const usePlayerBubbleActions = () => {
  const { dispatch } = usePlayerBubble();

  const handleMinify = () => dispatch(actions.setMinify(true));

  return {
    handleMinify,
  };
};

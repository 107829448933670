import { RefObject } from 'react';

export type PlayerSettings = {
  url: string;
  posterUrl: string;
  error?: boolean;
  loading?: boolean;
  aspectRatio?: string;
  hotKeys?: boolean;
};

export type PlayerEntry = {
  wrapperRef: RefObject<HTMLDivElement>;
  entry?: ResizeObserverEntry;
};

export type PlayerBubbleSettings = {
  onEditClick?: () => void;
  interactive?: boolean;
};

export const HlsError = 'hlsError';

export enum HlsErrorTypes {
  NETWORK_ERROR = 'networkError',
  MEDIA_ERROR = 'mediaError',
  KEY_SYSTEM_ERROR = 'keySystemError',
  MUX_ERROR = 'muxError',
  OTHER_ERROR = 'otherError',
}

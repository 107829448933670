import { Editor, Extension } from '@tiptap/core';
import { ReactRenderer, findParentNodeClosestToPos } from '@tiptap/react';
import Suggestion, {
  SuggestionProps,
  SuggestionKeyDownProps,
} from '@tiptap/suggestion';
import { PluginKey } from '@tiptap/pm/state';
import tippy from 'tippy.js';

import { MenuList } from './TriggerMenu/MenuList';
import { CommandGroup } from './types';
import { AnyAction, Store } from '@reduxjs/toolkit';
import { RootState } from '../../../app';

const extensionName = 'triggerMenu';

let popup: any;

export type TriggerMenuOptions = {
  store: Store<RootState, AnyAction>;
  trigger: string;
  groups: CommandGroup[];
};

export const TriggerMenu = Extension.create<TriggerMenuOptions>({
  name: extensionName,

  priority: 200,

  addOptions() {
    return {
      store: {} as Store<RootState, AnyAction>,
      trigger: '/',
      groups: [],
    };
  },

  onCreate() {
    popup = tippy('body', {
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
      theme: 'trigger-menu',
      getReferenceClientRect: () => {
        const coords = this.editor.view.coordsAtPos(
          this.editor.view.state.selection.$anchor.pos
        );

        return new DOMRect(coords.left, coords.top, 0, 0);
      },
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            enabled: false,
          },
        ],
      },
    });
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        char: this.options.trigger,
        allowSpaces: true,
        startOfLine: true,
        pluginKey: new PluginKey(extensionName),
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const isRootDepth = $from.depth === 1;
          const isParagraph = $from.parent.type.name === 'paragraph';
          const isStartOfNode =
            $from.parent.textContent?.charAt(0) === this.options.trigger;
          const resolvedPos = state.doc.resolve($from.pos);
          const isInsideColumn = !!findParentNodeClosestToPos(
            resolvedPos,
            (node) => {
              return node.type.name === 'column';
            }
          );

          return (
            (isRootDepth || isInsideColumn) && isParagraph && isStartOfNode
          );
        },
        command: ({ editor, props }: { editor: Editor; props: any }) => {
          const {
            view,
            view: {
              state,
              state: {
                selection: { $head, $from },
              },
              dispatch,
            },
          } = editor;

          const end = $from.pos;
          const from = $head?.nodeBefore
            ? end -
              ($head.nodeBefore.text?.substring(
                $head.nodeBefore.text?.indexOf(this.options.trigger)
              ).length ?? 0)
            : $from.start();

          const tr = state.tr.deleteRange(from, end);
          dispatch(tr);

          props.action(editor, this.options.store);
          view.focus();
        },
        items: ({ query }: { query: string }) => {
          const withFilteredCommands = this.options.groups.map((group) => ({
            ...group,
            items: group.items
              .filter((item) => {
                const labelNormalized = item.name.toLowerCase().trim();
                const queryNormalized = query.toLowerCase().trim();

                if (item.aliases) {
                  const aliases = item.aliases.map((alias) =>
                    alias.toLowerCase().trim()
                  );

                  return (
                    labelNormalized.includes(queryNormalized) ||
                    aliases.includes(queryNormalized)
                  );
                }

                return labelNormalized.includes(queryNormalized);
              })
              .filter((command) => !command.shouldBeHidden),
          }));

          const withoutEmptyGroups = withFilteredCommands.filter((group) => {
            if (group.items.length > 0) {
              return true;
            }

            return false;
          });

          return withoutEmptyGroups;
        },
        render: () => {
          let component: any;

          return {
            onStart: (props: SuggestionProps) => {
              component = new ReactRenderer(MenuList, {
                props,
                editor: props.editor,
              });

              const { view } = props.editor;

              const editorNode = view.dom as HTMLElement;
              const maxWidth = 0;

              const getReferenceClientRect = () => {
                const coords = this.editor.view.coordsAtPos(
                  this.editor.view.state.selection.$anchor.pos
                );

                return new DOMRect(coords.left, coords.top, 0, 0);
              };

              popup[0].setProps({
                getReferenceClientRect,
                maxWidth,
                appendTo: () => props?.editor?.options?.element,
                content: component.element,
              });

              popup[0].show();
            },

            onUpdate(props: SuggestionProps) {
              component.updateProps(props);

              const { view } = props.editor;

              const editorNode = view.dom as HTMLElement;
              const maxWidth = 36 * 16;

              const getReferenceClientRect = () => {
                const coords = view.coordsAtPos(
                  view.state.selection.$anchor.pos
                );

                return new DOMRect(coords.left, coords.top, 0, 0);
              };

              // eslint-disable-next-line no-param-reassign
              props.editor.storage[extensionName].rect = props.clientRect
                ? getReferenceClientRect()
                : {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                  };
              popup[0].setProps({
                getReferenceClientRect,
                maxWidth,
              });
            },

            onKeyDown(props: SuggestionKeyDownProps) {
              if (props.event.key === 'Escape') {
                popup[0].hide();

                return true;
              }

              if (!popup[0].state.isShown) {
                popup[0].show();
              }

              return component.ref?.onKeyDown(props);
            },

            onExit() {
              popup[0].hide();
              if (component) {
                component.destroy();
                component = null;
              }
            },
          };
        },
      }),
    ];
  },

  addStorage() {
    return {
      rect: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
    };
  },
});

export default TriggerMenu;

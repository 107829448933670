import { Dialog } from '@headlessui/react';
import { Button, Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { useDispatch } from 'react-redux';
import { narrationModel } from '../../model';

export const NarrationPermissionModal = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(narrationModel.actions.setModal(null));
  };

  return (
    <Dialog className="relative z-50 w-full" open onClose={handleClose}>
      <div className="fixed inset-0 flex items-center justify-center p-4 bg-bg-200">
        <Dialog.Panel className="w-full max-w-120 p-6 shadow-xl rounded-xl bg-base-white relative flex flex-col overflow-hidden">
          <Dialog.Title className="text-gray-900 text-display-xs font-medium font-display">
            Cam & mic permissions
          </Dialog.Title>
          <div className="flex flex-col my-5 text-sm text-gray-600">
            <p>
              Distribute requires permission to access your camera and
              microphone.
            </p>
            <p className="my-3">
              If you have previously blocked access, follow the steps in the
              list:
            </p>
            <ol className="pl-4.5 list-decimal">
              <li className="mb-1">
                Click{' '}
                <span className="inline-flex align-middle">
                  <Icon
                    glyph={IconMap.Settings02}
                    width={16}
                    className="mb-0.5"
                  />
                </span>{' '}
                on the left side of the address bar.
              </li>
              <li>
                Select ”<span className="font-semibold">Allow</span>“ option for
                a{' '}
                <span className="inline-flex align-middle">
                  <Icon glyph={IconMap.Camera} width={16} className="mb-0.5" />
                </span>{' '}
                <span className="font-semibold">Camera</span> and{' '}
                <span className="inline-flex align-middle">
                  <Icon
                    glyph={IconMap.Microphone1}
                    width={16}
                    className="mb-0.5"
                  />
                </span>{' '}
                <span className="font-semibold">Microphone</span>
              </li>
            </ol>
          </div>
          <div className="flex flex-nowrap gap-x-3">
            <Button
              variant="text"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

import React, { FC } from 'react';
import cn from 'classnames';
import { Pagination } from './Pagination';
import { OVERVIEW_COLUMNS } from '../../config';
import { TableRow } from './TableRow';
import { PageOverview } from '@distribute/shared/types';

type Props = {
  data: PageOverview[];
  currentPage: number;
  totalPages: number;
  handleChangePage: (page: number) => void;
};

export const Table: FC<Props> = ({
  data,
  currentPage,
  totalPages,
  handleChangePage,
}) => {
  if (!data.length) {
    return (
      <div className="flex flex-col items-center justify-center gap-8 w-full h-full">
        <div className="flex flex-col items-center gap-2">
          <h2 className="text-m leading-8 font-semibold text-gray-900">
            No data
          </h2>
          <p className="text-gray-600">We couldn't find any data.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pb-12">
      <div className="flex flex-col border border-solid border-gray-200 rounded-xl overflow-auto">
        <table className="table-auto w-full text-center text-gray-600 border-collapse border-spacing-0">
          <thead className="bg-gray-50">
            <tr className="text-xs">
              {OVERVIEW_COLUMNS.map((column) => (
                <th
                  key={column.name}
                  className={cn(
                    'px-4 py-3 text-left font-medium whitespace-nowrap'
                  )}
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm text-gray-600 font-normal">
            {data.map((item) => (
              <TableRow key={item.id} item={item} />
            ))}
          </tbody>
        </table>
        <Pagination
          isHideIfOnlyOnePage
          className="px-6 py-3.5 border-t border-t-solid border-t-gray-200 "
          currentPage={currentPage}
          lastPage={totalPages}
          maxLength={15}
          setCurrentPage={handleChangePage}
        />
      </div>
    </div>
  );
};

import { RefObject, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { HlsError, HlsErrorTypes } from '../types';

export const useRetry = (playerRef: RefObject<ReactPlayer>) => {
  const [error, setError] = useState('');
  const [online, setOnline] = useState(true);

  const handleError = (error: any, data: any) => {
    if (error !== HlsError) return;

    [HlsErrorTypes.MEDIA_ERROR, HlsErrorTypes.NETWORK_ERROR].includes(
      data?.type
    ) && handleHlsError(data?.type);
  };

  const handleHlsError = (e: string) => {
    setError(e);
    setOnline(false);
  };

  useEffect(() => {
    const timer = setInterval(
      () => setOnline(Boolean(navigator?.onLine)),
      1000
    );
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!online || !error) return;

    const player = playerRef?.current?.getInternalPlayer('hls') as
      | any
      | undefined;

    if (!player) return;

    (() => {
      try {
        if (error === HlsErrorTypes.NETWORK_ERROR) {
          player?.startLoad();
          setError('');
          return;
        }

        if (error === HlsErrorTypes.MEDIA_ERROR) {
          player?.recoverMediaError();
          setError('');
          return;
        }
      } catch (e: unknown) {
        console.error('Unknown error with retry hls: ', (e as Error)?.message);
      }
    })();

    //eslint-disable-next-line
  }, [online, error]);

  return { handleError };
};

import { END, EventChannel, eventChannel } from 'redux-saga';
import { socialApi } from '../../../../../shared/api/axios/social';
import { APP_HOST, PROTOCOL } from '../../../../../shared/config';
import { call, put, take } from 'redux-saga/effects';
import { GetTwitterAuthLinkResponse } from '../../types';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { fetchTwitterAccessToken } from './fetchTwitterAccessToken';
import { socialModel } from '../..';
import { fetchTwitterProfileData } from './fetchTwitterProfileData';
import { openOauthSignupPopup } from '../../../../../utils/openOauthSignupPopup';

const twitterCallbackUrl = `${PROTOCOL}${APP_HOST}/twitter-callback`;

let callbackUrl = '';

export function* connectToTwitter(): any {
  yield put(socialModel.actions.setIsConnectingToTwitter(true));

  const res: GetTwitterAuthLinkResponse = yield call(
    socialApi.getTwitterAuthLink,
    twitterCallbackUrl
  );

  function handleMessage(e: StorageEvent) {
    callbackUrl = e.url;
    window.removeEventListener('storage', handleMessage);
  }

  window.addEventListener('storage', handleMessage);

  const signUpPopup = openOauthSignupPopup(res.url);

  const callbackUrlChannel: EventChannel<string> = yield call(
    checkConnectionModalForAuthCode,
    signUpPopup
  );

  try {
    while (true) {
      const url = yield take(callbackUrlChannel);
      if (url) break;
    }
    callbackUrlChannel.close();
    yield call(onCallbackUrlSet, res.oauth_token_secret);
  } finally {
    callbackUrlChannel.close();
    callbackUrl = '';
    yield put(socialModel.actions.setIsConnectingToTwitter(false));
  }
}

function* onCallbackUrlSet(oauthTokenSecret: string) {
  const urlParams = new URLSearchParams(
    callbackUrl.substring(callbackUrl.indexOf('?'))
  );
  const oauth_token: string = yield urlParams.get('oauth_token');
  const oauth_verifier: string = yield urlParams.get('oauth_verifier');

  if (!oauth_verifier) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to connect to Twitter')
      )
    );
    yield put(socialModel.actions.setIsConnectingToTwitter(false));
    return;
  }

  yield call(fetchTwitterAccessToken, {
    oauth_token,
    oauth_verifier,
    oauth_token_secret: oauthTokenSecret,
  });

  try {
    yield call(fetchTwitterProfileData);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Successfully connected to Twitter')
      )
    );
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to fetch Twitter profile data')
      )
    );
  } finally {
    yield put(socialModel.actions.setIsConnectingToTwitter(false));
  }
}

function checkConnectionModalForAuthCode(signUpPopup: Window | null) {
  return eventChannel((emitter) => {
    const checkSignUpPopupInterval = setInterval(() => {
      if (callbackUrl.length) {
        emitter(callbackUrl);
      } else if (!signUpPopup || signUpPopup?.closed) {
        emitter(END);
      }
    }, 1000);

    return () => {
      clearInterval(checkSignUpPopupInterval);
    };
  });
}

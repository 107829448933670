import { formatDate, formatDateDiff } from '@distribute/shared/utils';

export const generateTimelineDateWrapper = (dueDate: string) => {
  return `
    <span class="timeline-item__due-date">${formatDate(dueDate)}</span>
    <span class="timeline-item__days">${formatDateDiff(
      new Date(dueDate)
    )} </span>
  `;
};

import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { Button, Icon, Tooltip } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import classNames from 'classnames';
import { formatDateShort } from '@distribute/shared/utils';
import { tasksApi } from '../../../../../src/shared/api';

export type CustomTaskListItemNodeType = NodeViewProps['node'];

export type CustomTaskListItemNodeViewProps = NodeViewProps & {
  node: CustomTaskListItemNodeType;
};

export const CustomTaskListItemNodeView = ({
  node,
  getPos,
  editor,
  updateAttributes,
}: CustomTaskListItemNodeViewProps) => {
  const pos = getPos();

  const currentDueDate = node?.attrs.dueDate ?? new Date();
  const dueDate = node.attrs.dueDate ? formatDateShort(node.attrs.dueDate) : '';
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleDueDateChange = useCallback(
    (value: Date | null | (Date | null)[]) => {
      updateAttributes({
        dueDate: value?.toString() ?? '',
      });
    },
    [editor]
  );

  const handleClearDueDate = useCallback(() => {
    updateAttributes({
      dueDate: '',
    });
    setIsCalendarOpen(false);
  }, []);

  const useOutsideAlerter = (ref: RefObject<HTMLElement>) => {
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setIsCalendarOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const calendarRef = useRef(null);

  useOutsideAlerter(calendarRef);

  const setTaskId = async () => {
    const task = await tasksApi.createTask();

    updateAttributes({ id: task.id });
  };

  useEffect(() => {
    if (!node.attrs.id) {
      setTaskId();
    }
  }, []);

  return (
    <NodeViewWrapper className="!border-none" data-done={node.attrs.isDone}>
      <div
        data-type="customTaskListItem"
        className="pl-5 pr-1.25 py-4 mb-4 rounded-lg bg-gray-50 hover:bg-gray-100"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-grow gap-3">
            <label
              className="relative inline-block w-5 h-5"
              contentEditable={false}
            >
              <input
                type="checkbox"
                className="absolute w-full h-full opacity-0 cursor-pointer"
                checked={node.attrs.isDone}
                onChange={(e) => {
                  updateAttributes({
                    isDone: e.target.checked,
                  });
                }}
              />
              <span
                className={classNames(
                  'border border-gray-300 rounded w-5 h-5 flex flex-col justify-center items-center',
                  {
                    'bg-primary-solid border-primary-solid': node.attrs.isDone,
                    'bg-base-white border-gray-300': !node.attrs.isDone,
                  }
                )}
              >
                {node.attrs.isDone && (
                  <Icon
                    className="w-3 h-3 text-base-white"
                    glyph={IconMap.Check}
                  />
                )}
              </span>
            </label>
            <div className="relative flex-grow">
              <NodeViewContent
                className={classNames({
                  'line-through': node.attrs.isDone,
                })}
              />
              {node.firstChild?.textContent?.trim().length === 0 && (
                <div className="absolute top-0 left-0 text-lg font-semibold text-gray-400 ">
                  Untitled
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <Tooltip
              hideArrow
              className="py-2 text-center"
              triggerClassNames="flex"
              trigger={
                <button
                  onClick={() => {
                    updateAttributes({
                      isVisible: !node.attrs.isVisible,
                    });
                  }}
                >
                  <Icon
                    glyph={
                      node.attrs.isVisible ? IconMap.Eye : IconMap.CrossedEye
                    }
                    width={20}
                    className="text-gray-600"
                  />
                </button>
              }
            >
              {node.attrs.isVisible
                ? 'Visible for viewers'
                : 'Invisible for viewers'}
            </Tooltip>

            <div className="relative">
              <Tooltip
                hideArrow
                className="flex flex-col py-2"
                triggerClassNames="flex"
                trigger={
                  <button
                    className="flex flex-col items-end"
                    onClick={() => {
                      setIsCalendarOpen((prevOpen) => !prevOpen);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <Icon
                        glyph={IconMap.Calendar}
                        width={20}
                        className={classNames({
                          'text-gray-500': dueDate,
                          'text-gray-400': !dueDate,
                        })}
                      />
                      <span
                        className={classNames('text-md', {
                          'text-gray-600 font-medium': dueDate,
                          'text-gray-400': !dueDate,
                        })}
                      >
                        {dueDate ? dueDate : 'none'}
                      </span>
                    </div>
                  </button>
                }
              >
                <span>Due date</span>
                {node.attrs.dueDate && <span>{dueDate}</span>}
              </Tooltip>
              {isCalendarOpen && (
                <div
                  ref={calendarRef}
                  className="absolute px-6 pt-5 pb-4 border border-gray-200 z-[10000] -top-4 right-24 w-82 bg-base-white rounded-xl"
                  onClick={() => {
                    return;
                  }}
                >
                  <Calendar
                    allowPartialRange={false}
                    value={currentDueDate}
                    onChange={handleDueDateChange}
                  />
                  <div className="mt-5 shadow-xs">
                    <Button
                      fullWidth
                      variant="text"
                      onClick={handleClearDueDate}
                      color="secondary"
                      disabled={!node.attrs.dueDate}
                    >
                      Clear date
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

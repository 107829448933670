import { FC } from 'react';

import { Icon, Icons } from '../../icons';
import { usePlayer, usePlayerActions, usePlayerBubble } from '../../hooks';

export const PlayerBubbleTooltip: FC = () => {
  const {
    state: stateBubble,
    config: { interactive },
  } = usePlayerBubble();
  const { state } = usePlayer();

  const { handleStart } = usePlayerActions();

  const { isStarted } = state;
  const { isMinimized } = stateBubble;

  if (!isStarted && interactive && !isMinimized)
    return (
      <div className="right-0 translate-x-full bottom-0 pl-2 absolute z-50">
        <button
          onClick={handleStart}
          type="button"
          className="px-3 py-2 text-xs select-none rounded-lg bg-gray-900 text-base-white max-w-45 font-base font-semibold shadow-lg whitespace-nowrap flex flex-nowrap items-center gap-x-1"
        >
          <span>Click to play</span>
          <Icon svg={Icons.PlayTooltip} size="xss" />
        </button>
      </div>
    );

  return null;
};

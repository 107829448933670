import { VideoNarrationDetail } from '@distribute/shared/types';
import { MediaRecorderProxy } from '../lib';

export type NarrationMachineContext = {
  narration: VideoNarrationDetail;

  pageId: string | null;

  videoId: string;
  audioId: string;

  uploadId: string;
  uploadUrl: string;

  mediaRecorderProxy: MediaRecorderProxy | null;

  mediaStream: MediaStream | null;
  supportTracks: MediaStreamTrack[];

  blobs: Blob[];
  buffer: Uint8Array;
  lastBlobIndex: number;
  lastBufferChunk: number;

  isCancelled: boolean;
  isCancelledOnStart: boolean;
  isCancelledOnNetworkError: boolean;
  retry: number;
};

export const NarrationMachineEvent = {
  INIT: 'INIT',
  CREATED: 'CREATED',
  NETWORK_ERROR: 'NETWORK_ERROR',
  START: 'START',
  START_UPLOADING: 'START_UPLOADING',
  RESTART: 'RESTART',
  RESUME: 'RESUME',
  PAUSE: 'PAUSE',
  STOP: 'STOP',
  CANCEL: 'CANCEL',
  BLOB: 'BLOB',
} as const;

//prettier-ignore
export type NarrationMachineActions =
  | { type: (typeof NarrationMachineEvent)['INIT']; data: { pageId: string, videoId: string, audioId: string } }
  | { type: (typeof NarrationMachineEvent)['START'] }
  | { type: (typeof NarrationMachineEvent)['NETWORK_ERROR'] }
  | { type: (typeof NarrationMachineEvent)['START_UPLOADING'] }
  | { type: (typeof NarrationMachineEvent)['RESUME'] }
  | { type: (typeof NarrationMachineEvent)['RESTART'] }
  | { type: (typeof NarrationMachineEvent)['PAUSE'] }
  | { type: (typeof NarrationMachineEvent)['STOP'] }
  | { type: (typeof NarrationMachineEvent)['CANCEL'] }
  | { type: (typeof NarrationMachineEvent)['BLOB']; data: Blob }
  | { type: (typeof NarrationMachineEvent)['CREATED']; data: MediaRecorderProxy };

export const formatToHumanSeconds = (seconds: number) => {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    timeZone: 'Etc/UTC',
    hour12: false,
    minute: '2-digit',
    second: '2-digit',
  });

  return formatter.format(new Date(seconds * 1000));
};

export const formatToSeconds = (ms: number) => Math.floor(ms / 1000);

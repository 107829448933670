import { FC, useCallback, useContext } from 'react';
import { useTiptapEditor } from '../../../../../entities/tiptap-editor';
import { useDispatch } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { Button } from '../../../../../shared/ui';
import context from './context';
import {
  deserializeContacts,
  serializeContacts,
} from '@distribute/shared/generate-html';

const Footer: FC = () => {
  const { form } = useContext(context);
  const { editor } = useTiptapEditor();
  const dispatch = useDispatch();

  const onSave = useCallback(() => {
    const values = form.getValues();

    const contacts = deserializeContacts(values.serializedContacts).filter(
      (contact) => contact.value
    );

    values.serializedContacts = serializeContacts(contacts);

    editor?.chain().focus().updateAttributes('contactCard', values).run();
    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
  }, [dispatch, editor, form]);

  const onClose = useCallback(() => {
    form.reset();
    dispatch(editorSidebarModel.actions.setSidebarOpen(false));
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
  }, [dispatch, form]);

  return (
    <div className="bg-base-white p-4 flex flex-row justify-between">
      <Button
        variant="text"
        color="secondary"
        size="sm"
        title="Close"
        onClick={onClose}
      >
        Close
      </Button>
      <Button
        variant="text"
        color="primary"
        size="sm"
        title="Close"
        onClick={onSave}
      >
        Save
      </Button>
    </div>
  );
};

export default Footer;

import { call, put, select } from 'redux-saga/effects';
import {
  AnalyticsEvents,
  PlanName,
  SubscriptionInterval,
  TeamFiltered,
} from '@distribute/shared/types';
import { actions } from '../reducer';
import { createNotification, snackbarModel } from '../../../snackbar';
import { analytics } from '../../../../entities/analytics';
import { SkipOnboardingStepSource } from '../types';
import { teamsModel } from '../../../teams';
import { createSubscriptionTrial } from './createSubscriptionTrial';

export function* skipTeamOnboardingStep({
  payload: { source },
}: ReturnType<typeof actions.skipTeamOnboardingStep>) {
  try {
    yield put(actions.setIsLoadingSkipTeamOnboardingStep(true));

    const currentTeam: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeamWithError
    );

    yield call(createSubscriptionTrial, {
      teamId: currentTeam.id,
      plan: PlanName.PRO,
      interval: SubscriptionInterval.MONTH,
    });

    if (source === SkipOnboardingStepSource.INVITE_TEAM_MEMBERS) {
      analytics.track(AnalyticsEvents.INVITE_TEAMMATES_STEP_SKIPPED);
    }
  } catch (error: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update team')
      )
    );
  } finally {
    yield put(actions.setIsLoadingSkipTeamOnboardingStep(false));
  }
}

import { Input, LabelToggle } from '../../../../../shared/ui';
import { FC, useCallback, useContext } from 'react';
import context from './context';
import { IconMap } from '../../../../../shared/sprite';

const Description: FC = () => {
  const { form } = useContext(context);

  const onChangeisDescriptionBlockVisible = useCallback(
    (newState: boolean) => {
      form.setValue('isDescriptionBlockVisible', newState);
    },
    [form]
  );

  return (
    <div className="flex flex-col gap-y-4 pb-6 border-b border-b-gray-300">
      <LabelToggle
        checked={form.watch('isDescriptionBlockVisible')}
        icon={IconMap.Document}
        onChange={onChangeisDescriptionBlockVisible}
        text="Description"
        iconClassName="hidden"
        textClassName="!text-base font-semibold"
      />
      {form.watch('isDescriptionBlockVisible') && (
        <Input
          type="text"
          isTextArea={true}
          {...form.register('description')}
        />
      )}
    </div>
  );
};

export default Description;

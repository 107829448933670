import { socialModel } from '../..';
import { socialApi } from '../../../../../shared/api/axios/social';
import { call, put } from 'redux-saga/effects';
import { LINKEDIN_TOKEN_STORAGE_KEY } from '../../../../../shared/constants';
import { environment } from '../../../../../environments/environment';
import { FeatureFlags } from '../../../../feature-flag';
import { checkLinkedinAccessTokenExpiration } from './checkLinkedinAccessTokenExpiration';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';

type GetLinkedinProfileResponse = {
  sub: string;
  name: string;
  picture: string;
};

export function* fetchLinkedinProfileData() {
  const isDistributeTabEnabled =
    environment.featureFlags[FeatureFlags.DISTRIBUTE_TAB];

  if (!isDistributeTabEnabled) return;

  const linkedinToken = localStorage.getItem(LINKEDIN_TOKEN_STORAGE_KEY);

  if (!linkedinToken) return;

  const hasExpired: boolean = yield call(checkLinkedinAccessTokenExpiration);

  if (hasExpired) {
    return;
  }

  try {
    const data: GetLinkedinProfileResponse = yield call(
      socialApi.getLinkedinProfileData,
      linkedinToken
    );

    if (!data) return;

    yield put(socialModel.actions.setLinkedinProfileId(data.sub));
    yield put(socialModel.actions.setLinkedinProfileName(data.name));
    yield put(socialModel.actions.setLinkedinProfilePicture(data.picture));
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to connect to LinkedIn')
      )
    );
  }
}

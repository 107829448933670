import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { IconMap } from '../../../../../src/shared/sprite';
import { Icon } from '../../../../../src/shared/ui';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

export type CustomTaskListNodeType = NodeViewProps['node'];

export type CustomTaskListNodeViewProps = NodeViewProps & {
  node: CustomTaskListNodeType;
};

export const CustomTaskListNodeView = ({
  node,
  getPos,
  editor,
  updateAttributes,
}: CustomTaskListNodeViewProps) => {
  const pos = getPos();

  const ref = useRef<HTMLDivElement>(null);

  const [completedAmount, setCompletedAmount] = useState(0);
  const [itemsAmount, setItemsAmount] = useState(0);

  const countCompleted = () => {
    const items = ref.current?.children[1]?.children[1]?.children[0]?.children;
    const currentItemsAmount = items?.length || 0;
    setItemsAmount(currentItemsAmount);
    let currentCompletedAmount = 0;
    if (items) {
      for (let i = 0; i < currentItemsAmount; i++) {
        const item = items[i]?.children[0];
        if (item) {
          const isItemDone = item.getAttribute('data-done');
          currentCompletedAmount =
            currentCompletedAmount + (isItemDone === 'true' ? 1 : 0);
        }
      }
    }
    setCompletedAmount(currentCompletedAmount);
  };

  const handleCountCompletedByEvent = () => {
    setTimeout(() => {
      countCompleted();
    }, 0);
  };

  useEffect(() => {
    countCompleted();
    document.addEventListener('click', handleCountCompletedByEvent);
    document.addEventListener('drop', handleCountCompletedByEvent);

    return () => {
      document.removeEventListener('click', handleCountCompletedByEvent);
      document.removeEventListener('drop', handleCountCompletedByEvent);
    };
  }, []);

  return (
    <NodeViewWrapper
      className="!border-none py-5"
      data-open={node.attrs.isOpen}
      draggable="true"
    >
      <div data-type="customTaskList" ref={ref}>
        <div
          className={classNames('flex items-end justify-between w-full gap-2', {
            'mb-6': node.attrs.isOpen,
          })}
        >
          <div className="flex flex-grow gap-2">
            <button
              className="rounded-1.25 hover:bg-gray-100 text-gray-900 pointer-events-auto w-6 h-6 flex justify-center items-center mt-1.5"
              onClick={() => {
                updateAttributes({
                  isOpen: !node.attrs.isOpen,
                });
              }}
            >
              <Icon
                glyph={IconMap.ToggleIcon}
                width={20}
                className={classNames('text-gray-700', {
                  'rotate-90': node.attrs.isOpen,
                })}
              />
            </button>
            <input
              placeholder="Task list name"
              className="flex-grow text-3xl font-bold border-none outline-none placeholder:text-gray-400"
              value={node.attrs.title}
              onChange={(e) => {
                updateAttributes({
                  title: e.target.value,
                });
              }}
            />
          </div>
          <span className="text-lg text-gray-500">
            {completedAmount}/{itemsAmount} completed
          </span>
        </div>
        <div
          className={classNames({
            hidden: !node.attrs.isOpen,
            block: node.attrs.isOpen,
          })}
        >
          <div className="w-full h-px mb-6 bg-gray-200" />
          <NodeViewContent />
          <div className="flex justify-between">
            <button
              contentEditable={false}
              className="flex items-center gap-1.5 px-5"
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .insertContentAt(pos + node.nodeSize - 1, {
                    type: 'customTaskListItem',
                    content: [
                      {
                        type: 'paragraph',
                      },
                    ],
                  })

                  .focus(pos + node.nodeSize)
                  .run();
              }}
            >
              <Icon glyph={IconMap.Plus} width={20} className="text-gray-600" />
              <span className="text-sm font-semibold text-gray-600">
                New task
              </span>
            </button>

            <button
              contentEditable={false}
              className="flex items-center gap-1.5"
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .insertContentAt(pos + node.nodeSize, {
                    type: 'customTaskList',
                    content: [
                      {
                        type: 'customTaskListItem',
                        content: [
                          {
                            type: 'paragraph',
                          },
                        ],
                      },
                    ],
                  })
                  .focus(pos + node.nodeSize + 2)
                  .run();
              }}
            >
              <Icon glyph={IconMap.Plus} width={24} className="text-gray-600" />
              <span className="text-sm font-semibold text-gray-600">
                Another task list
              </span>
            </button>
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

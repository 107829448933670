import { logger } from '../../../../../shared/lib';

import { NarrationMachineContext } from './types';

export const onStart = (context: NarrationMachineContext) => {
  context.mediaRecorderProxy?.start();
};

export const onRestart = (context: NarrationMachineContext) => {
  context.mediaRecorderProxy?.reset();
};

export const onResume = (context: NarrationMachineContext) => {
  context.mediaRecorderProxy?.resume();
};

export const onPause = (context: NarrationMachineContext) => {
  context.mediaRecorderProxy?.pause();
};

export const onStop = async (context: NarrationMachineContext) => {
  try {
    context.mediaRecorderProxy?.stop();
    onClear(context);
  } catch (e: unknown) {
    logger.error(e);
  }
};

export const onClear = async (context: NarrationMachineContext) => {
  try {
    context.mediaStream?.getTracks().forEach((track) => track.stop());
    context?.supportTracks?.forEach((track) => track.stop());
  } catch (e: unknown) {
    logger.error(e);
  }
};

import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cn from 'classnames';

import { Icon, Tooltip } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

import { formatToSeconds } from '../../../../shared/lib';

import { NarrationToolbarDuration } from './narration-toolbar-duration';
import { NarrationToolbarButton } from './narration-toolbar-button';

import { calculateElapsedRecordingDuration } from '../../lib';
import { NarrationLogicState } from '../../model/types';

import { narrationModel } from '../../model';
import { checkIsNarrationRecorderActive } from '../../model/selectors';

export const NarrationToolbar: FC = () => {
  const dispatch = useDispatch();

  const { logicState, time } = useSelector(
    narrationModel.selectors.selectNarration
  );

  const isNarrationRecorderActive = useSelector(checkIsNarrationRecorderActive);

  const hasRecorderTime = Object.keys(time).length;

  const [duration, setDuration] = useState(() =>
    hasRecorderTime
      ? formatToSeconds(
          calculateElapsedRecordingDuration(
            time?.start || 0,
            time?.pauseAll || 0
          )
        )
      : 0
  );

  useEffect(() => {
    if (logicState === NarrationLogicState.Paused) {
      setDuration(formatToSeconds(time?.duration || 0));
      return;
    }

    if (!hasRecorderTime) {
      setDuration(0);
      return;
    }

    const timeout = setInterval(() => {
      const duration = formatToSeconds(
        calculateElapsedRecordingDuration(time?.start || 0, time?.pauseAll || 0)
      );

      setDuration(duration);
    }, 1000);

    return () => clearInterval(timeout);
  }, [logicState, time, hasRecorderTime]);

  return (
    <div
      className={cn(
        'py-2 px-3 rounded-xl',
        'flex flex-nowrap items-center gap-x-2',
        'absolute bottom-14 left-[50%] translate-x-[-50%] pointer-events-auto',
        'shadow-sm bg-gray-800'
      )}
    >
      <Tooltip
        hideArrow
        align="center"
        sideOffset={12}
        trigger={
          <NarrationToolbarButton
            disabled={!isNarrationRecorderActive}
            onClick={() => dispatch(narrationModel.actions.cancelRecording())}
          >
            <Icon width={24} height={24} glyph={IconMap.Cancel} />
          </NarrationToolbarButton>
        }
      >
        <p className="px-2 py-1 font-semibold text-xs">Cancel recording</p>
      </Tooltip>
      <Tooltip
        hideArrow
        align="center"
        sideOffset={12}
        trigger={
          <NarrationToolbarButton
            disabled={!isNarrationRecorderActive}
            onClick={() => dispatch(narrationModel.actions.restartRecording())}
          >
            <Icon width={24} height={24} glyph={IconMap.Restart} />
          </NarrationToolbarButton>
        }
      >
        <p className="px-2 py-1 font-semibold text-xs">Restart recording</p>
      </Tooltip>
      {logicState !== NarrationLogicState.Paused && (
        <Tooltip
          hideArrow
          align="center"
          sideOffset={12}
          trigger={
            <NarrationToolbarButton
              disabled={!isNarrationRecorderActive}
              onClick={() => dispatch(narrationModel.actions.pauseRecording())}
            >
              <Icon width={24} height={24} glyph={IconMap.Pause} />
            </NarrationToolbarButton>
          }
        >
          <p className="px-2 py-1 font-semibold text-xs">Pause</p>
        </Tooltip>
      )}
      {logicState === NarrationLogicState.Paused && (
        <Tooltip
          hideArrow
          align="center"
          sideOffset={12}
          trigger={
            <NarrationToolbarButton
              disabled={!isNarrationRecorderActive}
              onClick={() => dispatch(narrationModel.actions.resumeRecording())}
            >
              <Icon width={24} height={24} glyph={IconMap.Play} />
            </NarrationToolbarButton>
          }
        >
          <p className="px-2 py-1 font-semibold text-xs">Resume</p>
        </Tooltip>
      )}
      <div className={'w-px h-6 bg-base-white/[0.08]'} />
      <div className="flex flex-nowrap items-center">
        {isNarrationRecorderActive && (
          <Tooltip
            hideArrow
            align="center"
            sideOffset={12}
            trigger={
              <NarrationToolbarButton
                variant="destructive"
                onClick={() => dispatch(narrationModel.actions.stopRecording())}
              >
                <Icon width={24} height={24} glyph={IconMap.Stop} />
              </NarrationToolbarButton>
            }
          >
            <p className="px-2 py-1 font-semibold text-xs">Finish recording</p>
          </Tooltip>
        )}
        <NarrationToolbarDuration
          duration={duration}
          step={isNarrationRecorderActive ? 'active' : 'inactive'}
        />
      </div>
    </div>
  );
};

import { State } from 'xstate';

import { useMachine } from '@xstate/react';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { play } from '../../../../shared/lib';

import { createNotification, snackbarModel } from '../../../snackbar';

import { narrationMachine } from './machine';
import { narrationModel } from '../../model';

import { MAX_RETRIES } from './config';

import {
  NarrationMachineContext,
  NarrationMachineEvent,
} from './machine/types';

import { messageActions, NarrationMessage } from '../../model/message-action';

import endMp3 from '../../../../shared/audio/end.mp3';

export const NarrationRecorder: FC = () => {
  const dispatch = useDispatch();

  const messageAction = useSelector(
    narrationModel.selectors.selectNarrationMessageAction
  );

  const [machine, send] = useMachine(narrationMachine, {
    actions: {
      onIdle: () =>
        dispatch(
          narrationModel.actions.setMessageAction(messageActions.onIdle())
        ),
      onMediaError: (ctx) =>
        dispatch(
          narrationModel.actions.destroyRecorder({ uploadId: ctx.uploadId })
        ),
      onMediaActive: () => dispatch(narrationModel.actions.activateRecorder()),
      onRecorderStopped: () => {
        play(endMp3);

        dispatch(narrationModel.actions.stopRecording());
      },
      onRecorderRestarted: (ctx) =>
        dispatch(
          narrationModel.actions.cancelUploadSession({
            uploadId: ctx.uploadId,
          })
        ),
      onUploadedSessionReady: (ctx) =>
        dispatch(narrationModel.actions.startRecording(ctx.narration)),
      onCompletedWhenCancelledOnNetworkError: (ctx) => {
        dispatch(
          narrationModel.actions.destroyRecorder({ uploadId: ctx.uploadId })
        );
      },
      onCompletedWhenCancelledOnStart: () =>
        dispatch(narrationModel.actions.destroyRecorder({})),
      onCompletedWhenCancelled: (ctx) =>
        dispatch(
          narrationModel.actions.cancelUploadSession({ uploadId: ctx.uploadId })
        ),
      onCompletedWhenFinishedUploadng: (ctx) =>
        dispatch(
          narrationModel.actions.completeUploadSession({
            uploadId: ctx.uploadId,
          })
        ),
    },
  });

  useEffect(() => {
    if (messageAction.type === NarrationMessage.OnInit) {
      send(NarrationMachineEvent.INIT, { data: messageAction.payload });
      return;
    }
    if (messageAction.type === NarrationMessage.OnStartRecording) {
      send(NarrationMachineEvent.START);
      return;
    }
    if (messageAction.type === NarrationMessage.OnStartUploadingSession) {
      send(NarrationMachineEvent.START_UPLOADING);
      return;
    }
    if (messageAction.type === NarrationMessage.OnRestartRecording) {
      send(NarrationMachineEvent.RESTART);
      return;
    }
    if (messageAction.type === NarrationMessage.OnPauseRecording) {
      send(NarrationMachineEvent.PAUSE);
      return;
    }
    if (messageAction.type === NarrationMessage.OnResumeRecording) {
      send(NarrationMachineEvent.RESUME);
      return;
    }
    if (messageAction.type === NarrationMessage.OnStopRecording) {
      send(NarrationMachineEvent.STOP);
      return;
    }
    if (messageAction.type === NarrationMessage.OnCancelRecording) {
      send(NarrationMachineEvent.CANCEL);
      return;
    }
  }, [send, messageAction]);

  useEffect(() => {
    if (machine.context.retry >= MAX_RETRIES) {
      send({
        type: NarrationMachineEvent.NETWORK_ERROR,
      });
      dispatch(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', 'Failed to upload narration')
        )
      );
    }
  }, [machine.context.retry, send, dispatch]);

  //@ts-expect-error types
  return <DevLogs machine={machine} />;
};

const DevLogs: FC<{
  machine: State<NarrationMachineContext>;
  enable?: boolean;
}> = ({ machine, enable }) => {
  if (enable) {
    //eslint-disable-next-line
    const { blobs, buffer, ...context } = machine.context;
    const { value } = machine;

    console.log(JSON.stringify(value, null, 2));
    console.log(JSON.stringify(context, null, 2));
  }

  return null;
};

const blockContextTags = [
  'p',
  'li',
  'blockquote',
  'div',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'pre',
  'ol',
  'ul',
  'table',
  'thead',
  'tbody',
  'tr',
  'td',
  'th',
];

const NBSP = String.fromCharCode(160);

const getClosestInlineParent = (el: Element) => {
  let { parentElement } = el;

  /* eslint-disable */
  while (true) {
    if (!parentElement?.parentElement) {
      break;
    }
    if (blockContextTags.includes(parentElement.tagName.toLowerCase())) {
      parentElement = null;
      break;
    }

    if (
      blockContextTags.includes(
        parentElement.parentElement.tagName.toLowerCase()
      )
    ) {
      break;
    }

    if (parentElement.nextSibling) {
      break;
    }

    parentElement = parentElement.parentElement;
  }
  return parentElement;
};

export const addBreaks = () => {
  const contentBlock =
    document.querySelector<HTMLElement>('.rich-text-content');
  if (!contentBlock) {
    return;
  }

  contentBlock.querySelectorAll<HTMLElement>('br').forEach((br) => {
    if (br.nextSibling) {
      return;
    }
    const parentElement = getClosestInlineParent(br);

    if (parentElement?.nextSibling) {
      return;
    }

    br.insertAdjacentElement('afterend', document.createElement('br'));
  });

  // Create trailing br in empty text blocks
  contentBlock
    .querySelectorAll<HTMLElement>(blockContextTags.join(', '))
    .forEach((blockTag) => {
      const isEmpty = blockTag.childNodes.length === 0;
      if (isEmpty) {
        blockTag.appendChild(document.createElement('br'));
      }
    });
};

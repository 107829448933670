import { api } from './instance';
import {
  CreateSnippetCategoryRequestType,
  CreateSnippetCategoryResponseType,
  GetSnippetsCategoriesResponseType,
  CreateSnippetRequestType,
  CreateSnippetResponseType,
  GetSnippetsResponseType,
  UpdateSnippetCategoryRequestType,
  UpdateSnippetCategoryResponseType,
  DeleteSnippetResponseType,
  DeleteSnippetCategoryResponseType,
  UpdateSnippetRequestType,
  UpdateSnippetResponseType,
  GetSnippetResponseType,
  ReplaceSnippetRequestType,
  ReorderCategoriesRequestType,
  ReorderCategoriesResponseType,
} from '@distribute/shared/api-types/snippets';

export const snippetsApi = {
  async getSnippetsCategories(teamId: string) {
    const { data } = await api.get<GetSnippetsCategoriesResponseType>(
      `snippets/categories?teamId=${teamId}`
    );
    return data;
  },

  async getPersonalSnippets(ownerId: string, teamId: number) {
    const { data } = await api.get<GetSnippetsResponseType>(
      `snippets/personal?ownerId=${ownerId}&teamId=${teamId}`
    );
    return data;
  },

  async getRecentSnippets() {
    const { data } = await api.get<GetSnippetsResponseType>(`snippets/recent`);
    return data;
  },

  async getDraftSnippets(ownerId: string, teamId: number) {
    const { data } = await api.get<GetSnippetsResponseType>(
      `snippets/draft?ownerId=${ownerId}&teamId=${teamId}`
    );
    return data;
  },

  async createSnippetCategory(data: CreateSnippetCategoryRequestType) {
    const { data: responseData } =
      await api.post<CreateSnippetCategoryResponseType>(
        'snippets/create-category',
        data
      );
    return responseData;
  },

  async createSnippet(data: CreateSnippetRequestType) {
    const { data: responseData } = await api.post<CreateSnippetResponseType>(
      'snippets/create-snippet',
      data
    );
    return responseData;
  },

  async updateCategory(
    categoryId: number,
    data: UpdateSnippetCategoryRequestType
  ) {
    const { data: responseData } =
      await api.patch<UpdateSnippetCategoryResponseType>(
        `snippets/update-category/${categoryId}`,
        data
      );
    return responseData;
  },

  async deleteSnippet(snippetId: string) {
    const { data } = await api.delete<DeleteSnippetResponseType>(
      `snippets/${snippetId}`
    );
    return data;
  },

  async deleteSnippetCategory(categoryId: number, moveCategoryId?: number) {
    const { data } = await api.delete<DeleteSnippetCategoryResponseType>(
      moveCategoryId
        ? `snippets/category/${categoryId}?moveCategoryId=${moveCategoryId}`
        : `snippets/category/${categoryId}`
    );
    return data;
  },

  async updateSnippet(snippetId: string, data: UpdateSnippetRequestType) {
    const { data: responseData } = await api.patch<UpdateSnippetResponseType>(
      `snippets/update-snippet/${snippetId}`,
      data
    );
    return responseData;
  },

  async updateSnippetUsedCount(snippetId: string) {
    const { data } = await api.patch<UpdateSnippetResponseType>(
      `snippets/update-snippet-used-count/${snippetId}`
    );

    return data;
  },

  async getSnippetData(snippetId: string): Promise<GetSnippetResponseType> {
    const { data } = await api.get<GetSnippetResponseType>(
      `snippets/${snippetId}`
    );
    return data;
  },

  async replaceSnippet(snippetId: string, data: ReplaceSnippetRequestType) {
    const { data: responseData } = await api.patch<UpdateSnippetResponseType>(
      `snippets/replace-snippet/${snippetId}`,
      data
    );
    return responseData;
  },

  async reorderCategories(data: ReorderCategoriesRequestType) {
    const { data: responseData } =
      await api.patch<ReorderCategoriesResponseType>(
        `snippets/reorder-categories`,
        { categories: data.categories }
      );
    return responseData;
  },
};

import { useTiptapEditor } from '../../lib';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Editor, JSONContent } from '@tiptap/react';
import { DOMSerializer } from '@tiptap/pm/model';
import {
  FilePreviewComponent,
  FilePreviewNodeType,
} from '../../extensions/FilePreview';
import {
  CalloutNodeType,
  CalloutNodeView,
} from '../../extensions/Callout/Callout.renderer';
import { Node } from '@tiptap/react';
import { createRoot } from 'react-dom/client';
import {
  getHoveringBlockTopOffset,
  DIVIDER_TOP_OFFSET,
  addButtonHoveringBlockPluginKey,
} from '../../extensions/HoveringBlock';
import {
  DropBlockDirection,
  DropColumnDirection,
  DropColumnPosition,
} from '../../extensions/DropCursor';
import { buildColumn, buildParagraph } from '../../extensions/MultipleColumns';
import { TodoListItemNodeType, TodoListView } from '../../extensions/TodoList';
import { Fragment, Node as ProsemirrorNode } from 'prosemirror-model';

type FragmentWithContent = Fragment & {
  content: ProsemirrorNode[];
};

type ColumnNodeHierarchyLevelType = 'root' | 'nested' | false;

const DRAGGED_NODE_ID = 'draggedNodeId';
const WRAPPER_OFFSET_Y = 3;
const WRAPPER_OFFSET_X = 44;
const EDITOR_LEFT_PADDING = 80;
const MAX_COLUMNS_NUMBER = 4;

const calculateButtonClickPositionShift = (
  e: MouseEvent,
  dragButtonRef: RefObject<HTMLButtonElement>
) => {
  const box = dragButtonRef.current?.getBoundingClientRect();

  return {
    Y: e.clientY - (box?.top ?? 0),
    X: e.clientX - (box?.left ?? 0),
  };
};

type Props = {
  dragButtonRef: React.RefObject<HTMLButtonElement>;
};

export const useEditorDragNDrop = ({ dragButtonRef }: Props) => {
  const { editor } = useTiptapEditor();

  const draggedNodePosition = useRef<number | null>(null);

  const [isInDragState, setDragState] = useState(false);

  const draggedNodeIndex = useRef<number>(0);
  const dragPosition = useRef<number>(0);
  const draggedNodeTopOffset = useRef<number>(0);
  const hoveringButtons = document.getElementById('hoveringButtons');

  const buttonClickPositionShift = useRef<{ X: number; Y: number }>({
    X: 0,
    Y: 0,
  });

  const genNodesStartPositions = useCallback(() => {
    const nodeStartPositions: number[] = [];
    editor?.view.state.doc.forEach((_, offset) => {
      nodeStartPositions.push(offset);
    });
    return nodeStartPositions;
  }, [editor?.view.state.doc]);

  const calculateDraggedNodeHeightOffsetBasedOnNodeLineHeight = (
    domNode: HTMLElement,
    isDivider: boolean
  ) => {
    if (isDivider) {
      draggedNodeTopOffset.current = DIVIDER_TOP_OFFSET;
      return;
    }
    let nodeLineHeight;
    try {
      nodeLineHeight = Number.parseInt(
        document.defaultView
          ?.getComputedStyle(domNode, null)
          .getPropertyValue('line-height') ?? ''
      );
      // eslint-disable-next-line no-empty
    } catch {}
    const topOffset = getHoveringBlockTopOffset(nodeLineHeight);

    draggedNodeTopOffset.current = topOffset;
  };

  const getDraggedNodeWrapper = (editor: Editor, e: MouseEvent) => {
    const wrapper = document.createElement('div');

    wrapper.classList.add('ProseMirror', 'main-editor', 'bg-transparent');

    wrapper.id = DRAGGED_NODE_ID;
    wrapper.style.width = `${
      editor?.view.dom.clientWidth - EDITOR_LEFT_PADDING
    }px`;
    wrapper.style.paddingTop = '0';
    wrapper.style.position = 'absolute';
    wrapper.style.top = `${
      e.clientY -
      buttonClickPositionShift.current.Y +
      WRAPPER_OFFSET_Y -
      draggedNodeTopOffset.current
    }px`;
    wrapper.style.left = `${
      e.clientX - buttonClickPositionShift.current.X + WRAPPER_OFFSET_X
    }px`;
    return wrapper;
  };

  const getDraggedElement = useCallback((e: MouseEvent, editor: Editor) => {
    const hoveringBlockPluginState = addButtonHoveringBlockPluginKey.getState(
      editor.state
    );

    if (
      !hoveringBlockPluginState?.hoveredBlockNode ||
      hoveringBlockPluginState.hoveredBlockPosition === null
    )
      return;

    const { hoveredBlockNode: node, hoveredBlockPosition } =
      hoveringBlockPluginState;
    draggedNodePosition.current = hoveredBlockPosition;
    if (!node) return;

    const wrapper = getDraggedNodeWrapper(editor, e);

    switch (node.type.name) {
      case 'listItem': {
        const resolvedPos = editor.state.doc.resolve(hoveredBlockPosition);
        const serializer = DOMSerializer.fromSchema(editor.schema);
        const dom = serializer.serializeNode(node);
        const parent = resolvedPos.parent;

        if (resolvedPos.parent.type.name === 'orderedList') {
          let olStart = 1;

          parent.content.forEach((i, _, idx) => {
            if (i === node) {
              olStart = idx + 1;
            }
          });

          const ol = document.createElement('ol');
          ol.start = olStart;
          ol.appendChild(dom);
          wrapper.appendChild(ol);
        } else if (resolvedPos.parent.type.name === 'bulletList') {
          const ul = document.createElement('ul');
          ul.appendChild(dom);
          wrapper.appendChild(ul);
        }
        break;
      }
      case 'todoListItem': {
        const root = createRoot(wrapper);
        const serializer = DOMSerializer.fromSchema(editor.schema);
        const dom = serializer.serializeNode(node);
        const domContent = (dom as HTMLElement).querySelector(
          'div'
        ) as HTMLElement;

        root.render(
          <TodoListView
            node={node as unknown as TodoListItemNodeType}
            getPos={() => 0}
            editor={editor}
            decorations={[]}
            selected={false}
            extension={node as unknown as Node}
            updateAttributes={() => null}
            deleteNode={() => null}
            htmlContent={(domContent as HTMLElement).innerHTML}
          />
        );
        break;
      }
      case 'filePreview': {
        const root = createRoot(wrapper);
        root.render(
          <FilePreviewComponent
            selected={false}
            node={node as unknown as FilePreviewNodeType}
            className="bg-base-white"
          />
        );
        break;
      }
      case 'callout': {
        const root = createRoot(wrapper);
        const serializer = DOMSerializer.fromSchema(editor.schema);
        const dom = serializer.serializeNode(node);

        root.render(
          <CalloutNodeView
            node={node as unknown as CalloutNodeType}
            getPos={() => 0}
            editor={editor}
            decorations={[]}
            selected={false}
            extension={node as unknown as Node}
            updateAttributes={() => null}
            deleteNode={() => null}
            htmlContent={(dom as HTMLElement).innerHTML}
          />
        );
        break;
      }
      case 'customTaskList':
      case 'toggleList':
      case 'table':
      case 'toggleListItemContent':
      case 'snippet':
      case 'resizeableFigure': {
        const dom = editor.view.nodeDOM(hoveredBlockPosition);

        if (dom) {
          (dom as HTMLElement).style.margin = '0';
          (dom as HTMLElement).style.backgroundColor = 'transparent';

          wrapper.appendChild(dom.cloneNode(true));
        }
        break;
      }
      default: {
        const serializer = DOMSerializer.fromSchema(editor.schema);
        const dom = serializer.serializeNode(node);

        (dom as HTMLElement).style.margin = '0';
        (dom as HTMLElement).style.backgroundColor = 'transparent';
        (dom as HTMLElement).style.opacity = '0.5';

        wrapper.appendChild(dom);
        break;
      }
    }

    return wrapper;
  }, []);

  const handleDragMove = useCallback((e: MouseEvent) => {
    e.preventDefault();
    const draggedElement = document.getElementById(DRAGGED_NODE_ID);
    if (!draggedElement) return;
    if (!e.clientY && !e.clientX) return;

    draggedElement.style.top = `${
      e.pageY -
      buttonClickPositionShift.current.Y -
      draggedNodeTopOffset.current +
      WRAPPER_OFFSET_Y
    }px`;
    draggedElement.style.left = `${
      e.pageX - buttonClickPositionShift.current.X + WRAPPER_OFFSET_X
    }px`;
  }, []);

  const cleanup = useCallback(() => {
    setDragState(false);
    const draggedElement = document.getElementById(DRAGGED_NODE_ID);
    if (!draggedElement) return;
    document.body.removeChild(draggedElement);
    document.body.removeEventListener('dragover', handleDragMove);

    if (hoveringButtons) {
      hoveringButtons.style.opacity = '1';
    }
  }, [handleDragMove, hoveringButtons]);

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();

      if (!editor) return;
      const {
        dropColumnTarget,
        dropBlockTarget,
        dropColumnDirection,
        dropBlockDirection,
        dropColumnPosition,
      } = editor.storage.dropCursor as {
        dropColumnTarget: HTMLElement | null;
        dropBlockTarget: HTMLElement | null;
        dropColumnDirection: DropColumnDirection | null;
        dropBlockDirection: DropBlockDirection | null;
        dropColumnPosition: DropColumnPosition;
      };
      if (draggedNodePosition.current === null) {
        return;
      }
      const node = editor.state.doc.nodeAt(draggedNodePosition.current || 0);
      const nodeResolvedPos = editor.state.doc.resolve(
        draggedNodePosition.current || 0
      );
      const nodeParent = nodeResolvedPos.parent;

      if (!node) {
        return;
      }

      let content: JSONContent | JSONContent[];
      if (
        node.type.name === 'toggleListItemContent' ||
        node.type.name === 'column'
      ) {
        content = node.content.toJSON();
      } else {
        content = node.toJSON();
      }

      if (dropBlockTarget) {
        if (
          node.type.name === 'customTaskListItem' &&
          !dropBlockTarget.classList.contains('node-customTaskListItem')
        ) {
          return;
        }

        const shouldBeOnTop = dropBlockDirection === 'top';

        const nodeStartPosition = editor.view.posAtDOM(dropBlockTarget, 0);
        const domNode = editor.state.doc.nodeAt(
          nodeStartPosition - (shouldBeOnTop ? 1 : 0)
        );
        const nodeEndPosition = nodeStartPosition + (domNode?.nodeSize || 0);
        const nodeInsertingPosition = shouldBeOnTop
          ? nodeStartPosition - 1
          : nodeEndPosition;

        let position = nodeInsertingPosition;

        const resolvedPos = editor.state.doc.resolve(position);
        const parent = resolvedPos.parent;
        if (node.type.name === 'listItem' && parent.type.name === 'listItem') {
          position -= 1;
        }

        if (node.type.name === 'listItem' && parent.type.name !== 'listItem') {
          if (nodeParent.type.name === 'orderedList') {
            content = {
              type: 'orderedList',
              content: Array.isArray(content) ? content : [content],
            };
          }
        }

        const rangeToDelete = {
          from: draggedNodePosition.current,
          to: draggedNodePosition.current + node.nodeSize,
        };
        if (node.type.name === 'todoListItem') {
          rangeToDelete.from -= 1;

          // Prevent inserting todoListItem inside another todoListItem
          editor.state.doc.nodesBetween(position, position, (n, p) => {
            if (n.type.name === 'todoListItem') {
              position = p;
            }
          });
        }

        if (position < draggedNodePosition.current) {
          editor
            .chain()
            .focus()
            .deleteRange(rangeToDelete)

            .insertContentAt(position, content, { updateSelection: true })
            .run();
        } else {
          editor
            .chain()
            .focus()

            .insertContentAt(position, content, { updateSelection: true })
            .deleteRange(rangeToDelete)
            .run();
        }
      } else if (dropColumnTarget) {
        const shouldBeLeft = dropColumnDirection === 'left';
        const nodeStartPosition = editor.view.posAtDOM(dropColumnTarget, 0);
        const nodeEndPosition = editor.view.posAtDOM(dropColumnTarget, 1);
        const domNode = editor.state.doc.nodeAt(nodeStartPosition - 1);

        if (!domNode || node === domNode || dropColumnPosition === null) {
          document.body.removeEventListener('drop', handleDrop);
          cleanup();
          return;
        }

        const alreadyColumns = domNode?.type.name === 'columnBlock';

        const json = domNode?.toJSON();

        const isMaximumColumnsNumberReached =
          [...json.content].length >= MAX_COLUMNS_NUMBER;

        let jsonContent = {} as JSONContent;
        if (json.type === 'text') {
          jsonContent = buildParagraph({ content: [json] });
        } else {
          jsonContent = json;
        }

        if (!Array.isArray(content)) {
          if (content.type === 'listItem') {
            content =
              nodeParent.type.name === 'orderedList'
                ? {
                    type: 'orderedList',
                    content: [content],
                  }
                : {
                    type: 'bulletList',
                    content: [content],
                  };
          }
          if (content.type === 'todoListItem') {
            content = {
              type: 'todoList',
              content: [content],
            };
          }
        }

        const prevColumn = buildColumn({
          content: [jsonContent],
        });
        const newColumn = buildColumn({
          content: Array.isArray(content) ? content : [content],
        });

        let columnsToInsert: JSONContent[];

        if (alreadyColumns) {
          if (isMaximumColumnsNumberReached) {
            columnsToInsert = [...json.content];
          } else {
            if (dropColumnPosition !== null) {
              columnsToInsert = [
                ...[...json.content].slice(0, dropColumnPosition),
                newColumn,
                ...[...json.content].slice(dropColumnPosition),
              ];
            } else {
              columnsToInsert = shouldBeLeft
                ? [newColumn, ...json.content]
                : [...json.content, newColumn];
            }
          }
        } else {
          columnsToInsert = shouldBeLeft
            ? [newColumn, prevColumn]
            : [prevColumn, newColumn];
        }

        let deleteRange;

        if (alreadyColumns && isMaximumColumnsNumberReached) {
          deleteRange = {
            from: -1,
            to: -1,
          };
        } else {
          deleteRange = {
            from: draggedNodePosition.current,
            to: draggedNodePosition.current + node.nodeSize,
          };
        }

        const newSelection = alreadyColumns
          ? {
              from: nodeStartPosition,
              to: nodeStartPosition,
            }
          : {
              from: nodeStartPosition,
              to: nodeEndPosition,
            };

        const nodeHierarchyLevelType = getNodeHierarchyLevelType(
          domNode?.content as FragmentWithContent,
          node
        );

        if (
          nodeHierarchyLevelType === 'nested' &&
          !isMaximumColumnsNumberReached
        ) {
          const columnsJSONWithoutNestedNode =
            getColumnsJSONWithoutNestedColumn(domNode, node);

          const newReorderedColumns = [
            ...[...columnsJSONWithoutNestedNode.content].slice(
              0,
              dropColumnPosition
            ),
            newColumn,
            ...[...columnsJSONWithoutNestedNode.content].slice(
              dropColumnPosition
            ),
          ];

          editor
            .chain()
            .focus()
            .setTextSelection(newSelection)
            .unsetColumns(false)
            .setColumns(newReorderedColumns)
            .run();
        } else if (nodeHierarchyLevelType === 'root') {
          const previousColumnsOriginalObjectsFragment =
            domNode.content as FragmentWithContent;

          const draggedNodeArrayIndex =
            previousColumnsOriginalObjectsFragment.content.findIndex(
              (arrayNode) => arrayNode === node
            );

          const newReorderedColumns = immutableArrayReorder(
            json.content,
            draggedNodeArrayIndex,
            dropColumnPosition > draggedNodeArrayIndex
              ? dropColumnPosition - 1
              : dropColumnPosition
          ) as JSONContent[];

          if (
            dropColumnPosition !== draggedNodeArrayIndex &&
            draggedNodeArrayIndex !== dropColumnPosition - 1
          ) {
            editor
              .chain()
              .focus()
              .setTextSelection(newSelection)
              .unsetColumns(false)
              .setColumns(newReorderedColumns)
              .run();
          }
        } else if (isMaximumColumnsNumberReached) {
          document.body.removeEventListener('drop', handleDrop);
          cleanup();
          return;
        } else if (alreadyColumns) {
          if (nodeStartPosition < draggedNodePosition.current) {
            editor
              .chain()
              .focus()
              .deleteRange(deleteRange)
              .setTextSelection(newSelection)
              .unsetColumns(false)
              .setColumns(columnsToInsert)
              .run();
          } else {
            editor
              .chain()
              .focus()
              .setTextSelection(newSelection)
              .unsetColumns(false)
              .setColumns(columnsToInsert)
              .deleteRange(deleteRange)
              .run();
          }
        } else {
          if (nodeStartPosition < draggedNodePosition.current) {
            editor
              .chain()
              .focus()
              .deleteRange(deleteRange)
              .setTextSelection({
                from: nodeStartPosition,
                to: nodeStartPosition,
              })
              .setColumns(columnsToInsert)
              .run();
          } else {
            editor
              .chain()
              .focus()
              .setTextSelection(newSelection)
              .setColumns(columnsToInsert)
              .deleteRange(deleteRange)
              .run();
          }
        }
      }

      document.body.removeEventListener('drop', handleDrop);
      cleanup();
    },
    [cleanup, editor]
  );

  const handleDragStart = useCallback(
    (e: DragEvent) => {
      setDragState(true);
      if (e.dataTransfer) {
        e.dataTransfer.effectAllowed = 'move';
      }

      buttonClickPositionShift.current = calculateButtonClickPositionShift(
        e,
        dragButtonRef
      );

      const draggedLinePosition = editor?.view.posAtCoords({
        left: e.clientX + 80,
        top: e.clientY,
      });

      if (draggedLinePosition?.pos === undefined || !editor) return;

      const domNode = editor.view.domAtPos(
        draggedLinePosition.inside < 0
          ? draggedLinePosition.pos + 1
          : draggedLinePosition.inside + 1
      );

      const node = editor.state.doc.nodeAt(draggedLinePosition.pos);

      calculateDraggedNodeHeightOffsetBasedOnNodeLineHeight(
        domNode.node as HTMLElement,
        !!(node?.type.name === 'horizontalRule')
      );

      const draggedElement = getDraggedElement(e, editor);

      if (!draggedElement) return;

      const nodeRect = (domNode.node as HTMLElement).getBoundingClientRect();

      draggedElement.style.width = `${nodeRect.width}px`;

      document.body.appendChild(draggedElement);
      document.body.addEventListener('dragover', handleDragMove);

      const nodesStartPositions = genNodesStartPositions();

      const index = nodesStartPositions.findIndex(
        (el: number) => el > draggedLinePosition.pos
      );
      const draggedElementIndex =
        index !== -1 ? index - 1 : nodesStartPositions.length - 1;

      draggedNodeIndex.current = draggedElementIndex;
      dragPosition.current = draggedLinePosition.pos;

      document.body.addEventListener('drop', handleDrop);

      if (hoveringButtons) {
        hoveringButtons.style.opacity = '0';
      }
    },
    [
      dragButtonRef,
      editor,
      genNodesStartPositions,
      getDraggedElement,
      handleDragMove,
      handleDrop,
      hoveringButtons,
    ]
  );

  const handleDragEnd = useCallback(() => {
    cleanup();
  }, [cleanup]);

  useEffect(() => {
    const element = dragButtonRef.current;
    element?.addEventListener(
      'dragstart',
      handleDragStart as unknown as (e: Event) => void
    );
    element?.addEventListener('dragend', handleDragEnd);

    return () => {
      element?.removeEventListener(
        'dragstart',
        handleDragStart as unknown as (e: Event) => void
      );
      element?.removeEventListener('dragend', handleDragEnd);
    };
  }, [dragButtonRef, handleDragEnd, handleDragStart]);

  useEffect(() => {
    const element = dragButtonRef.current;
    if (isInDragState) {
      element?.classList.add('!bg-transparent');
    } else {
      element?.classList.remove('!bg-transparent');
    }
  }, [dragButtonRef, isInDragState]);

  useEffect(() => {
    const editorElement = document.querySelector('.main-editor') as HTMLElement;

    if (!editorElement) return;

    if (isInDragState) {
      editorElement.classList.add('dragging');
    } else {
      editorElement.classList.remove('dragging');
    }
  }, [isInDragState]);
};

function immutableArrayReorder<T>(arr: T[], from: number, to: number) {
  return arr.reduce((prev: T[], current, idx, self) => {
    if (from === to) {
      prev.push(current);
    }
    if (idx === from) {
      return prev;
    }
    if (from < to) {
      prev.push(current);
    }
    if (idx === to) {
      prev.push(self[from]);
    }
    if (from > to) {
      prev.push(current);
    }
    return prev;
  }, []);
}

function getNodeHierarchyLevelType(
  columnsContent: FragmentWithContent,
  node: ProsemirrorNode
): ColumnNodeHierarchyLevelType {
  const isNodeExistsOnTheTopLevel = columnsContent.content.includes(node);

  if (isNodeExistsOnTheTopLevel) {
    return 'root';
  }

  const isNodeExistsInTheNestedLevel = !!columnsContent.content.find(
    (parentNode) => {
      return (parentNode.content as FragmentWithContent).content.includes(node);
    }
  );

  return isNodeExistsInTheNestedLevel ? 'nested' : false;
}

function getNestedColumnNodeParentIndex(
  columnsContent: FragmentWithContent,
  node: ProsemirrorNode
): number {
  return columnsContent.content.findIndex((parentNode) =>
    (parentNode.content as FragmentWithContent).content.includes(node)
  );
}

function getColumnsJSONWithoutNestedColumn(
  domNode: ProsemirrorNode,
  node: ProsemirrorNode
) {
  const nestedColumnNodeParentIndex = getNestedColumnNodeParentIndex(
    domNode.content as FragmentWithContent,
    node
  );

  const nestedColumnNodeIndexInsideParent = (
    (domNode.content as FragmentWithContent).content[
      nestedColumnNodeParentIndex
    ].content as FragmentWithContent
  ).content.findIndex((arrayNode) => arrayNode === node);

  const domJSON = domNode.toJSON();

  domJSON.content[nestedColumnNodeParentIndex].content.splice(
    nestedColumnNodeIndexInsideParent,
    1
  );

  return domJSON;
}

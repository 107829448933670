import { select, call, put, all, fork } from 'redux-saga/effects';
import { Folder, Page } from '@distribute/shared/types';
import { matchParams } from '../../../shared/lib';
import { pagesModel } from '../../../features/pages';
import { authUserModel, getUserFromFb } from '../../../entities/auth-user';
import { FirebaseUser } from '../../../shared/types';
import { foldersModel } from '../../../features/folders';
import { setPageSettingsData } from '../../../features/page-settings';
import {
  setAlertBarData,
  setCTAData,
  setPopUpData,
  setGatedContentData,
  setSqueezePageData,
  setRequireEmailToViewData,
} from '../../../features/conversion-kit';
import { narrationModel, setNarrationData } from '../../../features/narration';

import { editorPageWorker } from './editorPageWorker';
import {
  JoinPageResponse,
  websocketClient,
} from '../../../entities/websocket-client';
import { getQueryParam } from '../../../shared/lib';
import { QUERY_PARAM_CURRENT_OPENED_TAB } from '../../../features/pages';

export function* editorPageSaga(location: Location, route: string) {
  yield fork(editorPageWorker);
  yield fork(narrationModel.sagas.narrationWorker);

  const user: FirebaseUser = yield getUserFromFb();
  const token: string = yield user.getIdToken();
  const { pathname } = location;
  const params = matchParams(pathname, route);
  const pages: Page[] = yield select(pagesModel.selectors.selectPages);
  const page = pages.find((i) => params?.sequenceNumber === i.sequenceNumber);
  if (!page) {
    yield call(
      pagesModel.sagas.redirectToPublicPage,
      params?.sequenceNumber ?? ''
    );
    return;
  }
  yield put(pagesModel.actions.setCurrentPage(page));

  const openedContentItemId = getQueryParam(QUERY_PARAM_CURRENT_OPENED_TAB);
  let currentContent = page.content.contentItems[0];
  if (openedContentItemId) {
    currentContent =
      page.content.contentItems.find(
        ({ id }) => id === Number(openedContentItemId)
      ) ?? page.content.contentItems[0];
  }

  yield put(
    pagesModel.actions.setCurrentContentItem({ contentItem: currentContent })
  );

  yield put(pagesModel.actions.setPageEditorDataIsLoading(true));
  yield all([
    call(setPageSettingsData, page.content.id),
    call(setAlertBarData, page.content.id),
    call(setPopUpData, page.content.id),
    call(setCTAData, page.content.id),
    call(setGatedContentData, page.content.id),
    call(setSqueezePageData, page.content.id),
    call(setRequireEmailToViewData, page.content.id),
    call(setNarrationData, page.id),
  ]);
  yield put(pagesModel.actions.setPageEditorDataIsLoading(false));

  const folders: Folder[] = yield select(foldersModel.selectors.selectFolders);
  const currentFolder = folders.find((i) => page?.folderId === i.id);
  yield put(foldersModel.actions.setCurrentFolder(currentFolder));
  yield put(authUserModel.actions.setAuthToken(token));

  const joinPageResponse: JoinPageResponse = yield websocketClient.joinPage(
    page.id
  );
  yield put(pagesModel.actions.setEditorBlocked(!joinPageResponse.canEdit));
  if (!joinPageResponse.canEdit) {
    yield put(
      pagesModel.actions.setExistingEditorData({
        userEmail: joinPageResponse.userEmail,
        userName: joinPageResponse.userName,
      })
    );
  }
}

import { FC } from 'react';

export const PlayerBubbleLoader: FC = () => {
  return (
    <div className="flex items-center flex-nowrap justify-center gap-x-1.5 outline-none w-full h-full bg-gray-900 rounded-xl">
      {[1, 2, 3].map((i) => (
        <span
          key={i}
          className={`w-2 h-2 bg-base-white rounded-full loading-transform-dot loading-dot--${i}`}
        />
      ))}
    </div>
  );
};

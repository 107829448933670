import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { pagesModel, usePagePermissions } from '../../../features/pages';
import { EditorWrapper, PreviewPage, TiptapEditorPage } from './components';
import { useWindowWidth } from '@distribute/frontend/utils';
import { Page, TemplateCreationPhase } from '@distribute/shared/types';
import { websocketClient } from '../../../entities/websocket-client';
import { useSetDocumentContentStyles } from '../../../shared/hooks/useSetDocumentContentStyles';
import debounce from 'debounce';
import { TiptapEditorProvider } from '../../../entities/tiptap-editor';
import { templatesModel } from '../../../features/templates';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

import { DropHandleExtension } from '../../../entities/tiptap-editor/extensions/DropHandle/DropHandleExtension';

import { useUserAgentDevices } from '../../../shared/hooks/useUserAgentDevices';
import { JSONContent } from '@tiptap/react';
import { mainEditorExtensions } from '../../../entities/tiptap-editor/mainEditorExtensions';
import { AIExtension } from '../../../entities/tiptap-editor/extensions/AI';
import TriggerMenu from '../../../entities/tiptap-editor/extensions/TriggerMenuExtension';
import { SLASH_MENU_GROUPS } from '../lib/commands';
import { EditorPreviewModeEnum } from '../../../features/pages/model/types';
import { getSlashMenuWithoutSnippet } from '../../snippets/ui/snippet-editor/lib/helpers';
import { useSubscriptionLimits } from '../../../features/subscription/hooks';
import type { DocumentContentItem } from '@distribute/shared/types';
import { JSONContentFactory } from '@distribute/shared/utils';
import { narrationModel } from '../../../features/narration';

type Props = {
  isTemplateMode: boolean;
};

export const Editor: React.FC<Props> = ({ isTemplateMode }) => {
  const { isMobile } = useWindowWidth();
  const { isTablet } = useUserAgentDevices();
  const isEditorPreview = useSelector(
    pagesModel.selectors.selectIsEditorPreview
  );
  const dispatch = useDispatch();
  const store = useStore();

  const resetExistingEditorData = useCallback(() => {
    dispatch(pagesModel.actions.setExistingEditorData(null));
  }, [dispatch]);

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPage
  ) as Page;

  const currentPageContent = useSelector(
    pagesModel.selectors.selectCurrentContent
  ) as DocumentContentItem;

  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  ) as TemplateExtended;

  const currentTemplateContent = useSelector(
    templatesModel.selectors.selectCurrentTemplateContent
  ) as DocumentContentItem;

  const currentPageOrTemplate = isTemplateMode ? currentTemplate : currentPage;
  const currentContent = isTemplateMode
    ? currentTemplateContent
    : currentPageContent;

  const content = currentPageOrTemplate?.content;

  const isEditorBlocked = useSelector(
    isTemplateMode
      ? templatesModel.selectors.selectIsEditorBlocked
      : pagesModel.selectors.selectIsEditorBlocked
  );

  const existingEditorData = useSelector(
    isTemplateMode
      ? templatesModel.selectors.selectExistingEditorData
      : pagesModel.selectors.selectExistingEditorData
  );

  const isNarrationRecordingSessionActive = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionActive
  );

  const {
    isCanEditDocumentContent,
    isCanEditPageStyles,
    isCanEditConversionTools,
    isCanEditPageSettings,
  } = usePagePermissions(currentPage);

  const isCanEditPage =
    isCanEditConversionTools &&
    isCanEditPageStyles &&
    isCanEditDocumentContent &&
    isCanEditPageSettings;
  const isCanEdit = isTemplateMode ? currentTemplate.isOwner : isCanEditPage;
  const showPreview =
    isEditorPreview || isMobile || isEditorBlocked || !isCanEdit || isTablet;

  useSetDocumentContentStyles(content ?? {});

  useEffect(() => {
    return () => {
      dispatch(pagesModel.actions.setEditorBlocked(undefined));
      dispatch(
        pagesModel.actions.setEditorPreviewMode(EditorPreviewModeEnum.DESKTOP)
      );
      websocketClient.leavePage(currentPageOrTemplate?.id);
      resetExistingEditorData();
    };
  }, [currentPageOrTemplate?.id, dispatch, resetExistingEditorData]);

  const data = currentContent?.contentJson || JSONContentFactory.DEFAULT();
  const tabs = [...(currentPageOrTemplate?.content.contentItems ?? [])].sort(
    (a, b) => a.order - b.order
  );

  const handleChange = debounce((contentJson: JSONContent) => {
    if (isTemplateMode) {
      const updatedContentItems = currentTemplate.content.contentItems.map(
        (item) => {
          if (item.id === currentContent.id) {
            return { ...item, contentJson };
          }
          return item;
        }
      );

      dispatch(
        templatesModel.actions.setCurrentTemplate({
          ...currentTemplate,
          content: {
            ...currentTemplate.content,
            contentItems: updatedContentItems,
          },
        })
      );

      if (currentTemplate.creationPhase === TemplateCreationPhase.DRAFT) {
        dispatch(templatesModel.actions.updateTemplateFromEditor());
      }

      dispatch(templatesModel.actions.setIsTemplateSavedInEditor(false));
    } else {
      dispatch(
        pagesModel.actions.changePageContent({
          contentItemId: currentContent.id,
          pageId: currentPage.id,
          contentJson,
        })
      );
    }
  }, 400);

  const { snippets: allowSnippets } = useSubscriptionLimits();

  return (
    <TiptapEditorProvider
      options={{
        content: data,
        editorProps: {
          attributes: {
            class: 'main-editor',
          },
        },
        extensions: [
          ...mainEditorExtensions,
          DropHandleExtension.configure({
            dispatch,
          }),
          AIExtension,
          TriggerMenu.configure({
            store,
            trigger: '/',
            groups: allowSnippets
              ? SLASH_MENU_GROUPS
              : getSlashMenuWithoutSnippet(),
          }),
        ],
        onUpdate: ({ editor, transaction }) => {
          if (transaction.getMeta('isPreventUpdate')) return;
          handleChange(editor.getJSON());
        },
      }}
    >
      <EditorWrapper
        isPreviewMode={showPreview}
        currentPageOrTemplate={currentPageOrTemplate}
        isTemplateMode={isTemplateMode}
        isCanEdit={isCanEdit}
        isBlocked={isEditorBlocked}
      >
        {showPreview && !isTemplateMode ? (
          <PreviewPage
            isBlocked={isEditorBlocked}
            userEmail={existingEditorData?.userEmail}
            userName={existingEditorData?.userName}
            currentPageOrTemplate={currentPageOrTemplate}
          />
        ) : (
          <TiptapEditorPage
            currentTab={currentContent}
            tabs={tabs}
            currentPageOrTemplate={currentPageOrTemplate}
            isTemplateMode={isTemplateMode}
          />
        )}
      </EditorWrapper>
    </TiptapEditorProvider>
  );
};

import { useContext } from 'react';
import { PlayerBubbleContext } from '../provider/player-bubble';

export const usePlayerBubble = () => {
  const context = useContext(PlayerBubbleContext);

  if (context === null) {
    throw new Error(
      'usePlayerBubble must be used within a PlayerBubbleProvider'
    );
  }

  return context;
};

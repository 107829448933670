import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { squeezePageModel } from '../model';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../editor-sidebar';
import { ConversionControlItem } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { narrationModel } from '../../narration';

type IProps = {
  isDisabled: boolean;
  isHidden: boolean;
};

export const SqueezePageConversionControl: React.FC<IProps> = ({
  isDisabled,
  isHidden,
}) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(squeezePageModel.selectors.selectIsOpen);

  const toggleIsSubscribeSqueezePage = useCallback(() => {
    dispatch(narrationModel.actions.setPopupOpen(false));
    dispatch(squeezePageModel.actions.setIsOpen(!isOpen));
  }, [dispatch, isOpen]);

  const handleOpenSqueezePageSettings = () => {
    dispatch(narrationModel.actions.setPopupOpen(false));
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.SQUEEZE_PAGE
      )
    );
  };

  if (isHidden) return null;

  return (
    <ConversionControlItem
      title="Squeeze Page"
      icon={IconMap.SqueezePageIcon}
      isOpened={isOpen}
      isDisabled={isDisabled}
      onEdit={handleOpenSqueezePageSettings}
      onPreview={toggleIsSubscribeSqueezePage}
    />
  );
};

import { useSelector } from 'react-redux';
import { teamsModel, useTeamPermissions } from '../../teams';
import { GuestUserBanner } from './GuestUserBanner';
import { GettingStartedStatus } from '../../getting-started';
import { useWindowWidth } from '@distribute/frontend/utils';
import { useUserAgentDevices } from '../../../shared/hooks/useUserAgentDevices';

export const SidebarBannerItems: React.FC = () => {
  const { isGuest } = useTeamPermissions();
  const isGettingStartedAvailable = useSelector(
    teamsModel.selectors.selectIsGettingStartedAvailable
  );
  const { isMobile } = useWindowWidth();
  const { isTablet } = useUserAgentDevices();

  if (isGuest) {
    return <GuestUserBanner />;
  }

  if (isGettingStartedAvailable && !isMobile && !isTablet) {
    return <GettingStartedStatus />;
  }

  return null;
};

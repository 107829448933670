import { FC, RefObject } from 'react';
import ReactPlayer from 'react-player';

import cn from 'classnames';
import { checkIsInf } from '../../../lib';

import { Slider } from './Slider';

import { usePlayer, usePlayerActions } from '../../../hooks';

type Props = {
  playerRef: RefObject<ReactPlayer>;
};

export const PlayerProgress: FC<Props> = ({ playerRef }) => {
  const { state } = usePlayer();
  const { handleSetTime } = usePlayerActions();

  const { isFullscreen, durationSeconds, playedSeconds, bufferedSeconds } =
    state;

  const isInf = checkIsInf(durationSeconds);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key, shiftKey } = e;

    if (isInf) return;

    if (key === 'ArrowRight') {
      e.preventDefault();

      const val = playedSeconds + 1 * (shiftKey ? 10 : 1);
      handleChangeValue(val > durationSeconds ? durationSeconds : val);
      return;
    }
    if (key === 'ArrowLeft') {
      e.preventDefault();

      const val = playedSeconds - 1 * (shiftKey ? 10 : 1);
      handleChangeValue(val < 0 ? 0 : val);
      return;
    }
  };

  const handleChangeValue = (value: number) => {
    handleSetTime(playerRef, value);
  };

  return (
    <div>
      <Slider
        id="progress-video"
        variant="progress-bar"
        progressValue={bufferedSeconds * 100}
        onKeyDownCapture={handleKeyDown}
        min={0}
        max={isInf ? 1 : durationSeconds}
        value={isInf ? [1] : [playedSeconds]}
        disabled={isInf || !durationSeconds}
        step={0.1}
        className={cn({ 'h-4': !isFullscreen, 'h-6': isFullscreen })}
        size={isFullscreen ? 'md' : 'sm'}
        onValueChange={([value]) => handleChangeValue(value)}
      />
    </div>
  );
};

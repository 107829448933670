import { useMemo } from 'react';
import { Section } from '../../../shared/ui/Section';
import { ChangeNameDomainForm } from './components/ChangeNameDomainForm';
import { LeaveTeamForm } from './components/LeaveTeamForm';
import { DeleteTeamForm } from './components/DeleteTeamForm';
import { teamsModel, useTeamPermissions } from '../../teams';
import { useSelector } from 'react-redux';
import { CustomDomainSettings } from '../../custom-domains';
import { useCurrentPlan } from '../../subscription/hooks';
import { GongTeamConfigurationForm } from './components';

export const TeamInfoSettings = () => {
  const { isOwner, isCanEdit } = useTeamPermissions();

  const teamUsers = useSelector(teamsModel.selectors.selectCurrentTeamMembers);
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const teamUsersInviteAccepted = useMemo(
    () => teamUsers.filter((user) => !user.isWaitingForInviteAcceptance),
    [teamUsers]
  );
  const currentPlan = useCurrentPlan();

  return (
    <div className="pb-14.5">
      <h1 className="text-display-sm font-display font-medium text-gray-900 mb-6 md:hidden">
        Workspace settings
      </h1>
      <Section title="Team info">
        <div className="flex flex-col gap-y-5">
          <ChangeNameDomainForm />
        </div>
      </Section>
      {isCanEdit && (
        <Section
          title="Custom Domain"
          requirePaidPlan={currentPlan.id === 'free'}
        >
          <CustomDomainSettings />
        </Section>
      )}
      <Section title="Gong Integration">
        <GongTeamConfigurationForm />
      </Section>
      {((teamUsersInviteAccepted.length > 1 && !currentTeam.isAppsumo) ||
        (teamUsersInviteAccepted.length > 1 &&
          currentTeam.isAppsumo &&
          !isOwner)) && (
        <Section title="Leave a team">
          <LeaveTeamForm />
        </Section>
      )}
      {isOwner && (
        <Section title="Delete a team">
          <DeleteTeamForm />
        </Section>
      )}
    </div>
  );
};

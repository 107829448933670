import { Editor, findParentNodeClosestToPos } from '@tiptap/react';
import { Plugin, EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { FragmentWithContent } from '../MultipleColumns';
import { ResolvedPos } from '@tiptap/pm/model';

export interface DropCursorOptions {
  color?: string | false;
  width?: number;
  class?: string;
}

export function dropCursor(
  options: DropCursorOptions = {},
  editor: Editor
): Plugin {
  return new Plugin({
    view(editorView) {
      return new DropCursorView(editorView, options, editor);
    },
  });
}

class DropCursorView {
  width: number;
  color: string | undefined;
  class: string | undefined;
  cursorPos: number | null = null;
  element: HTMLElement | null = null;
  timeout = -1;
  handlers: { name: string; handler: (event: Event) => void }[];
  inlineCursorDirection: 'left' | 'right' | null = null;
  constructor(
    readonly editorView: EditorView,
    options: DropCursorOptions,
    private readonly editor: Editor
  ) {
    this.width = options.width ?? 1;
    this.color = options.color === false ? undefined : options.color || 'black';
    this.class = options.class;

    this.handlers = ['dragover', 'dragend', 'drop', 'dragleave'].map((name) => {
      const handler = (e: Event) => {
        (this as any)[name](e);
      };
      editorView.dom.addEventListener(name, handler);
      return { name, handler };
    });
  }

  destroy() {
    this.handlers.forEach(({ name, handler }) =>
      this.editorView.dom.removeEventListener(name, handler)
    );
  }

  update(editorView: EditorView, prevState: EditorState) {
    if (this.cursorPos != null && prevState.doc != editorView.state.doc) {
      if (this.cursorPos > editorView.state.doc.content.size)
        this.setCursor(null);
      else this.updateOverlay();
    }
  }

  setCursor(pos: number | null, cursorClientX?: number) {
    if (pos === this.cursorPos) return;
    this.cursorPos = pos;
    if (pos === null) {
      this.element?.parentNode!.removeChild(this.element!);
      this.element = null;
      this.editor.commands.setDropBlockTarget(null);
    } else {
      this.updateOverlay(cursorClientX);
    }
  }

  updateOverlay(cursorClientX?: number) {
    const $pos = this.editorView.state.doc.resolve(this.cursorPos!);

    const isBlock = !$pos.parent.inlineContent;
    let rect;

    const editorDOM = this.editorView.dom,
      editorRect = editorDOM.getBoundingClientRect();
    const scaleX = editorRect.width / editorDOM.offsetWidth,
      scaleY = editorRect.height / editorDOM.offsetHeight;
    if (isBlock) {
      const before = $pos.nodeBefore,
        after = $pos.nodeAfter;
      if (before || after) {
        let node = this.editorView.nodeDOM(
          this.cursorPos! - (before ? before.nodeSize : 0)
        );

        const timelineItem = findParentNodeClosestToPos($pos, (node) => {
          return node.type.name === 'timelineItem';
        });

        if (timelineItem) {
          node = this.editorView.nodeDOM(
            timelineItem.pos - (before ? before.nodeSize : 0)
          );
        }

        if (node) {
          if (this.checkIfDropCursorIsBetweenColumns($pos, cursorClientX)) {
            return;
          }

          const nodeRect = (node as HTMLElement).getBoundingClientRect();
          let top = before ? nodeRect.bottom : nodeRect.top;

          if (before && after) {
            top =
              (top +
                (
                  this.editorView.nodeDOM(this.cursorPos!) as HTMLElement
                ).getBoundingClientRect().top) /
              2;
          }
          const halfWidth = (this.width / 2) * scaleY;
          this.editor.commands.setDropBlockTarget(node as HTMLElement);

          const rectTop = (node as HTMLElement).getBoundingClientRect();

          this.editor.commands.setDropBlockDirection(
            top > rectTop.top ? 'bottom' : 'top'
          );

          rect = {
            left: nodeRect.left,
            right: nodeRect.right,
            top: top - halfWidth,
            bottom: top + halfWidth,
          };
        }
      }
    }
    if (!rect) {
      const coords = this.editorView.coordsAtPos(this.cursorPos!);
      const halfWidth = (this.width / 2) * scaleX;
      rect = {
        left: coords.left - halfWidth,
        right: coords.left + halfWidth,
        top: coords.top,
        bottom: coords.bottom,
      };
    }

    const parent = this.editorView.dom.offsetParent as HTMLElement;

    if (!this.element) {
      this.element = parent.appendChild(document.createElement('div'));
      if (this.class) this.element.className = this.class;
      this.element.style.cssText =
        'position: absolute; z-index: 1000; pointer-events: none;';
      if (this.color) {
        this.element.style.backgroundColor = this.color;
      }
    }
    this.element.classList.toggle('prosemirror-dropcursor-block', isBlock);
    this.element.classList.toggle('prosemirror-dropcursor-inline', !isBlock);
    let parentLeft, parentTop;
    if (
      !parent ||
      (parent == document.body && getComputedStyle(parent).position == 'static')
    ) {
      // eslint-disable-next-line no-restricted-globals
      parentLeft = -pageXOffset;
      // eslint-disable-next-line no-restricted-globals
      parentTop = -pageYOffset;
    } else {
      const rect = parent.getBoundingClientRect();
      const parentScaleX = rect.width / parent.offsetWidth,
        parentScaleY = rect.height / parent.offsetHeight;
      parentLeft = rect.left - parent.scrollLeft * parentScaleX;
      parentTop = rect.top - parent.scrollTop * parentScaleY;
    }

    this.element.style.left = (rect.left - parentLeft) / scaleX + 'px';
    this.element.style.top = (rect.top - parentTop) / scaleY + 'px';
    this.element.style.width = (rect.right - rect.left) / scaleX + 'px';
    this.element.style.height = (rect.bottom - rect.top) / scaleY + 'px';
  }

  scheduleRemoval(timeout: number) {
    clearTimeout(this.timeout);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.timeout = setTimeout(() => this.setCursor(null), timeout);
  }

  dragover(event: DragEvent) {
    if (!this.editorView.editable) return;
    let pos: {
      pos: number;
      inside: number;
    } | null = null;

    pos = this.editorView.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    });
    if (pos?.pos === 0) {
      pos = this.editorView.posAtCoords({
        left: event.clientX + 80,
        top: event.clientY,
      });
    }

    const node =
      pos && pos.inside >= 0 && this.editorView.state.doc.nodeAt(pos.inside);

    const disableDropCursor = node && node.type.spec.disableDropCursor;
    const disabled =
      typeof disableDropCursor == 'function'
        ? disableDropCursor(this.editorView, pos, event)
        : disableDropCursor;

    if (pos && !disabled) {
      const target = pos.pos;

      const point = this.findDropPoint(target);

      this.setCursor(point, event.clientX);
      this.scheduleRemoval(5000);
    }
  }

  findDropPoint(pos: number) {
    const $pos = this.editorView.state.doc.resolve(pos);

    const isInline = !!$pos.parent.inlineContent;
    if (!isInline) {
      return pos;
    }

    return null;
  }

  dragend() {
    this.scheduleRemoval(20);
  }

  drop() {
    this.scheduleRemoval(20);
  }

  dragleave(event: DragEvent) {
    if (
      event.target == this.editorView.dom ||
      !this.editorView.dom.contains((event as any).relatedTarget)
    )
      this.setCursor(null);
  }

  checkIfDropCursorIsBetweenColumns(pos: ResolvedPos, cursorClientX?: number) {
    const COLUMNS_GAP_HALF_WIDTH = 12;

    const ancestor = findParentNodeClosestToPos(pos, (node) => {
      return node.type.name === 'columnBlock';
    });

    if (ancestor) {
      const columnsRects: DOMRect[] = [];
      (ancestor.node.content as FragmentWithContent).forEach((_, pos) => {
        columnsRects.push(
          (
            this.editor.view.nodeDOM(pos + ancestor.pos + 1) as HTMLElement
          ).getBoundingClientRect()
        );
      });

      if (cursorClientX === undefined) return false;

      const isCursorBetweenColumns = columnsRects.some(
        (currentRect, index, rects) => {
          const isCursorToTheRight =
            cursorClientX >= currentRect.right - COLUMNS_GAP_HALF_WIDTH;

          if (index === rects.length - 1) return isCursorToTheRight;

          const isCursorToTheLeft =
            cursorClientX <= currentRect.left + COLUMNS_GAP_HALF_WIDTH;
          const isCursorToTheLeftOfTheNextColumn =
            cursorClientX <= rects[index + 1].left + COLUMNS_GAP_HALF_WIDTH;

          if (index === 0)
            return (
              isCursorToTheLeft ||
              (isCursorToTheRight && isCursorToTheLeftOfTheNextColumn)
            );

          return isCursorToTheRight && isCursorToTheLeftOfTheNextColumn;
        }
      );

      return isCursorBetweenColumns;
    }
    return false;
  }
}

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'chartjs-adapter-moment';

import { Dropdown, LoaderDots, Tabs } from '../../../shared/ui';
import { LinearChart } from './components/LinearChart';
import { analyticsModel } from '..';
import {
  AnalyticsConversionSource,
  AnalyticsMetric,
  ConversionLine,
} from '../types';
import { TabContent, AdditionalData } from '../../../shared/ui/Tabs';
import {
  DateRange,
  DateRangePicker,
  DateRangePeriod,
} from '../../../shared/ui/date-range-picker';
import { DateUnitState, ChartTypeState } from './const';
import { getCurrentConversionSourcesCharts } from './utils';

type AnalyticsTabItem = {
  name: AnalyticsMetric;
  title: string;
  value: number | string;
  additionalData?: AdditionalData;
};

export const AnalyticsTab: React.FC = () => {
  const dispatch = useDispatch();
  const isLoadingData = useSelector(
    analyticsModel.selectors.selectIsLoadingData
  );
  const analyticsTotal = useSelector(analyticsModel.selectors.selectTotal);
  const analyticsData = useSelector(analyticsModel.selectors.selectChartData);
  const selectedDateRange = useSelector(
    analyticsModel.selectors.selectDateRange
  );
  const selectedDateRangePeriod = useSelector(
    analyticsModel.selectors.selectDateRangePeriod
  );

  const tabs = useMemo<AnalyticsTabItem[]>(
    () => [
      {
        name: 'pageViews',
        title: 'Page views',
        value: analyticsTotal.pageViews,
        additionalData: {
          name: 'tabViews',
          title: 'Tab views',
          value: (analyticsData ?? []).map(({ tabViews = [] }) => tabViews),
        },
      },
      {
        name: 'uniqueVisitors',
        title: 'Unique visitors',
        value: analyticsTotal.uniqueVisitors,
      },
      {
        name: 'conversions',
        title: 'Conversions',
        value: analyticsTotal.conversions,
      },
      {
        name: 'conversionRate',
        title: 'Conversion rate',
        value: analyticsTotal.conversionRate,
      },
      {
        name: 'averageTimeOnPage',
        title: 'Avg. time on page',
        value: analyticsTotal.averageTimeOnPage,
      },
      {
        name: 'averageTimeToConvert',
        title: 'Avg. time to convert',
        value: analyticsTotal.averageTimeToConvert,
      },
    ],
    [analyticsTotal, analyticsData]
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const selectedTab: TabContent = tabs[selectedTabIndex];

  const dateUnits = useMemo(
    () => [
      {
        label: 'Hour',
        onClick: () => {
          setCurrentDateUnit(DateUnitState.HOUR);
        },
        id: DateUnitState.HOUR,
      },
      {
        label: 'Day',
        onClick: () => {
          setCurrentDateUnit(DateUnitState.DAY);
        },
        id: DateUnitState.DAY,
      },
      {
        label: 'Week',
        onClick: () => {
          setCurrentDateUnit(DateUnitState.WEEK);
        },
        id: DateUnitState.WEEK,
      },
      {
        label: 'Month',
        onClick: () => {
          setCurrentDateUnit(DateUnitState.MONTH);
        },
        id: DateUnitState.MONTH,
      },
    ],
    []
  );

  const chartType = [
    {
      label: 'Linear',
      onClick: () => {
        setCurrentChartType(ChartTypeState.LINEAR);
      },
      id: ChartTypeState.LINEAR,
    },
    {
      label: 'Cumulative',
      onClick: () => {
        setCurrentChartType(ChartTypeState.CUMULATIVE);
      },
      id: ChartTypeState.CUMULATIVE,
    },
  ];

  const [currentDateUnit, setCurrentDateUnit] = useState(dateUnits[1].id);
  const [currentChartType, setCurrentChartType] = useState(chartType[0].id);

  const [conversionLines, setConversionLines] = useState<ConversionLine[]>([]);

  const handleDateRange = (date: DateRange) => {
    dispatch(analyticsModel.actions.onChangeRange(date));
  };

  const handleRangePeriod = (type: DateRangePeriod) => {
    dispatch(analyticsModel.actions.setDateRangePeriod(type));
  };

  const toggleConversionLine = (id: AnalyticsConversionSource) => {
    setConversionLines((prev) =>
      prev.reduce<ConversionLine[]>((acc, line) => {
        return [
          ...acc,
          { ...line, isShow: line.id === id ? !line.isShow : line.isShow },
        ];
      }, [])
    );
  };

  useEffect(() => {
    if (isLoadingData) return;

    setConversionLines(getCurrentConversionSourcesCharts(analyticsData));
  }, [isLoadingData, analyticsData]);

  useEffect(() => {
    selectedDateRangePeriod === 'today'
      ? setCurrentDateUnit(DateUnitState.HOUR)
      : setCurrentDateUnit(DateUnitState.DAY);
  }, [selectedDateRangePeriod]);

  return (
    <div className="flex flex-col justify-between pb-13.5">
      <div className="flex flex-row justify-between sm:flex-col sm:gap-y-3 mb-6">
        <DateRangePicker
          onChange={handleDateRange}
          getRangePeriod={handleRangePeriod}
          defaultValue={selectedDateRangePeriod}
        />
        <div className="flex flex-row gap-x-3">
          <div className="w-40 sm:flex-1 hover:bg-gray-50">
            <Dropdown
              listStyles="shadow-lg"
              items={chartType}
              currentItemId={currentChartType}
              itemStyles="w-40"
              triggerStyles="w-40 !h-10"
            />
          </div>
          <div className="w-30 sm:flex-1 hover:bg-gray-50">
            <Dropdown
              listStyles="shadow-lg"
              items={dateUnits}
              currentItemId={currentDateUnit}
              itemStyles="w-30"
              triggerStyles="w-30 !h-10"
            />
          </div>
        </div>
      </div>
      {isLoadingData ? (
        <LoaderDots className="mt-44" />
      ) : (
        <Tabs
          key={selectedTab.name}
          selectedTab={selectedTab}
          tabs={tabs}
          onSelectTab={(_, index) => setSelectedTabIndex(index)}
          className="flex row justify-between overflow-x-auto scrollbar-hide"
          tabClassName="grow !text-gray-600 !font-normal md:min-w-40 sm:min-w-35 border-b-5 pt-0 pb-2 [&.active]:border-primary-600 hover:border-transparent"
        >
          <LinearChart
            toggleConversionLine={toggleConversionLine}
            conversionLines={conversionLines}
            selectedItem={selectedTab}
            dateUnit={currentDateUnit}
            chartType={currentChartType}
            dateRange={selectedDateRange}
          />
        </Tabs>
      )}
    </div>
  );
};

import { takeLatest, put, select, fork, call } from 'redux-saga/effects';

import { narrationModel } from '../';
import { selectNarration } from '../selectors';
import { RT } from '../../../../shared/types';

import {
  NarrationCountdownState,
  NarrationLogicState,
  NarrationMediaState,
} from '../types';

import { videoNarrationUploadApi } from '../../../../shared/api';

import {
  calculatePauseDuration,
  calculateElapsedRecordingDuration,
} from '../../lib';

import { recorderDurationTimer } from './recorder-duration-timer';
import { messageActions } from '../message-action';
import { pagesModel } from '../../../pages';
import { createNotification, snackbarModel } from '../../../snackbar';

function* recorderIdleSagasWorker() {
  yield takeLatest(narrationModel.actions.activateRecorder, function* () {
    yield put(narrationModel.actions.setMediaState(NarrationMediaState.Active));
    yield put(
      narrationModel.actions.setCountdownState(NarrationCountdownState.Active)
    );
  });

  yield takeLatest(narrationModel.actions.destroyRecorder, function* (action) {
    yield put(narrationModel.actions.setNarrationActive(null));
    yield put(narrationModel.actions.resetRecorder());

    if (action.payload.uploadId) {
      const { uploadId } = action.payload;
      yield call(videoNarrationUploadApi.cancelSession, { uploadId });
    }

    //prettier-ignore
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', 'Unexpected issue with recording narration')));
  });

  yield takeLatest(narrationModel.actions.start, function* () {
    try {
      //prettier-ignore
      const { id: pageId }: RT<typeof pagesModel.selectors.selectCurrentPageWithError> = yield select(pagesModel.selectors.selectCurrentPageWithError);

      const { videoId, audioId }: RT<typeof selectNarration> = yield select(
        selectNarration
      );

      yield put(
        narrationModel.actions.setMessageAction(
          messageActions.onInit({
            videoId,
            audioId,
            pageId,
          })
        )
      );

      yield put(
        narrationModel.actions.setLogicState(NarrationLogicState.Initialization)
      );
    } catch (e: unknown) {
      //prettier-ignore
      yield put(snackbarModel.actions.addNotificationAction(createNotification('error', 'Failed to start recording')));
    }
  });

  yield takeLatest(
    narrationModel.actions.cancelUploadSession,
    function* (action) {
      const { uploadId } = action.payload;
      yield call(videoNarrationUploadApi.cancelSession, { uploadId });
    }
  );
}

function* recorderInitSagasWorker() {
  yield takeLatest(narrationModel.actions.startUploadingSession, function* () {
    yield put(
      narrationModel.actions.setMessageAction(
        messageActions.onStartUploadingSession()
      )
    );
    yield put(
      narrationModel.actions.setCountdownState(
        NarrationCountdownState.Completed
      )
    );
    yield put(
      narrationModel.actions.setLogicState(NarrationLogicState.LoadSession)
    );
  });
}

function* recorderLoadSessionSagasWorker() {
  yield takeLatest(narrationModel.actions.startRecording, function* (action) {
    yield put(narrationModel.actions.setNarrationActive(action.payload));
    yield put(
      narrationModel.actions.setMessageAction(messageActions.onStartRecording())
    );
    yield put(
      narrationModel.actions.setTime({
        start: new Date().getTime(),
        pause: 0,
        pauseAll: 0,
        duration: 0,
      })
    );
    yield put(
      narrationModel.actions.setLogicState(NarrationLogicState.Running)
    );
  });
}

function* recorderRunningSagasWorker() {
  yield fork(recorderActionsSagaWorker);
  yield fork(recorderDurationTimer);

  yield takeLatest(narrationModel.actions.pauseRecording, function* () {
    const { time }: RT<typeof selectNarration> = yield select(selectNarration);

    yield put(
      narrationModel.actions.setMessageAction(messageActions.onPauseRecording())
    );
    yield put(
      narrationModel.actions.setTime({
        pause: new Date().getTime(),
        duration: calculateElapsedRecordingDuration(
          time?.start || 0,
          time?.pauseAll || 0
        ),
      })
    );
    yield put(narrationModel.actions.setLogicState(NarrationLogicState.Paused));
  });
}

function* recorderPausedSagasWorker() {
  yield fork(recorderActionsSagaWorker);

  yield takeLatest(narrationModel.actions.resumeRecording, function* () {
    const { time }: RT<typeof selectNarration> = yield select(selectNarration);

    yield put(
      narrationModel.actions.setMessageAction(
        messageActions.onResumeRecording()
      )
    );
    yield put(
      narrationModel.actions.setTime({
        pause: 0,
        pauseAll: calculatePauseDuration(time?.pause || 0, time?.pauseAll || 0),
      })
    );
    yield put(
      narrationModel.actions.setLogicState(NarrationLogicState.Running)
    );
  });
}

function* recorderActionsSagaWorker() {
  yield takeLatest(narrationModel.actions.stopRecording, function* () {
    yield put(
      narrationModel.actions.setMessageAction(messageActions.onStopRecording())
    );
    yield put(
      narrationModel.actions.setLogicState(NarrationLogicState.Stopped)
    );
  });

  yield takeLatest(narrationModel.actions.restartRecording, function* () {
    yield put(
      narrationModel.actions.setMessageAction(
        messageActions.onRestartRecording()
      )
    );

    yield put(narrationModel.actions.setNarrationActive(null));
    yield put(narrationModel.actions.resetTime());
    yield put(
      narrationModel.actions.setCountdownState(NarrationCountdownState.Active)
    );
    yield put(
      narrationModel.actions.setLogicState(NarrationLogicState.Initialization)
    );
  });

  yield takeLatest(narrationModel.actions.cancelRecording, function* () {
    yield put(
      narrationModel.actions.setMessageAction(
        messageActions.onCancelRecording()
      )
    );

    yield put(narrationModel.actions.setNarrationActive(null));
    yield put(narrationModel.actions.resetRecorder());
  });
}

function* recorderStoppedSagasWorker() {
  yield takeLatest(
    narrationModel.actions.completeUploadSession,
    function* (action) {
      const { uploadId } = action.payload;

      const { narrationActive }: RT<typeof selectNarration> = yield select(
        selectNarration
      );

      yield call(videoNarrationUploadApi.completeSession, { uploadId });

      yield put(narrationModel.actions.setNarration(narrationActive));
      yield put(narrationModel.actions.resetRecorder());
    }
  );
}

export function* recorderSagaWorker() {
  yield fork(recorderIdleSagasWorker);

  yield takeLatest(narrationModel.actions.setLogicState, function* (action) {
    const { payload: logicState } = action;

    if (logicState === NarrationLogicState.Initialization) {
      yield fork(recorderInitSagasWorker);
    }

    if (logicState === NarrationLogicState.LoadSession) {
      yield fork(recorderLoadSessionSagasWorker);
    }

    if (logicState === NarrationLogicState.Paused) {
      yield fork(recorderPausedSagasWorker);
    }

    //prettier-ignore
    if ([NarrationLogicState.Running, NarrationLogicState.Resuming].includes(logicState)) {
      yield fork(recorderRunningSagasWorker);
    }

    if (logicState === NarrationLogicState.Stopped) {
      yield fork(recorderStoppedSagasWorker);
    }
  });
}

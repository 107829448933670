import { useCallback, useEffect, useMemo, useRef } from 'react';
import { BubbleMenu } from '@tiptap/react';
import classNames from 'classnames';
import {
  BubbleMenuDivider,
  BubbleMenuIconButton,
  BubbleMenuToolbar,
  useTiptapEditor,
} from '../../../../entities/tiptap-editor';
import { IconMap } from '../../../../shared/sprite';
import { TiptapTextFormatDropdown } from './TiptapTextFormatDropdown';
import { TiptapTextAlignDropdown } from './TiptapTextAlignDropdown';
import { BubbleMenuColorPicker } from './bubble-menu-color-picker';

type TooltipContentType = {
  label: string;
  command?: string;
};

const disableForNodes = ['codeBlock'];

const TooltipContent: React.FC<TooltipContentType> = ({ label, command }) => {
  return (
    <div className="flex flex-col items-center">
      <p className="text-sm font-medium">{label}</p>
      {command && (
        <p className="text-xs font-medium text-gray-300">{command}</p>
      )}
    </div>
  );
};

export const TiptapTextBubbleMenu = () => {
  const { editor } = useTiptapEditor();
  const isSelectionStart = useRef(false);

  const shouldShow = useCallback(() => {
    if (!editor) return false;
    return (
      !disableForNodes.some((nodeName) => editor.isActive(nodeName)) &&
      !editor.state.selection.empty &&
      editor.state.selection.$anchor.node().isTextblock &&
      editor.view.state.doc.nodeAt(editor.view.state.selection.from)?.type
        .name !== 'customLink' &&
      !isSelectionStart.current
    );
  }, [editor]);

  const setCurrentSelectionWithDelay = (delay = 0) => {
    setTimeout(() => {
      if (!editor) return;
      editor.commands.setTextSelection({
        from: editor?.state.selection.head,
        to: editor?.state.selection.anchor,
      });
    }, delay);
  };

  useEffect(() => {
    const mousedown = () => {
      isSelectionStart.current = true;
    };
    const mouseup = () => {
      const wasSelectionStart = isSelectionStart.current;
      isSelectionStart.current = false;
      if (wasSelectionStart && shouldShow()) {
        setCurrentSelectionWithDelay(250);
      }
    };

    editor?.view.dom.addEventListener('mousedown', mousedown);
    window.addEventListener('mouseup', mouseup);
    return () => {
      editor?.view.dom.removeEventListener('mousedown', mousedown);
      window.removeEventListener('mouseup', mouseup);
    };
  }, [editor?.view.dom]);

  const currentItemId = useMemo(() => {
    if (!editor) {
      return 'paragraph';
    }

    if (editor.isActive('heading', { level: 2 })) {
      return 'heading1';
    }

    if (editor.isActive('heading', { level: 3 })) {
      return 'heading2';
    }

    if (editor.isActive('heading', { level: 4 })) {
      return 'heading3';
    }

    if (editor.isActive('paragraph')) {
      return 'paragraph';
    }

    return 'paragraph';
  }, [editor, editor?.state]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenAIMenu = () => {
    if (!editor) return;

    editor.commands.renderAI();
  };

  if (!editor) {
    return null;
  }

  const isSelectedTextWithLimitedOptions =
    editor.isActive('table') ||
    editor.isActive('timelineItem') ||
    editor.isActive('customTaskListItem');
  const isSelectedTextToggleListHeader =
    editor.isActive('toggleList') && !editor.isActive('toggleListItemContent');

  return (
    <BubbleMenu
      className={classNames({
        'limited-bubble-menu': isSelectedTextWithLimitedOptions,
      })}
      tippyOptions={{
        duration: 200,
        hideOnClick: true,
        placement: 'top-start',
        maxWidth: 'none',
        interactive: true,
        offset: ({ popper, reference }) => {
          const editorRect = editor.view.dom.getBoundingClientRect();
          const editorEndX = editorRect.x + editorRect.width;

          if (reference.x + popper.width >= editorEndX) {
            const diff = reference.x + popper.width - editorEndX;
            return [-diff, 5];
          }

          return [0, 5];
        },
      }}
      editor={editor}
      shouldShow={shouldShow}
      updateDelay={0}
    >
      {!editor.storage.ai.isRendered && (
        <BubbleMenuToolbar>
          {!isSelectedTextWithLimitedOptions &&
            !isSelectedTextToggleListHeader && (
              <>
                <BubbleMenuIconButton
                  onClick={handleOpenAIMenu}
                  tooltip={
                    <TooltipContent label="Ask AI to edit and generate the text" />
                  }
                  buttonContent={
                    <>
                      <img
                        src="../../../assets/images/wizard-write.svg"
                        alt="Wizard write icon"
                        width={20}
                      />
                      <span className="text-sm font-medium">Wizard</span>
                    </>
                  }
                />
                <BubbleMenuDivider />
                <TiptapTextFormatDropdown
                  editor={editor}
                  currentItemId={currentItemId}
                />
                <BubbleMenuDivider />
              </>
            )}
          <BubbleMenuIconButton
            active={editor.isActive('bold')}
            iconName={IconMap.Bold}
            onClick={() => editor.chain().toggleBold().focus().run()}
            tooltip={<TooltipContent label="Bold" command="⌘B" />}
          />
          <BubbleMenuIconButton
            active={editor.isActive('italic')}
            iconName={IconMap.Italic}
            onClick={() => editor.chain().toggleItalic().focus().run()}
            tooltip={<TooltipContent label="Italic" command="⌘I" />}
          />
          <BubbleMenuIconButton
            active={editor.isActive('underline')}
            iconName={IconMap.Underline}
            onClick={() => editor.chain().toggleUnderline().focus().run()}
            tooltip={<TooltipContent label="Underline" command="⌘U" />}
          />
          <BubbleMenuIconButton
            active={editor.isActive('strike')}
            iconName={IconMap.Strikethrough}
            onClick={() => editor.chain().toggleStrike().focus().run()}
            tooltip={<TooltipContent label="Strikethrough" command="⌘⇧X" />}
          />
          <BubbleMenuDivider />
          {!isSelectedTextWithLimitedOptions && (
            <>
              <BubbleMenuIconButton
                iconName={IconMap.TypeStrikethrough}
                onClick={() => {
                  let chain = editor
                    .chain()
                    .unsetAllMarks()
                    .setParagraph()
                    .setTextAlign('left');

                  if (editor.isActive('bulletList')) {
                    chain = chain.toggleBulletList();
                  }
                  if (editor.isActive('orderedList')) {
                    chain = chain.toggleOrderedList();
                  }
                  if (editor.isActive('blockquote')) {
                    chain = chain.unsetBlockquote();
                  }
                  if (editor.isActive('callout')) {
                    chain = chain.toggleWrap('callout');
                  }
                  if (editor.isActive('customLink')) {
                    chain = chain.unsetCustomLink();
                  }

                  chain.focus().run();
                }}
                tooltip={<TooltipContent label="Remove formatting" />}
              />
              <BubbleMenuDivider />
              <TiptapTextAlignDropdown editor={editor} />
              <BubbleMenuDivider />
              {!isSelectedTextToggleListHeader && (
                <>
                  <BubbleMenuIconButton
                    active={editor.isActive('bulletList')}
                    iconName={IconMap.ListBulleted}
                    onClick={() =>
                      editor.chain().toggleBulletList().focus().run()
                    }
                    tooltip={<TooltipContent label="Bulleted list" />}
                  />
                  <BubbleMenuIconButton
                    active={editor.isActive('orderedList')}
                    iconName={IconMap.ListNumbered}
                    onClick={() =>
                      editor.chain().toggleOrderedList().focus().run()
                    }
                    tooltip={<TooltipContent label="Numbered list" />}
                  />
                  <BubbleMenuIconButton
                    active={editor.isActive('blockquote')}
                    iconName={IconMap.Quote}
                    onClick={() => {
                      if (editor.isActive('callout')) {
                        editor
                          .chain()
                          .toggleWrap('callout')
                          .toggleBlockquote()
                          .focus()
                          .run();
                      } else {
                        editor.chain().toggleBlockquote().focus().run();
                      }
                    }}
                    tooltip={<TooltipContent label="Block quote" />}
                  />
                  <BubbleMenuIconButton
                    active={editor.isActive('callout')}
                    iconName={IconMap.SpacingVertical}
                    onClick={() =>
                      editor
                        .chain()
                        .unsetBlockquote()
                        .toggleCallout()
                        .focus()
                        .run()
                    }
                    tooltip={<TooltipContent label="Callout block" />}
                  />
                  <BubbleMenuDivider />
                </>
              )}
            </>
          )}

          <BubbleMenuIconButton
            active={editor.isActive('customLink')}
            iconName={IconMap.Link02}
            onClick={() => {
              if (editor.isActive('customLink')) {
                return editor.chain().unsetCustomLink().run();
              }
              return editor.chain().setCustomLink().focus().run();
            }}
            tooltip={<TooltipContent label="Link" command="⌘K" />}
          />
          <BubbleMenuDivider />
          <BubbleMenuColorPicker editor={editor} />
        </BubbleMenuToolbar>
      )}
    </BubbleMenu>
  );
};

import {
  AUTH_ROUTE,
  CLOSE_WINDOW_ROUTE,
  COMPLETE_PASSWORD_RESET_ROUTE,
  CONFIRM_DELETE_TEAM_ROUTE,
  EDITOR_PAGE_ROUTE,
  GETTING_STARTED_ROUTE,
  INDEX_ROUTE,
  LEGAL_PRIVACY_POLICY_ROUTE,
  LEGAL_TERMS_OF_USE_ROUTE,
  LINKEDIN_CALLBACK_ROUTE,
  LOADING_ROUTE,
  LOGIN_ROUTE,
  NOT_FOUND_ROUTE,
  ONBOARDING_ROUTE,
  PUBLIC_TEMPLATE_ROUTE,
  REGISTER_APP_SUMO_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  SETTINGS_INTEGRATIONS_ROUTE,
  SETTINGS_PROFILE_ROUTE,
  SETTINGS_ROUTE,
  SUCCESSFULLY_CREATED_ACCOUNT_ROUTE,
  TEAM_LEFT_ROUTE,
  TEAM_SETTINGS_BRAND_ROUTE,
  TEAM_SETTINGS_STYLES_ROUTE,
  TEAM_SETTINGS_INFO_ROUTE,
  TEAM_SETTINGS_MEMBERS_ROUTE,
  TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE,
  TEAM_SETTINGS_ROUTE,
  TEAM_SETTINGS_SUBSCRIPTION_ROUTE,
  TEMPLATES_APPLY_ROUTE,
  TEMPLATES_DRAFTS_ROUTE,
  TEMPLATES_PERSONAL_ROUTE,
  TEMPLATES_ROUTE,
  TEMPLATES_TEAM_ROUTE,
  TEMPLATE_EDITOR_PAGE_ROUTE,
  SNIPPET_EDITOR_ROUTE,
  TWITTER_CALLBACK_ROUTE,
  UI_COMPONENTS_PAGE,
  VERIFY_EMAIL_ROUTE,
  WORKSPACE_ANALYTICS_ROUTE,
  WORKSPACE_FOLDER_ROUTE,
  WORKSPACE_ROUTE,
  WORKSPACE_RECENT_VIEWERS_ROUTE,
  WORKSPACE_LEADS_ROUTE,
  redirect,
  SNIPPETS_ROUTE,
  SNIPPETS_PERSONAL_ROUTE,
  SNIPPETS_TEAM_ROUTE,
  SNIPPETS_DRAFTS_ROUTE,
  QUICK_RECORDINGS_ROUTE,
  QUICK_RECORDINGS_MY_ROUTE,
  QUICK_RECORDINGS_SHARED_ROUTE,
  QUICK_RECORDING_ROUTE,
  SETTINGS_SUPERUSER_ROUTE,
  PAGES_OVERVIEW_ROUTE,
} from '../../../entities/history';
import { onboardingWorker } from '../../../pages/onboarding';

import { closeWindow, nil } from '../../../shared/lib';

import {
  linkedinAuthWorker,
  twitterAuthWorker,
} from '../../../entities/social-auth';
import { templateEditorPageSaga } from '../../../features/templates/model/sagas';
import { analyticsWorker } from '../../../pages/analytics';
import { pagesOverviewWorker } from '../../../pages/pages-overview';
import { confirmDeleteTeamWorker } from '../../../pages/confirm-delete-team/model';
import { editorPageSaga } from '../../../pages/editor';
import { verifyEmailWorker } from '../../../pages/email-verification';
import { gettingStartedWorker } from '../../../pages/getting-started/model';
import { publicTemplateWorker } from '../../../pages/public-template/model';
import {
  recordDetailsWorker,
  recordsSharedWorker,
} from '../../../pages/recordings/model';
import { settingsWorker } from '../../../pages/settings/model';
import { signUpAppSumoWorker } from '../../../pages/sign-up-app-sumo';
import { successfullyCreatedAccountWorker } from '../../../pages/successfully-created-account';
import { teamSettingsWorker } from '../../../pages/team-settings';
import {
  workspaceFolderSaga,
  workspaceSaga,
} from '../../../pages/workspace/model';
import { sharedRouteHandlers } from './sharedRouteHandlers';
import { RouteHandler } from './types';
import {
  snippetEditorSaga,
  snippetsSaga,
} from '../../../features/snippets/model/sagas';
import { superuserSettingsWorker } from '../../../features/superuser';
import { recordsWith } from '../../../pages/recordings/model';

export const privateRouteHandlers: RouteHandler[] = [
  { route: AUTH_ROUTE, saga: nil },
  { route: INDEX_ROUTE, saga: redirect.toWorkspaceDefaultFolder },
  { route: REGISTER_ROUTE, saga: redirect.toOnboarding },
  { route: REGISTER_APP_SUMO_ROUTE, saga: signUpAppSumoWorker },
  { route: LOGIN_ROUTE, saga: redirect.toWorkspace },
  { route: RESET_PASSWORD_ROUTE, saga: redirect.toWorkspace },
  { route: COMPLETE_PASSWORD_RESET_ROUTE, saga: redirect.toWorkspace },
  { route: NOT_FOUND_ROUTE, saga: nil },

  { route: PAGES_OVERVIEW_ROUTE, saga: pagesOverviewWorker },
  { route: WORKSPACE_ROUTE, saga: workspaceSaga },
  { route: WORKSPACE_ANALYTICS_ROUTE, saga: analyticsWorker },
  { route: WORKSPACE_LEADS_ROUTE, saga: analyticsWorker },
  { route: WORKSPACE_RECENT_VIEWERS_ROUTE, saga: analyticsWorker },
  { route: WORKSPACE_FOLDER_ROUTE, saga: workspaceFolderSaga },

  { route: TEMPLATES_ROUTE, saga: workspaceSaga },
  { route: TEMPLATES_APPLY_ROUTE, saga: workspaceSaga },
  { route: TEMPLATES_PERSONAL_ROUTE, saga: workspaceSaga },
  { route: TEMPLATES_TEAM_ROUTE, saga: workspaceSaga },
  { route: TEMPLATES_DRAFTS_ROUTE, saga: workspaceSaga },

  { route: SNIPPETS_ROUTE, saga: snippetsSaga },
  { route: SNIPPETS_PERSONAL_ROUTE, saga: snippetsSaga },
  { route: SNIPPETS_TEAM_ROUTE, saga: snippetsSaga },
  { route: SNIPPETS_DRAFTS_ROUTE, saga: snippetsSaga },

  { route: QUICK_RECORDINGS_ROUTE, saga: redirect.toRecordsMy },
  { route: QUICK_RECORDINGS_MY_ROUTE, saga: recordsWith(nil) },
  {
    route: QUICK_RECORDINGS_SHARED_ROUTE,
    saga: recordsWith(recordsSharedWorker),
  },
  { route: QUICK_RECORDING_ROUTE, saga: recordDetailsWorker(false) },

  { route: VERIFY_EMAIL_ROUTE, saga: verifyEmailWorker },
  { route: ONBOARDING_ROUTE, saga: onboardingWorker },

  { route: EDITOR_PAGE_ROUTE, saga: editorPageSaga },
  { route: TEMPLATE_EDITOR_PAGE_ROUTE, saga: templateEditorPageSaga },
  { route: SNIPPET_EDITOR_ROUTE, saga: snippetEditorSaga },

  { route: LOADING_ROUTE, saga: nil },
  { route: CLOSE_WINDOW_ROUTE, saga: closeWindow },
  { route: SETTINGS_ROUTE, saga: settingsWorker },
  { route: SETTINGS_PROFILE_ROUTE, saga: settingsWorker },
  { route: SETTINGS_INTEGRATIONS_ROUTE, saga: settingsWorker },
  { route: SETTINGS_SUPERUSER_ROUTE, saga: superuserSettingsWorker },
  { route: TEAM_SETTINGS_ROUTE, saga: teamSettingsWorker },
  { route: TEAM_SETTINGS_INFO_ROUTE, saga: teamSettingsWorker },
  { route: TEAM_SETTINGS_BRAND_ROUTE, saga: teamSettingsWorker },
  { route: TEAM_SETTINGS_STYLES_ROUTE, saga: teamSettingsWorker },
  { route: TEAM_SETTINGS_MEMBERS_ROUTE, saga: teamSettingsWorker },
  { route: TEAM_SETTINGS_SUBSCRIPTION_ROUTE, saga: teamSettingsWorker },
  { route: TEAM_SETTINGS_PERMISSIONS_CONTROL_ROUTE, saga: teamSettingsWorker },

  { route: TEAM_LEFT_ROUTE, saga: nil },
  { route: CONFIRM_DELETE_TEAM_ROUTE, saga: confirmDeleteTeamWorker },

  { route: LEGAL_PRIVACY_POLICY_ROUTE, saga: nil },
  { route: LEGAL_TERMS_OF_USE_ROUTE, saga: nil },
  { route: UI_COMPONENTS_PAGE, saga: nil },

  { route: PUBLIC_TEMPLATE_ROUTE, saga: publicTemplateWorker },

  { route: LINKEDIN_CALLBACK_ROUTE, saga: linkedinAuthWorker },
  { route: TWITTER_CALLBACK_ROUTE, saga: twitterAuthWorker },
  { route: GETTING_STARTED_ROUTE, saga: gettingStartedWorker },

  {
    route: SUCCESSFULLY_CREATED_ACCOUNT_ROUTE,
    saga: successfullyCreatedAccountWorker,
  },
  ...sharedRouteHandlers,
];

const iconBaseUrl =
  'https://storage.googleapis.com/distribute-production-storage/general';

export const mapTypeWithIcon = {
  email: `${iconBaseUrl}/at-sign.svg`,
  phone: `${iconBaseUrl}/phone.svg`,
  website: `${iconBaseUrl}/globe-01.svg`,
  linkedin: `${iconBaseUrl}/linkedin-line.svg`,
  twitter: `${iconBaseUrl}/x-social.svg`,
  facebook: `${iconBaseUrl}/facebook-line.svg`,
  calendar: `${iconBaseUrl}/calendar-plus-01.svg`,
};

export const COPY_ICON = `${iconBaseUrl}/copy-03.svg`;

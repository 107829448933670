import { TaskItem } from '@tiptap/extension-task-item';
import { mergeAttributes } from '@tiptap/react';

const CHECK_ICON =
  'https://storage.googleapis.com/distribute-production-storage/general/check.svg';

export const TodoListItemExt = TaskItem.extend({
  name: 'todoListItem',

  renderHTML({ node, HTMLAttributes }) {
    return [
      'li',
      mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes,
        {
          'data-type': this.name,
        },
        { class: 'todo-item' }
      ),
      [
        'label',
        { class: 'todo-icon__label' },
        [
          'div',
          {
            class: `${
              node.attrs.checked
                ? 'todo-item__icon--checked'
                : 'todo-item__icon--unchecked'
            } todo-item__icon`,
            style: ``,
          },
          [
            'img',
            {
              src: CHECK_ICON,
            },
          ],
        ],
      ],
      [
        'div',
        { class: `${node.attrs.checked ? 'todo-item__text--checked' : ''}` },
        0,
      ],
    ];
  },
});

import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { templatesApi } from '../../../../shared/api/axios/templates';
import { teamsModel } from '../../../teams';
import {
  AnalyticsEvents,
  TeamFiltered,
  Template,
} from '@distribute/shared/types';
import { templatesModel } from '..';
import { logger } from '../../../../shared/lib';
import { setTemplatesData } from '../../lib';
import { analytics } from '../../../../entities/analytics';

export function* savePageAsTemplate({
  payload: { callback, ...payloadData },
}: ReturnType<typeof templatesModel.actions.savePageAsTemplate>) {
  try {
    yield put(templatesModel.actions.setSavePageAsTemplateIsLoading(true));

    const { id: teamId }: TeamFiltered = yield select(
      teamsModel.selectors.selectCurrentTeam
    );

    const template: Template = yield templatesApi.savePageAsTemplate({
      ...payloadData,
      teamId,
    });

    yield call(setTemplatesData);

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'The page has been saved as a Template!')
      )
    );

    analytics.track(AnalyticsEvents.PAGE_SAVED_AS_TEMPLATE, {
      pageId: payloadData.pageId,
      templateId: template.id,
      templateName: template.name,
    });
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to save the page as a Template')
      )
    );
  } finally {
    yield put(templatesModel.actions.setSavePageAsTemplateIsLoading(false));
    yield call(callback);
  }
}

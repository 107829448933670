import { RootState } from '../../../app';

export const selectTotal = ({
  pagesOverview: { totalPages, totalPageViews, totalLeads },
}: RootState) => ({
  totalPages,
  totalPageViews,
  totalLeads,
});

export const selectPagesOverviewData = ({
  pagesOverview: { data },
}: RootState) => data;
export const selectPagesOverviewMeta = ({
  pagesOverview: { meta },
}: RootState) => meta;

export const selectIsTotalLoading = ({
  pagesOverview: { isLoadingTotal },
}: RootState) => isLoadingTotal;
export const selectIsOverviewDataLoading = ({
  pagesOverview: { isLoadingData },
}: RootState) => isLoadingData;

export const selectSearch = ({ pagesOverview: { search } }: RootState) =>
  search;
export const selectOrderBy = ({ pagesOverview: { orderBy } }: RootState) =>
  orderBy;
export const selectFilterByUserId = ({
  pagesOverview: { filterByUserId },
}: RootState) => filterByUserId;

import { DevicePermission } from '../../../model/types';

export type PermissionsMachineContext = {
  videoPermissionStatus: PermissionStatus | null;
  videoPermission: DevicePermission;

  audioPermissionStatus: PermissionStatus | null;
  audioPermission: DevicePermission;
};

export const PermissionsMachineEvent = {
  UPDATE_VIDEO: 'UPDATE_VIDEO',
  UPDATE_AUDIO: 'UPDATE_AUDIO',
} as const;

//prettier-ignore
export type PermissionsMachineActions =
  | { type: (typeof PermissionsMachineEvent)['UPDATE_VIDEO']; data: DevicePermission }
  | { type: (typeof PermissionsMachineEvent)['UPDATE_AUDIO']; data: DevicePermission }

import { call, put, takeEvery } from 'redux-saga/effects';
import { getUserFromStore } from '../../../entities/auth-user';
import { navigate } from '../../../processes/navigation';
import { ONBOARDING_ROUTE } from '../../../entities/history';
import { sendEmailVerification } from '../../../features/auth/email-verification';
import { authModel } from '../../../entities/auth-operation';

export function* verifyEmailWorker() {
  yield takeEvery(authModel.actions.verifyEmailClick, function* () {
    yield call(sendEmailVerification);
  });

  const user = yield* getUserFromStore();

  if (user.isVerified) {
    yield put(navigate({ to: ONBOARDING_ROUTE }));
  }
}

import { put, takeLatest } from 'redux-saga/effects';
import { pagesOverviewModel } from '../../../features/pages-overview';

export function* pagesOverviewWorker() {
  yield takeLatest(
    pagesOverviewModel.actions.getPagesOverview,
    pagesOverviewModel.sagas.getPagesOverview
  );

  yield takeLatest(
    pagesOverviewModel.actions.getPagesTotal,
    pagesOverviewModel.sagas.getPagesTotal
  );

  yield put(pagesOverviewModel.actions.reset());
  yield put(pagesOverviewModel.actions.getPagesTotal());
  yield put(pagesOverviewModel.actions.getPagesOverview({}));
}

import { MAX_CHUNK_SIZE } from '../config';

export const concatArrayBuffers = (...bufs: ArrayBuffer[]) => {
  const result = new Uint8Array(
    bufs.reduce((totalSize, buf) => totalSize + buf.byteLength, 0)
  );
  bufs.reduce((offset, buf) => {
    result.set(new Uint8Array(buf), offset);
    return offset + buf.byteLength;
  }, 0);
  return result;
};

export const checkIsDivisible = (number: number) =>
  number % MAX_CHUNK_SIZE === 0;

export const getStartEndBytes = (position: number) => {
  return {
    start: MAX_CHUNK_SIZE * position,
    end: MAX_CHUNK_SIZE * (position + 1),
  };
};

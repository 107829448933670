import { RootState } from '../../../app';

export const selectIsLoadingData = ({
  analytics: { isLoadingData },
}: RootState) => isLoadingData;

export const selectTotal = ({ analytics: { total } }: RootState) => total;
export const selectEmptyState = ({ analytics: { emptyState } }: RootState) =>
  emptyState;

export const selectChartData = ({ analytics: { chartData } }: RootState) =>
  chartData;

export const selectIsAnalyticsDeleting = ({
  analytics: { isAnalyticsDeleting },
}: RootState) => isAnalyticsDeleting;

export const selectDateRange = ({ analytics: { dateRange } }: RootState) =>
  dateRange;
export const selectDateRangePeriod = ({
  analytics: { dateRangePeriod },
}: RootState) => dateRangePeriod;

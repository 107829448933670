import { Node, mergeAttributes } from '@tiptap/react';

export const TimelineExt = Node.create({
  name: 'timeline',
  group: 'block',
  content: 'timelineItem+',
  isolating: true,
  selectable: true,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options?.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
        class: 'timeline',
      }),
      0,
    ];
  },
});

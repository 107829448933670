import React, { FC } from 'react';
import { Avatar, Link, Tooltip } from '../../../../shared/ui';
import { PageOverview } from '@distribute/shared/types';
import moment from 'moment';
import { getFullPageUrl } from '../../../../shared/lib';
import { useSelector, useDispatch } from 'react-redux';
import { teamsModel } from '../../../../features/teams';
import { customDomainsModel } from '../../../../features/custom-domains';
import { authUserModel } from '../../../../entities/auth-user';
import { UserPermissionToPageRole } from '@distribute/shared/types';
import { navigate } from '../../../../processes/navigation';
import { generatePath } from 'react-router-dom';
import { EDITOR_PAGE_ROUTE } from '../../../../entities/history';
import { Emoji } from 'emoji-picker-react';
import { TrendBadge } from '../../../../features/pages-overview';

type Props = {
  item: PageOverview;
};

const ROLES_TO_VIEW_PAGE = [
  UserPermissionToPageRole.OWNER,
  UserPermissionToPageRole.VIEWER,
  UserPermissionToPageRole.EDITOR,
];

export const TableRow: FC<Props> = ({ item }) => {
  const dispatch = useDispatch();
  const { domain: currentTeamDomain } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const customDomain = useSelector(
    customDomainsModel.selectors.selectVerifiedCustomDomain
  );
  const currentUser = useSelector(authUserModel.selectors.selectUser);
  const currentUserRoleInPage = item.users.find(
    (user) => user.userId === currentUser?.id
  )?.role as UserPermissionToPageRole;
  const isUserAllowedToView = ROLES_TO_VIEW_PAGE.includes(
    currentUserRoleInPage
  );
  const pageOwner = item.users.find(
    (user) => user.role === UserPermissionToPageRole.OWNER
  );
  const sharedWith = item.users.filter(
    (user) => user.role !== UserPermissionToPageRole.OWNER
  );
  const [firstSharedUser, secondSharedUser, ...restSharedUsers] = sharedWith;
  const pathToPage = generatePath(EDITOR_PAGE_ROUTE, {
    sequenceNumber: item.sequenceNumber,
  });

  const pageLink = isUserAllowedToView
    ? pathToPage
    : getFullPageUrl({
        slug: item.pageSlug,
        domain: currentTeamDomain,
        customDomain,
      });
  const linkTarget = isUserAllowedToView ? '_self' : '_blank';
  const isPageVisible = isUserAllowedToView || item.published;

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isUserAllowedToView) {
      e.preventDefault();
      dispatch(navigate({ to: pathToPage }));
    }
  };
  const pageTitle = item.title || 'Untitled';

  return (
    <tr className="border-b border-gray-200 text-left">
      <td className="p-4">
        <div className="flex items-center gap-3 max-w-[400px]">
          {item.logo ? (
            <Avatar
              src={item.logo}
              displayName={item.title}
              className="w-10 h-10 rounded-md"
            />
          ) : null}
          {!item.logo && item.icon ? (
            <span className="block border border-base-black-8 rounded-md w-10 h-10 p-2">
              <Emoji unified={item.icon} size={20} />
            </span>
          ) : null}
          {!isPageVisible ? (
            <Tooltip
              className="w-43.5 px-3 py-2"
              hideArrow
              trigger={
                <span className="text-gray-900 hover:underline">
                  {pageTitle}
                </span>
              }
            >
              <p className="text-sm text-white font-semibold">
                You cannot open this page unless it is published or shared with
                you.
              </p>
            </Tooltip>
          ) : (
            <Link
              target={linkTarget}
              rel="noreferrer"
              href={pageLink}
              size="medium"
              isDisabled={!isPageVisible}
              onClick={handleLinkClick}
            >
              <span className="text-gray-900 hover:underline">{pageTitle}</span>
            </Link>
          )}
        </div>
      </td>
      <td>
        <TrendBadge trend={item.trend} isPublic={item.published} />
      </td>
      <td className="p-4">{item.views}</td>
      <td className="p-4">{item.uniqViews}</td>
      <td className="p-4">
        <Tooltip
          className="px-3 py-2"
          hideArrow
          trigger={
            <Avatar
              isUserAvatar
              src={pageOwner?.photoUrl}
              displayName={pageOwner?.name ?? ''}
              size="xs"
              className="w-8 h-8"
            />
          }
        >
          <p>
            <span className="font-semibold">{pageOwner?.name} </span>
            {`(${pageOwner?.email})`}
          </p>
        </Tooltip>
      </td>
      <td className="p-4">
        <div className="flex items-center gap-0.5 relative">
          {firstSharedUser && (
            <Tooltip
              className="px-3 py-2"
              hideArrow
              trigger={
                <Avatar
                  className="border border-black-8"
                  isUserAvatar
                  src={firstSharedUser.photoUrl}
                  displayName={firstSharedUser.name}
                  size="xs"
                />
              }
            >
              <p>
                <span className="font-semibold">{firstSharedUser.name} </span>
                {`(${firstSharedUser.email})`}
              </p>
            </Tooltip>
          )}
          {secondSharedUser && (
            <span className="block absolute z-1 border-1.5 border-base-black-8 left-[20px] border-base-white w-7 h-7 rounded-full">
              <Tooltip
                className="px-3 py-2"
                hideArrow
                trigger={
                  <Avatar
                    className="border border-black-8"
                    key={secondSharedUser.id}
                    src={secondSharedUser.photoUrl}
                    displayName={secondSharedUser.name}
                    size="xs"
                  />
                }
              >
                <p>
                  <span className="font-semibold">
                    {secondSharedUser.name}{' '}
                  </span>
                  {`(${secondSharedUser.email})`}
                </p>
              </Tooltip>
            </span>
          )}
          {restSharedUsers.length > 0 ? (
            <span className="block absolute z-2 border-1.5 border-base-black-8 left-[40px] border-base-white w-7 h-7 rounded-full">
              <Tooltip
                className="px-3 py-2"
                hideArrow
                trigger={
                  <Avatar
                    isUserAvatar
                    className="border border-black-8"
                    key="random"
                    src=""
                    displayName={`+ ${restSharedUsers.length}`}
                    size="xs"
                  />
                }
              >
                {restSharedUsers.map((user) => (
                  <p key={user.id}>
                    <span className="font-semibold">{user.name} </span>
                    {`(${user.email})`}
                  </p>
                ))}
              </Tooltip>
            </span>
          ) : null}
        </div>
      </td>
      <td className="p-4 whitespace-nowrap">
        <div>
          {item.lastViewed
            ? moment(item.lastViewed).format('MMM D, YYYY, h:mm A')
            : '-'}
        </div>
      </td>
    </tr>
  );
};

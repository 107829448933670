import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { selectCurrentTeamWithError } from '../../teams/model/selectors';
import { FREE_SUBSCRIPTION_PLAN_ID } from '../config';
import { subscriptionLimits } from '@distribute/shared/types';

export const selectSubscriptionLimits = createSelector(
  [selectCurrentTeamWithError],
  (team) =>
    subscriptionLimits[
      team?.stripeSubscription?.name ?? FREE_SUBSCRIPTION_PLAN_ID
    ]
);

export const selectIsUpdatingPaymentMethod = ({
  subscription: { isUpdatingPaymentMethod },
}: RootState) => isUpdatingPaymentMethod;

export const selectIsUpdatingSubscription = ({
  subscription: { isUpdatingSubscription },
}: RootState) => isUpdatingSubscription;

export const selectUpcomingInvoiceInfo = ({
  subscription: { upcomingInvoiceInfo },
}: RootState) => upcomingInvoiceInfo;

export const selectIsLoadingUpcomingInvoice = ({
  subscription: { isLoadingUpcomingInvoice },
}: RootState) => isLoadingUpcomingInvoice;

export const selectUpdatePaymentMethodError = ({
  subscription: { updatePaymentMethodError },
}: RootState) => updatePaymentMethodError;

export const selectIsCancelingSubscription = ({
  subscription: { isCancelingSubscription },
}: RootState) => isCancelingSubscription;

export const selectIsRetryingLatestFailedInvoice = ({
  subscription: { isRetryingLatestFailedInvoice },
}: RootState) => isRetryingLatestFailedInvoice;

export const selectIsShowPlanReachedMembersLimitModal = ({
  subscription: { isShowPlanReachedMembersLimitModal },
}: RootState) => isShowPlanReachedMembersLimitModal;

export const selectIsLoadingInvoices = ({
  subscription: { isLoadingInvoices },
}: RootState) => isLoadingInvoices;

export const selectInvoices = ({ subscription: { invoices } }: RootState) =>
  invoices;

export const selectIsConfirmingPaymentMethod = ({
  subscription: { isConfirmingPaymentMethod },
}: RootState) => isConfirmingPaymentMethod;

export const selectIsShowPaywallModal = ({
  subscription: { isShowPaywallModal },
}: RootState) => isShowPaywallModal;

export const selectIsShowUpdatePaymentMethodModal = ({
  subscription: { isShowUpdatePaymentMethodModal },
}: RootState) => isShowUpdatePaymentMethodModal;

export const selectIsShowThanksConfirmPaymentMethod = ({
  subscription: { isShowThanksConfirmPaymentMethod },
}: RootState) => isShowThanksConfirmPaymentMethod;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { GettingStarted } from '../../../features/getting-started';
import { useWindowWidth } from '@distribute/frontend/utils';
import { useDispatch } from 'react-redux';
import { redirectActions } from '../../../entities/history';
import { useUserAgentDevices } from '../../../shared/hooks/useUserAgentDevices';

export const GettingStartedPage = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowWidth();
  const { isTablet } = useUserAgentDevices();

  useEffect(() => {
    if (isMobile || isTablet) {
      dispatch(redirectActions.toWorkspace());
    }
  }, [isMobile, isTablet, dispatch]);

  return (
    <>
      <Helmet title="Getting started" />
      <GettingStarted />
    </>
  );
};

import { ContactType, DeserializedContact } from './types';

export function serializeContacts(contacts: DeserializedContact[]): string {
  contacts.sort((a, b) => a.order - b.order);

  return JSON.stringify(contacts);
}

export function deserializeContacts(
  serializedContacts: string
): DeserializedContact[] {
  try {
    const deserializedContacts = JSON.parse(
      serializedContacts
    ) as DeserializedContact[];

    deserializedContacts.sort((a, b) => a.order - b.order);

    deserializedContacts.forEach((contact, index) => {
      contact.order = index;
    });

    return deserializedContacts;
  } catch (error) {
    return [];
  }
}

export function addNewEmptyContact(
  serializedContacts: string,
  type: ContactType
) {
  const contacts = deserializeContacts(serializedContacts);

  const newContact: DeserializedContact = {
    order: contacts.length,
    type,
    value: '',
    id: Date.now(),
  };

  contacts.push(newContact);

  return serializeContacts(contacts);
}

export function removeContact(serializedContacts: string, id: number) {
  const contacts = deserializeContacts(serializedContacts);

  const filteredContacts = contacts.filter((c) => c.id !== id);

  return serializeContacts(filteredContacts);
}

export function updateContact(
  serializedContacts: string,
  id: number,
  updatedContact: Partial<DeserializedContact>
) {
  const contacts = deserializeContacts(serializedContacts);

  const contactIndex = contacts.findIndex((c) => c.id === id);

  contacts[contactIndex] = {
    ...contacts[contactIndex],
    ...updatedContact,
  };

  return serializeContacts(contacts);
}

import { ContactType } from './types';

export function linkifyContact(type: ContactType, value: string) {
  if (type === 'email') {
    return `mailto:${value}`;
  }

  if (type === 'phone') {
    return `tel:${value
      .replace(/\s/g, '')
      .replace(/-/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')}`;
  }

  return value;
}

import { call, put, take } from 'redux-saga/effects';
import {
  APP_HOST,
  APP_ORIGIN,
  LINKEDIN_CLIENT_ID,
  PROTOCOL,
} from '../../../../../shared/config';
import { v4 } from 'uuid';

import { END, eventChannel } from 'redux-saga';
import {
  createNotification,
  snackbarModel,
} from '../../../../../features/snackbar';
import { fetchLinkedinAuthTokenBasedOnCode } from './fetchLinkedinAuthTokenBasedOnCode';
import { socialModel } from '../..';
import { openOauthSignupPopup } from '../../../../../utils/openOauthSignupPopup';

const scope = 'openid profile w_member_social email';
const linkedinCallbackUrl = `${PROTOCOL}${APP_HOST}/linkedin-callback`;

export function* connectToLinkedin(): any {
  yield put(socialModel.actions.setIsConnectingToLinkedin(true));
  const state = v4();

  const signUpPopup = openOauthSignupPopup(
    'https://www.linkedin.com/oauth/v2/authorization',
    {
      response_type: 'code',
      client_id: LINKEDIN_CLIENT_ID,
      redirect_uri: linkedinCallbackUrl,
      state,
      scope,
    }
  );

  const callbackUrlChannel = yield call(
    checkConnectionModalForAuthCode,
    signUpPopup
  );

  let callbackUrl = '';

  try {
    while (true) {
      callbackUrl = yield take(callbackUrlChannel);
      if (callbackUrl) break;
    }
    callbackUrlChannel.close();
    yield call(onCallbackUrlSet, callbackUrl, state);
  } finally {
    callbackUrlChannel.close();
    yield put(socialModel.actions.setIsConnectingToLinkedin(false));
  }
}

function* onCallbackUrlSet(callbackUrl: string, state: string) {
  try {
    yield call(fetchLinkedinAuthTokenBasedOnCode, callbackUrl, state);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Successfully connected to LinkedIn')
      )
    );
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to connect to LinkedIn')
      )
    );
  } finally {
    yield put(socialModel.actions.setIsConnectingToLinkedin(false));
  }
}

function checkConnectionModalForAuthCode(signUpPopup: Window | null) {
  return eventChannel((emitter) => {
    const checkSignUpPopupInterval = setInterval(() => {
      if (!signUpPopup || signUpPopup?.closed) {
        emitter(END);
        return;
      }
      try {
        if (signUpPopup?.window.location?.href.includes(APP_ORIGIN)) {
          emitter(signUpPopup.window.location.search);
          signUpPopup.close();
        }
      } catch {
        // nothing to do
      }
    }, 1000);

    return () => {
      clearInterval(checkSignUpPopupInterval);
    };
  });
}

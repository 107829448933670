import { MouseEvent, PropsWithChildren, forwardRef } from 'react';

import cn from 'classnames';

type Props = {
  size?: keyof (typeof styles)['sizes'];
  variant?: keyof (typeof styles)['variants'];
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => unknown;
  title?: string;
  className?: string;
  id?: string;
};

export const stylesBaseButtonIcon =
  'flex items-center justify-center shrink-0 disabled:cursor-not-allowed overflow-hidden focus:outline-none';

const styles = {
  variants: {
    secondary: {
      base: 'rounded-md text-base-white hover:bg-base-white/[0.06] focus:bg-base-white/[0.06]',
      disabled: 'disabled:bg-transparent disabled:text-gray-500',
    },
    destructive: {
      base: 'rounded-md text-error-500 hover:bg-base-white/[0.06] focus:bg-base-white/[0.06]',
      disabled: 'disabled:bg-transparent',
    },
  },
  sizes: {
    sm: 'w-8 h-8',
  },
};

export const NarrationToolbarButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<Props>
>(
  (
    {
      children,
      disabled,
      onClick,
      title,
      className,
      size = 'sm',
      variant = 'secondary',
      id,
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type="button"
        id={id}
        disabled={disabled}
        title={title}
        onClick={onClick}
        className={cn(
          stylesBaseButtonIcon,
          styles.variants[variant].base,
          {
            [styles.variants[variant].disabled]: disabled,
          },
          styles.sizes[size],
          className
        )}
      >
        {children}
      </button>
    );
  }
);

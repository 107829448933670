import { generateTimelineDateWrapper } from './lib/generateTimelineDateWrapper';

export const addTimelineDueDate = () => {
  const timelineItems = document.querySelectorAll('.timeline-item');

  for (let i = 0; i < timelineItems.length; i++) {
    const timelineItem = timelineItems[i];

    const timelineContentWrapper = timelineItem.querySelector(
      '.timeline-item__content-wrapper'
    );

    const dateWrapper = document.createElement('div');
    dateWrapper.classList.add('timeline-item__due-date-wrapper');

    const dueDate = timelineItem.getAttribute('data-date');
    if (dueDate) {
      dateWrapper.innerHTML = generateTimelineDateWrapper(dueDate);
    }

    if (timelineContentWrapper) {
      timelineContentWrapper.appendChild(dateWrapper);
    }
  }
};

import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { VideoRecordType } from '@distribute/shared/api-types/video-record';

import { authUserModel } from '../../../entities/auth-user';

import { useRecordPermissions } from './use-record-permissions';

import { useCurrentPlan } from '../../subscription/hooks';

import { IconMap } from '../../../shared/sprite';
import { createRecordLink } from '../lib/record-link';
import { actions } from '../model/reducer';
import { createNotification, snackbarModel } from '../../snackbar';
import { messages } from '../config';

import {
  subscriptionLimits,
  VideoRecordStatusMp4,
} from '@distribute/shared/types';

import { subscriptionModel } from '../../subscription';
import { teamsModel } from '../../teams';

type Props = {
  record: VideoRecordType;
  isShowCopyLink?: boolean;
  isShowRename?: boolean;
};

export const useRecordDropdownItems = ({
  record,
  isShowCopyLink = false,
  isShowRename = false,
}: Props) => {
  const dispatch = useDispatch();

  const {
    teamId,
    prefix,
    videoQuality,
    teamMembersPermission,
    userPermissionToVideoRecords,
    statusMp4,
    id,
  } = record;

  const user = useSelector(authUserModel.selectors.selectUserWithError);
  const permissions = useRecordPermissions({
    userId: user.id,
    userPermissionToVideoRecords,
    teamMembersPermission,
  });
  const teamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );
  const isShowShare = teamMembers.length > 1;

  const plan = useCurrentPlan();

  const recordDropdownItems = useMemo(
    () =>
      [
        {
          id: 'share',
          label: 'Share',
          onClick: () => dispatch(actions.setRecordIdToShare(id)),
          iconName: IconMap.Share07,
          iconWidth: 16,
          isShow: permissions.isCanShare && isShowShare,
          isSeparatedFromTop: false,
        },
        {
          id: 'rename',
          label: 'Rename',
          onClick: () => dispatch(actions.setRecordIdToEdit(id)),
          iconName: IconMap.Edit02,
          iconWidth: 16,
          isShow: permissions.isCanEdit && isShowRename,
          isSeparatedFromTop: false,
        },
        {
          id: 'copyLink',
          label: 'Copy Link',
          onClick: () => {
            navigator.clipboard.writeText(createRecordLink(prefix));
            dispatch(
              snackbarModel.actions.addNotificationAction(
                createNotification('success', messages.record.copyLink)
              )
            );
          },
          iconName: IconMap.Link,
          iconWidth: 16,
          isShow: isShowCopyLink,
          isSeparatedFromTop: false,
        },
        {
          id: 'download',
          label: 'Download',
          onClick: () => {
            const { allowVideoDownload } = subscriptionLimits[plan.id];

            if (!allowVideoDownload) {
              dispatch(subscriptionModel.actions.setIsShowPaywallModal(true));
              return;
            }

            if (statusMp4 === VideoRecordStatusMp4.FAILED) {
              dispatch(
                snackbarModel.actions.addNotificationAction(
                  createNotification('error', messages.record.downloadError)
                )
              );
              return;
            }

            dispatch(actions.downloadRecord({ teamId, prefix, videoQuality }));
          },
          iconName: IconMap.Download,
          iconWidth: 16,
          isShow: permissions.isCanDownload,
          isSeparatedFromTop: false,
          disabled: [
            VideoRecordStatusMp4.IDLE,
            VideoRecordStatusMp4.PREPARING,
          ].includes(statusMp4),
        },
        {
          id: 'delete',
          label: 'Delete',
          onClick: () => dispatch(actions.setRecordIdToDelete(id)),
          iconName: IconMap.Delete,
          iconWidth: 16,
          isShow: permissions.isCanDelete,
          isSeparatedFromTop: false,
        },
      ]
        .filter((i) => i.isShow)
        .map(
          (item, idx, arr) =>
            idx === 1
              ? { ...item, isSeparatedFromTop: arr[0].id === 'share' }
              : item,
          []
        ),

    //eslint-disable-next-line
    [
      permissions,
      prefix,
      id,
      teamId,
      videoQuality,
      isShowCopyLink,
      plan,
      statusMp4,
      isShowShare,
      isShowRename,
    ]
  );

  return recordDropdownItems;
};

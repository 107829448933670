import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChartData, EmptyState, Total } from './types';
import { globalActions } from '../../../app/model/actions';
import {
  DateRange,
  DateRangePeriod,
  getRangeByPeriod,
} from '../../../shared/ui/date-range-picker';

type State = {
  isLoadingData: boolean;
  total: Total;
  emptyState: EmptyState;
  chartData: ChartData;
  isAnalyticsDeleting: boolean;
  dateRange: DateRange;
  dateRangePeriod: DateRangePeriod;
};

const initialState: State = {
  isLoadingData: false,
  isAnalyticsDeleting: false,
  total: {
    pageViews: 0,
    uniqueVisitors: 0,
    conversions: 0,
    conversionRate: '-',
    averageTimeOnPage: '-',
    averageTimeToConvert: '-',
  },
  emptyState: {
    pageViews: true,
    uniqueVisitors: true,
    conversions: true,
    conversionRate: true,
    averageTimeOnPage: true,
    averageTimeToConvert: true,
  },
  chartData: [],
  dateRange: getRangeByPeriod('month'),
  dateRangePeriod: 'month',
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setIsLoadingData: (
      state,
      { payload: isLoadingData }: PayloadAction<boolean>
    ) => ({
      ...state,
      isLoadingData,
    }),
    setIsAnalyticsDeleting: (
      state,
      { payload: isAnalyticsDeleting }: PayloadAction<boolean>
    ) => ({
      ...state,
      isAnalyticsDeleting,
    }),
    setTotal: (state, { payload: total }: PayloadAction<Total>) => ({
      ...state,
      total,
    }),
    setChartData: (
      state,
      { payload: chartData }: PayloadAction<ChartData>
    ) => ({
      ...state,
      chartData,
    }),
    setEmptyState: (
      state,
      { payload: emptyState }: PayloadAction<EmptyState>
    ) => ({
      ...state,
      emptyState,
    }),
    setDateRange: (
      state,
      { payload: dateRange }: PayloadAction<State['dateRange']>
    ) => ({
      ...state,
      dateRange,
    }),
    setDateRangePeriod: (
      state,
      { payload: dateRangePeriod }: PayloadAction<State['dateRangePeriod']>
    ) => ({
      ...state,
      dateRangePeriod,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  resetAnalytics: createAction<{ cb: () => void }>('analytics/resetAnalytics'),
  onChangeRange: createAction<State['dateRange']>('analytics/onChangeRange'),
};

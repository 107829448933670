import { RT } from '../../../shared/types';
import { videoNarrationApi } from '../../../shared/api';
import { call, put } from 'redux-saga/effects';
import { narrationModel } from '../model';
import { logger } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';

export function* setNarrationData(pageId: string) {
  try {
    const narration: RT<typeof videoNarrationApi.getLast> = yield call(
      videoNarrationApi.getLast,
      { pageId }
    );

    yield put(narrationModel.actions.setNarration(narration));
  } catch (e: unknown) {
    yield put(narrationModel.actions.setNarration(null));
    logger.error(e);
    //prettier-ignore
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', 'Failed to show narration')));
  }
}

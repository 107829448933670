import { useMemo } from 'react';
import { usePlayer } from './use-player';

import { Size } from '../config';

export const useSize = () => {
  const { config } = usePlayer();

  const size = useMemo(() => {
    const width = config.entry?.contentRect?.width || 0;

    return width > 640 ? Size.MEDIUM : Size.SMALL;
  }, [config?.entry]);

  return { size };
};

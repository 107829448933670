import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SimpleLayout } from '../../shared/ui';

export const ExpiredInvitationPage = () => {
  return (
    <>
      <Helmet title="Invitation link has expired" />
      <SimpleLayout>
        <p className="mb-6 text-display-md font-medium font-display text-gray-900 text-center">
          Sorry, your invitation link has expired.
        </p>
        <p className="font-normal text-md text-center text-gray-600">
          Please link up with your inviter to get another invitation link.
        </p>
      </SimpleLayout>
    </>
  );
};

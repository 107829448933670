import { DevicePermission } from '../../../model/types';

type Params = {
  videoPermission: DevicePermission;
  audioPermission: DevicePermission;
};

export const executeDevicesPermission = async ({
  videoPermission,
  audioPermission,
}: Params) => {
  const isVideoInit = DevicePermission.PROMPT === videoPermission;
  const isAudioInit = DevicePermission.PROMPT === audioPermission;

  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: isVideoInit,
      audio: isAudioInit,
    });

    stream.getTracks().forEach((track) => track.stop());

    return {
      videoPermission: isVideoInit ? DevicePermission.GRANTED : videoPermission,
      audioPermission: isAudioInit ? DevicePermission.GRANTED : audioPermission,
    };
  } catch (e: unknown) {
    return {
      videoPermission: isVideoInit ? DevicePermission.DENIED : videoPermission,
      audioPermission: isAudioInit ? DevicePermission.DENIED : audioPermission,
    };
  }
};

import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import cn from 'classnames';

import { PlayerBubbleLoader } from '@distribute/frontend/ui/player';

import { narrationModel } from '../../model';
import { logger } from '../../../../shared/lib';

import { getVideoMedia } from '../narration-recorder/lib';

export const NarrationBubbleCamera: FC = () => {
  const [loading, setLoading] = useState(true);

  const videoRef = useRef<HTMLVideoElement>(null);

  const isNarrationRecordingSessionActive = useSelector(
    narrationModel.selectors.checkIsNarrationRecordingSessionActive
  );

  const { videoId } = useSelector(narrationModel.selectors.selectNarration);

  const isNoVideo = useSelector(narrationModel.selectors.checkIsNoVideo);
  const isVideoPermissionInActive = useSelector(
    narrationModel.selectors.checkIsVideoPermissionInActive
  );

  useEffect(() => {
    let promise: Promise<MediaStream | null> = Promise.resolve(null);

    setLoading(true);

    const getCamera = async () => {
      try {
        promise = getVideoMedia(videoId);
        const stream = await promise;

        videoRef.current && (videoRef.current.srcObject = stream);
      } catch (e: unknown) {
        logger.error(`[Get Camera]: ${e}`);
      }
    };

    if (isNoVideo || isVideoPermissionInActive) return;

    getCamera();

    return () => {
      promise
        .then((stream) => stream?.getTracks().forEach((track) => track.stop()))
        .catch(() => ({}));
    };
  }, [isNoVideo, isVideoPermissionInActive, videoId]);

  return (
    <div
      className={cn(
        'overflow-hidden rounded-xl relative transition-all duration-150',
        {
          'w-56 h-56': !isNarrationRecordingSessionActive,
          'w-60 h-60': isNarrationRecordingSessionActive,
        }
      )}
    >
      <div className="absolute inset-0">
        <PlayerBubbleLoader />
      </div>
      <video
        className={cn('h-full object-cover scale-x-[-1]', {
          'opacity-0': loading,
        })}
        ref={videoRef}
        onLoadedMetadata={() => setLoading(false)}
        autoPlay
        muted
      />
    </div>
  );
};

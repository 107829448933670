import { teamsApi } from '../../../../shared/api';
import { logger, matchParams } from '../../../../shared/lib';
import { call, put } from 'redux-saga/effects';
import {
  ACCEPT_PAGE_INVITATION_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  history,
} from '../../../../entities/history';
import { RouteParams } from '../types';
import { PageInvitationTeamMemberInfoResponseType } from '@distribute/shared/api-types/team';
import { authModel } from '../../../../entities/auth-operation';
import { navigate } from '../../../../processes/navigation';
import {
  QUERY_PARAM_PAGE_INVITE_ID,
  QUERY_PARAM_TEAM_INVITE_ID,
} from '../../config';
import { setParams } from '@distribute/shared/utils';

export function* handleAcceptPageInvitationSignedOut() {
  try {
    const { pathname } = history.location;
    const params = matchParams<RouteParams>(
      pathname,
      ACCEPT_PAGE_INVITATION_ROUTE
    );

    if (!params) return;

    const {
      isUserExistsInSystem,
      email,
    }: PageInvitationTeamMemberInfoResponseType = yield call(
      teamsApi.pageInvitationTeamMemberInfo,
      params.pageInviteId
    );

    yield put(authModel.actions.setEmailFromTeamOrPageInvitationLink(email));

    yield put(
      navigate({
        to: `${isUserExistsInSystem ? LOGIN_ROUTE : REGISTER_ROUTE}${setParams({
          [QUERY_PARAM_PAGE_INVITE_ID]: params.pageInviteId,
          [QUERY_PARAM_TEAM_INVITE_ID]: params.teamInviteId,
        })}`,
      })
    );
  } catch (e) {
    logger.error(e);
    return;
  }
}

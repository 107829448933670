import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { teamsModel, useTeamPermissions } from '../../../teams';
import { teamBrandSettingsModel } from '../../model';
import { Button, ChangeLogoForm, FormField } from '../../../../shared/ui';
import { CompanyNameSearchInput } from './CompanyNameSearchInput';
import { ClearbitCompanyInfo } from '../../../../shared/api';
import { useConfirmWindowClose } from '../../../../shared/hooks/useConfirmWindowClose';
import { Input } from '../../../../shared/ui';

export const ChangeBrandInfoForm: FC = () => {
  const dispatch = useDispatch();
  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const isUpdatingBrand = useSelector(
    teamBrandSettingsModel.selectors.selectIsUpdatingBrandInfo
  );

  const { isCanEdit } = useTeamPermissions();

  const [companyName, setCompanyName] = useState(currentTeam.brandCompanyName);
  const [companyLogo, setCompanyLogo] = useState(currentTeam.brandLogoUrl);
  const [companyInfo, setCompanyInfo] = useState(currentTeam.brandCompanyInfo);
  const [companyDomain, setCompanyDomain] = useState<string | null>(null);

  const isFormChanged =
    companyName !== currentTeam.brandCompanyName ||
    companyLogo !== currentTeam.brandLogoUrl ||
    companyInfo !== currentTeam.brandCompanyInfo;

  const handleCompanySelect = (company: ClearbitCompanyInfo) => {
    setCompanyName(company.name);
    setCompanyLogo(company.logo ?? '');
    setCompanyDomain(company.domain);
  };

  const isUploading = useSelector(
    teamBrandSettingsModel.selectors.selectIsUploadLogoLoading
  );
  const isRemoving = useSelector(
    teamBrandSettingsModel.selectors.selectIsRemoveLogoLoading
  );
  const uploadProgress = useSelector(
    teamBrandSettingsModel.selectors.selectUploadingLogoProgress
  );

  const handleChangeLogo = (file: File) => {
    dispatch(
      teamBrandSettingsModel.actions.changeLogo({
        file,
        teamId: currentTeam.id,
      })
    );
  };

  const handleDeleteLogo = () => {
    if (currentTeam.brandLogoUrl !== companyLogo) {
      setCompanyLogo(null);
    } else {
      dispatch(
        teamBrandSettingsModel.actions.deleteLogo({ teamId: currentTeam.id })
      );
    }
  };

  const handleCancelUploadPhoto = () => {
    dispatch(teamBrandSettingsModel.actions.cancelUploadingFile());
  };

  const handleSaveBrand = () => {
    dispatch(
      teamBrandSettingsModel.actions.updateTeamBrandInfo({
        teamId: currentTeam.id,
        brandLogoUrl: companyLogo,
        brandCompanyName: companyName,
        brandCompanyInfo: companyInfo,
        companyDomain,
      })
    );
  };

  useEffect(() => {
    setCompanyLogo(currentTeam.brandLogoUrl);
  }, [currentTeam.brandLogoUrl]);

  useConfirmWindowClose(isFormChanged, 'changeTeamBrand');

  return (
    <div className="flex flex-col gap-y-5">
      <ChangeLogoForm
        name={currentTeam.brandCompanyName ?? ''}
        logoUrl={companyLogo}
        isUploading={isUploading}
        isRemoving={isRemoving}
        uploadProgress={uploadProgress}
        handleChangeLogo={handleChangeLogo}
        handleDeleteLogo={handleDeleteLogo}
        handleCancelUploadPhoto={handleCancelUploadPhoto}
        hideIconIfEmpty
      />
      <FormField label="Company name">
        <CompanyNameSearchInput
          value={companyName}
          onChange={(value: string) => {
            setCompanyName(value);
            setCompanyDomain(null);
          }}
          handleCompanySelect={handleCompanySelect}
          disabled={!isCanEdit}
        />
      </FormField>
      <FormField label="About company">
        <Input
          isTextArea
          counterValue={companyInfo?.length ?? 0}
          isCounterOnly
          isCounterInside
          textAreaRows={12}
          type="text"
          value={companyInfo ?? ''}
          onChange={(e) => setCompanyInfo(e.target.value)}
          className="w-full rounded-md border-gray-300 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
          disabled={!isCanEdit}
        />
      </FormField>
      {isCanEdit && (
        <Button
          variant="text"
          color="primary"
          disabled={isUpdatingBrand || !isFormChanged}
          loading={isUpdatingBrand}
          type="submit"
          className="self-start sm:w-full"
          onClick={handleSaveBrand}
        >
          Save
        </Button>
      )}
    </div>
  );
};

import {
  VideoNarrationDeleteParamsType,
  VideoNarrationDetailType,
  VideoNarrationGetLastParamsType,
  VideoNarrationGetParamsType,
} from '@distribute/shared/api-types/video-narration';

import { api } from './instance';

export const videoNarrationApi = {
  async getLast({ pageId }: VideoNarrationGetLastParamsType) {
    const { data: responseData } = await api.get(
      `video-narration/${pageId}/last`
    );

    return responseData as VideoNarrationDetailType;
  },

  async get({ pageId, id }: VideoNarrationGetParamsType) {
    const { data: responseData } = await api
      .get(`video-narration/${pageId}/${id}`)
      .catch(() => ({ data: {} }));

    return responseData as VideoNarrationDetailType;
  },

  async delete({ pageId, id }: VideoNarrationDeleteParamsType) {
    await api.delete(`video-narration/${pageId}/${id}`);
  },
};

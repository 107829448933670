import { ContactCardAttrs } from '@distribute/shared/generate-html';
import { createContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

export interface ContactCardEditorContext {
  form: UseFormReturn<ContactCardAttrs, unknown>;
}

export default createContext<ContactCardEditorContext>(
  {} as ContactCardEditorContext
);

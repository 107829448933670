import { NodeViewProps, ReactNodeViewRenderer } from '@tiptap/react';
import { CustomTaskListNodeView } from './CustomTaskList.renderer';
import { CustomTaskListExt } from '@distribute/shared/generate-html';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customTaskList: {
      insertCustomTaskList: () => ReturnType;
    };
  }
}
export type IProps = NodeViewProps;

export const CustomTaskList = CustomTaskListExt.extend({
  addCommands() {
    return {
      insertCustomTaskList:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'customTaskList',
            content: [
              {
                type: 'customTaskListItem',
                content: [
                  {
                    type: 'paragraph',
                  },
                ],
              },
            ],
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomTaskListNodeView);
  },
});

import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMachine } from '@xstate/react';

import { DevicePermission } from '../../model/types';

import { permissionsMachine, PermissionsMachineEvent } from './machine';
import { getDevices } from './lib';
import { narrationModel } from '../../model';

export const NarrationPermission: FC = () => {
  const dispatch = useDispatch();

  const [_, send, machineRef] = useMachine(permissionsMachine);

  const onUpdateVideoState = useCallback(
    (videoPermission: DevicePermission) =>
      getDevices().then(({ videos }) =>
        dispatch(
          narrationModel.actions.updateVideo({
            videoPermission,
            videos,
          })
        )
      ),
    []
  );

  const onUpdateAudioState = useCallback(
    (audioPermission: DevicePermission) =>
      getDevices().then(({ audios }) =>
        dispatch(
          narrationModel.actions.updateAudio({
            audioPermission,
            audios,
          })
        )
      ),
    []
  );

  useEffect(() => {
    const sub = machineRef.subscribe((snap) => {
      if (snap.event.type === PermissionsMachineEvent.UPDATE_VIDEO) {
        onUpdateVideoState(snap.event.data);
        return;
      }

      if (snap.event.type === PermissionsMachineEvent.UPDATE_AUDIO) {
        onUpdateAudioState(snap.event.data);
        return;
      }
    });

    return sub.unsubscribe;
  }, []);

  return null;
};

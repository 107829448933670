import React, { createContext, useContext } from 'react';
import { LineActionMenuItem } from './types';

interface LineActionMenuContextProps {
  menuItems: LineActionMenuItem[];
}

type LineActionMenuContextProviderProps = {
  menuItems?: LineActionMenuItem[];
  children: React.ReactNode;
  isTemplateMode?: boolean;
};

const LineActionMenuContext = createContext<
  LineActionMenuContextProps | undefined
>(undefined);

export const useLineActionMenuContext = () => {
  const context = useContext(LineActionMenuContext);
  if (!context) {
    throw new Error(
      'useLineActionMenuContext must be used within a LineActionMenuProvider'
    );
  }
  return context;
};

export const LineActionMenuProvider: React.FC<
  LineActionMenuContextProviderProps
> = ({ isTemplateMode = false, menuItems = [], children }) => {
  const items = isTemplateMode ? [] : menuItems;

  return (
    <LineActionMenuContext.Provider value={{ menuItems: items }}>
      {children}
    </LineActionMenuContext.Provider>
  );
};

import { FC, RefObject, useEffect } from 'react';
import { Icon, Icons } from '../../../icons';
import { checkIsIOS } from '../../../lib';
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull';

import ReactPlayer from 'react-player';

import cn from 'classnames';

import { PlayerTooltip } from './PlayerTooltip';
import { PlayerButton } from './PlayerButton';
import { PlayerPlaybackRate } from './PlayerPlaybackRate';
import { PlayerVolume } from './PlayerVolume';
import { PlayerProgress } from './PlayerProgress';
import { PlayerKey } from './PlayerKey';

import { type } from '../../../config';
import { PlayerDuration } from './PlayerDuration';

import { usePlayer, usePlayerActions, useSize } from '../../../hooks';

type Props = {
  playerRef: RefObject<ReactPlayer>;
};

const styles = {
  sizes: {
    sm: 'h-14 px-2.5 pb-1.5',
    md: 'h-16 px-4 pb-2.5',
    lg: 'h-24 px-6 pb-4',
  },
} as const;

export const PlayerControls: FC<Props> = ({ playerRef }) => {
  const { state, config } = usePlayer();

  const { handleSetFullscreen, handleToggle, handleRetry } = usePlayerActions();

  const { size } = useSize();

  const { isPlaying, isFullscreen, isFinished } = state;
  const { wrapperRef, hotKeys } = config;

  const player = playerRef.current?.getInternalPlayer() as
    | HTMLVideoElement
    | undefined;

  const onChangeFullscreen = () => {
    screenfull.isEnabled &&
      screenfull.toggle(findDOMNode(wrapperRef.current) as Element);
  };

  useEffect(() => {
    if (!player) return;

    // Iphone issue with controls. See here: https://github.com/videojs/video.js/issues/3762
    const handler = () => player.removeAttribute('controls');

    player.addEventListener('webkitendfullscreen', handler);
    return () => player.removeEventListener('webkitendfullscreen', handler);
  }, [player]);

  useEffect(() => {
    const handler = (event: Event) => {
      const target = event?.target as HTMLElement;

      target?.dataset?.['type'] === type &&
        handleSetFullscreen(Boolean(document?.fullscreenElement));
    };

    screenfull.isEnabled && screenfull?.on('change', handler);
    return () => {
      screenfull.isEnabled && screenfull?.off('change', handler);
    };

    //eslint-disable-next-line
  }, []);

  const sizeControl = isFullscreen ? 'md' : 'sm';

  return (
    <div
      className={cn(
        'absolute bottom-0 flex flex-col w-full bg-gradient-to-t from-base-black to-base-black/0',
        styles.sizes[isFullscreen ? 'lg' : size]
      )}
    >
      <PlayerProgress playerRef={playerRef} />
      <div className="relative flex justify-between items-center mt-auto">
        <PlayerDuration />
        <div className="flex flex-nowrap gap-x-1">
          {isFinished ? (
            <PlayerButton
              size={sizeControl}
              variant="transparent"
              onClick={() => handleRetry(playerRef)}
            >
              <Icon svg={Icons.Retry} size={sizeControl} />
            </PlayerButton>
          ) : (
            <PlayerButton
              size={sizeControl}
              variant="transparent"
              onClick={handleToggle}
            >
              <Icon
                svg={isPlaying ? Icons.Pause : Icons.Play}
                size={sizeControl}
              />
            </PlayerButton>
          )}
          <PlayerVolume isSliderVisible />
        </div>
        <div className="flex flex-nowrap gap-x-1">
          <PlayerPlaybackRate />
          {screenfull.isEnabled && !checkIsIOS() && (
            <PlayerTooltip
              isOpen={isFullscreen || hotKeys ? undefined : false}
              size={sizeControl}
              trigger={
                <PlayerButton
                  size={sizeControl}
                  variant="transparent"
                  onClick={onChangeFullscreen}
                >
                  <Icon
                    svg={
                      isFullscreen ? Icons.FullscreenOff : Icons.FullscreenOn
                    }
                    size={sizeControl}
                  />
                </PlayerButton>
              }
            >
              <span>{isFullscreen ? 'Exit full screen' : 'Full screen'}</span>
              <PlayerKey size={sizeControl}>F</PlayerKey>
            </PlayerTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

import { FC } from 'react';
import { findDOMNode } from 'react-dom';

import screenfull from 'screenfull';

import { useHotkeys as hotKeys } from 'react-hotkeys-hook';
import { usePlayer, usePlayerActions } from '../../hooks';

export const PlayerHotKeys: FC = () => {
  const { config } = usePlayer();
  const { handleToggleVolume, handleSetPlaybackRate } = usePlayerActions();

  const { wrapperRef } = config;

  hotKeys('m', () => handleToggleVolume());
  hotKeys('s', () => handleSetPlaybackRate());
  hotKeys('f', () => {
    screenfull.isEnabled &&
      screenfull.toggle(findDOMNode(wrapperRef.current) as Element);
  });

  return null;
};

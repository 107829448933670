import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useMemo } from 'react';
import cn from 'classnames';
import {
  ContactCardAttrs,
  deserializeContacts,
} from '@distribute/shared/generate-html';
import { ContactLink } from './lib/ContactLink';
import { Avatar } from '../../../../shared/ui';

export type ContactCardItemNodeType = NodeViewProps['node'] & {
  attrs: ContactCardAttrs;
};

export type ContactCardItemNodeViewProps = NodeViewProps & {
  node: ContactCardItemNodeType;
};

export const ContactCardNodeView = ({ node }: ContactCardItemNodeViewProps) => {
  const contacts = useMemo(
    () => deserializeContacts(node.attrs.serializedContacts),
    [node.attrs.serializedContacts]
  );

  return (
    <NodeViewWrapper className="!border-none">
      <div
        data-type="contactCard"
        className="relative border rounded-2xl select-none border-gray-300 flex flex-row sm:flex-col overflow-hidden text-gray-900"
      >
        <div
          className={cn('flex-1 p-8', {
            hidden: !node.attrs.isDescriptionBlockVisible,
          })}
        >
          <p className="whitespace-pre-wrap !m-0 sm:text-base text-lg">
            {node.attrs.description}
          </p>
        </div>
        <div className="flex-1 p-8 bg-gray-100 flex flex-col gap-y-8 overflow-hidden">
          <div className="flex flex-row gap-x-3">
            <Avatar
              displayName={node.attrs.name || node.attrs.initialName}
              src={node.attrs.photoUrl}
              className={cn('h-14 w-14 border border-light-6', {
                hidden: !node.attrs.isPhotoBlockVisible,
              })}
              isUserAvatar={true}
            />
            <div className="flex flex-col flex-1 justify-center">
              <span className="sm:text-base text-lg font-semibold">
                {node.attrs.name}
              </span>
              <span
                className={cn('sm:text-base text-lg text-gray-600', {
                  hidden: !node.attrs.jobTitle && !node.attrs.company,
                })}
              >
                {[node.attrs.jobTitle, node.attrs.company]
                  .filter(Boolean)
                  .join(' • ')}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-3">
            {contacts.map((contact) => (
              <ContactLink {...contact} type={contact.type} key={contact.id} />
            ))}
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export type DesktopStream = {
  streamId: string | null;
  options: chrome.desktopCapture.StreamOptions;
};

export const getVideoMedia = (deviceId: string) => {
  return navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      deviceId: {
        exact: deviceId,
      },
      aspectRatio: { exact: 1 },
      frameRate: {
        ideal: 30,
        max: 30,
      },
      width: {
        ideal: 1280,
        max: 1280,
      },
      height: {
        ideal: 1280,
        max: 1280,
      },
    },
  });
};

export const getAudioMedia = (deviceId: string) => {
  return navigator.mediaDevices.getUserMedia({
    audio: {
      deviceId: {
        exact: deviceId,
      },
      autoGainControl: true,
      echoCancellation: true,
      noiseSuppression: true,
    },
    video: false,
  });
};

type Params = {
  stream: MediaStream;
  ondataavailable: (event: BlobEvent) => void;
  timeSlice: number;
};

export class MediaRecorderProxy {
  private readonly timeSlice: number;
  private readonly stream: MediaStream;
  private readonly cb: (event: BlobEvent) => void;

  private mediaRecorder: MediaRecorder;

  public state: 'running' | 'paused' | 'stopped' | 'restarted' | 'inactive' =
    'inactive';

  constructor({ stream, ondataavailable, timeSlice }: Params) {
    this.mediaRecorder = new MediaRecorder(stream);
    this.stream = stream;
    this.cb = ondataavailable;
    this.timeSlice = timeSlice;
  }

  public start() {
    this.mediaRecorder.addEventListener('dataavailable', this.cb);
    this.mediaRecorder.start(this.timeSlice);

    this.state = 'running';
  }

  public stop() {
    this.mediaRecorder.stop();

    // There is an issue with missing last chunk.
    setTimeout(() => {
      this.mediaRecorder.removeEventListener('dataavailable', this.cb);
      this.state = 'stopped';
    }, 0);
  }

  public resume() {
    this.mediaRecorder.resume();
    this.state = 'running';
  }

  public pause() {
    this.mediaRecorder.pause();
    this.state = 'paused';
  }

  public reset() {
    this.destroy();

    this.mediaRecorder = new MediaRecorder(this.stream);
    this.mediaRecorder.addEventListener('dataavailable', this.cb);

    this.state = 'restarted';
  }

  public destroy() {
    this.mediaRecorder.stop();
    this.mediaRecorder.removeEventListener('dataavailable', this.cb);
  }
}

import { FC, useRef } from 'react';

import { useResizeObserver } from '@distribute/frontend/utils';

import { type } from '../../config';

import { PlayerSettings } from '../../types';
import { PlayerProvider } from '../../provider/player';

import { PlayerError } from './PlayerError';
import { PlayerView } from './PlayerView';

import { PlayerErrorBoundary } from '../PlayerErrorBoundary';

export const Player: FC<PlayerSettings> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useResizeObserver(ref);

  return (
    <div
      ref={ref}
      style={{ aspectRatio: props.aspectRatio }}
      className="overflow-hidden relative bg-base-white cursor-auto outline-none"
      data-type={type}
    >
      <PlayerErrorBoundary fallback={<PlayerError />}>
        <PlayerProvider {...props} entry={entry} wrapperRef={ref}>
          <PlayerView />
        </PlayerProvider>
      </PlayerErrorBoundary>
    </div>
  );
};

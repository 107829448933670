import { DeviceSource } from '../../../model/types';

import { logger } from '../../../../../shared/lib';

export async function getDevices() {
  const videos: DeviceSource[] = [];
  const audios: DeviceSource[] = [];

  try {
    let devices = await navigator.mediaDevices.enumerateDevices();
    devices = devices.filter((d) => Boolean(d.deviceId));

    devices.forEach(function (device) {
      if (device.kind === 'videoinput') {
        videos.push({ label: device.label, id: device.deviceId });
      }
      if (device.kind === 'audioinput') {
        audios.push({ label: device.label, id: device.deviceId });
      }
    });

    return { videos, audios };
  } catch (e: unknown) {
    logger.error(e);

    return { videos, audios };
  }
}

import { SubscriptionPlanName } from './subscription';
import { VideoQuality } from './video-record';

export type PermissionSubscriptionLimitConfig = {
  maxPublishedPagesCount: number;
  maxAIMonthlyResponsesCount: number;
  maxVideoRecords: number;
  maxVideoRecordDuration: number;
  maxVideoRecordSize: number;
  allowVideoDownload: boolean;
  videoRecordSessionLimit: number;
  videoRecordQuality: VideoQuality[];
  videoRecordWatermark: boolean;
  snippets: boolean;
  maxVideoNarrationDuration: number;
  maxVideoNarrationSize: number;
  videoNarrationSessionLimit: number;
  videoNarrationUnlimitedDuration: boolean;
};

export type PermissionSubscriptionLimits = Record<
  SubscriptionPlanName,
  PermissionSubscriptionLimitConfig
>;

export const subscriptionLimits: PermissionSubscriptionLimits = {
  free: {
    maxPublishedPagesCount: 5,
    maxAIMonthlyResponsesCount: 20,
    maxVideoRecords: 10,
    maxVideoRecordDuration: 5, //5min
    maxVideoRecordSize: 1024 * 1024 * 100, //100MB
    allowVideoDownload: false,
    videoRecordSessionLimit: 15, //15min
    videoRecordQuality: [VideoQuality.HD],
    videoRecordWatermark: true,
    snippets: false,
    maxVideoNarrationDuration: 5, //5min
    maxVideoNarrationSize: 1024 * 1024 * 100, //100MB
    videoNarrationSessionLimit: 15, //15min
    videoNarrationUnlimitedDuration: false,
  },
  pro: {
    maxPublishedPagesCount: Number.POSITIVE_INFINITY,
    maxAIMonthlyResponsesCount: Number.POSITIVE_INFINITY,
    maxVideoRecords: Number.POSITIVE_INFINITY,
    maxVideoRecordDuration: 60, //1hour
    maxVideoRecordSize: 1024 * 1024 * 1024, //1GB
    allowVideoDownload: true,
    videoRecordSessionLimit: 90, //90min
    videoRecordQuality: [VideoQuality.HD, VideoQuality.FULLHD],
    videoRecordWatermark: false,
    snippets: true,
    maxVideoNarrationDuration: 60, //1hour
    maxVideoNarrationSize: 1024 * 1024 * 1024, //1GB
    videoNarrationSessionLimit: 90, //15min
    videoNarrationUnlimitedDuration: true,
  },
  team: {
    maxPublishedPagesCount: Number.POSITIVE_INFINITY,
    maxAIMonthlyResponsesCount: Number.POSITIVE_INFINITY,
    maxVideoRecords: Number.POSITIVE_INFINITY,
    maxVideoRecordDuration: 60, //1hour
    maxVideoRecordSize: 1024 * 1024 * 1024, //1GB
    allowVideoDownload: true,
    videoRecordSessionLimit: 90, //90min
    videoRecordQuality: [VideoQuality.HD, VideoQuality.FULLHD],
    videoRecordWatermark: false,
    snippets: true,
    maxVideoNarrationDuration: 60, //1hour
    maxVideoNarrationSize: 1024 * 1024 * 1024, //1GB
    videoNarrationSessionLimit: 90, //15min
    videoNarrationUnlimitedDuration: true,
  },
};

import { BubbleMenu, EditorEvents } from '@tiptap/react';
import { useTiptapEditor } from '../../lib';
import {
  BubbleMenuIconButton,
  BubbleMenuToolbar,
} from '../../../../entities/tiptap-editor';
import { IconMap } from '../../../../shared/sprite';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../../../features/editor-sidebar';

export const TiptapContactCardBubbleMenu = () => {
  const { editor } = useTiptapEditor();
  const dispatch = useDispatch();

  const openSidebarEditor = useCallback(() => {
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.CONTACT_CARD_EDITOR
      )
    );

    const closeSidebarEditorOnBlur = ({
      editor: ed,
    }: EditorEvents['selectionUpdate']) => {
      if (!ed.isActive('contactCard')) {
        dispatch(editorSidebarModel.actions.setSidebarOpen(false));
        dispatch(
          editorSidebarModel.actions.setActiveConversionSettingsPanel(null)
        );
      }
      ed.off('selectionUpdate', closeSidebarEditorOnBlur);
    };

    editor?.on('selectionUpdate', closeSidebarEditorOnBlur);
  }, [dispatch, editor]);

  const deleteCurrentNode = useCallback(() => {
    if (editor) {
      editor.commands.deleteSelection();
    }
  }, [editor]);

  if (!editor) return null;

  return (
    <BubbleMenu
      tippyOptions={{
        duration: 200,
        placement: 'top',
        maxWidth: 'none',
        offset: [0, -18],
      }}
      editor={editor}
      shouldShow={() => editor.isActive('contactCard')}
      updateDelay={0}
    >
      <BubbleMenuToolbar>
        <BubbleMenuIconButton
          iconName={IconMap.Edit05}
          tooltip="Edit content"
          onClick={openSidebarEditor}
        />
        <hr className="block w-px h-6 mx-1 border-none rounded bg-base-black-8"></hr>
        <BubbleMenuIconButton
          iconName={IconMap.Trash}
          tooltip="Delete"
          onClick={deleteCurrentNode}
        />
      </BubbleMenuToolbar>
    </BubbleMenu>
  );
};

import { api } from './instance';
import { getQueryString } from '../../lib';
import {
  GetPagesOverviewQueryParamsType,
  GetPagesOverviewResponseType,
  GetPagesTotalResponseType,
} from '@distribute/shared/api-types/analytics';

export const pagesOverviewApi = {
  getPagesOverview: async (params: GetPagesOverviewQueryParamsType) => {
    const { data } = await api.get<GetPagesOverviewResponseType>(
      `analytics/overview?${getQueryString(params)}`
    );
    return data;
  },

  getPagesTotal: async ({ teamId }: { teamId: number }) => {
    const { data } = await api.get<GetPagesTotalResponseType>(
      `analytics/total?teamId=${teamId}`
    );
    return data;
  },
};

import { setParams } from '@distribute/shared/utils';

import { APP_ORIGIN, PROTOCOL } from '../config';

type GetFullPageUrlProps = {
  slug: string;
  domain: string;
  customDomain?: string;
};

export const getPageUrl = ({
  slug,
  domain,
  customDomain,
}: GetFullPageUrlProps) =>
  customDomain ? `${customDomain}/${slug}` : `${domain}.${APP_ORIGIN}/${slug}`;

export const getFullPageUrl = ({
  slug,
  domain,
  customDomain,
}: GetFullPageUrlProps) => {
  return `${PROTOCOL}${getPageUrl({
    slug,
    domain,
    customDomain,
  })}`;
};

export const getFullPageSeoUrl = ({
  slug,
  domain,
  customDomain,
}: GetFullPageUrlProps) => {
  return `${PROTOCOL}${getPageUrl({
    slug: `p/${slug}`,
    domain,
    customDomain,
  })}`;
};

type GetPageGmailUrlProps = {
  id: string;
  gifImage: string;
  title: string;
  url: string;
};

export const getPageGmailUrl = ({
  id,
  gifImage,
  title,
  url,
}: GetPageGmailUrlProps) => {
  const pageFullUrl = `${url}${setParams({
    dUid: id,
    dGif: gifImage,
  })}`;

  return `https://mail.google.com/mail/u/0/${setParams({
    view: 'cm',
    su: title,
    body: pageFullUrl,
  })}`;
};

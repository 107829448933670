import { put, takeLatest } from 'redux-saga/effects';
import { narrationModel } from '..';
import { noAudio, noVideo } from '../../config';

export function* devicesSagaWorker() {
  yield takeLatest(narrationModel.actions.updateAudio, function* (action) {
    const { payload } = action;
    const { audios: newAudios, audioPermission } = payload;

    const audios = [noAudio, ...newAudios];

    yield put(narrationModel.actions.setAudios(audios));
    yield put(narrationModel.actions.setAudioPermission(audioPermission));

    const newAudioId = audios[1]?.id || noAudio.id;

    yield put(narrationModel.actions.setAudioId(newAudioId));
  });

  yield takeLatest(narrationModel.actions.updateVideo, function* (action) {
    const { payload } = action;
    const { videos: newVideos, videoPermission } = payload;

    const videos = [noVideo, ...newVideos];

    yield put(narrationModel.actions.setVideos(videos));
    yield put(narrationModel.actions.setVideoPermission(videoPermission));

    const newVideoId = videos[1]?.id || noVideo.id;

    yield put(narrationModel.actions.setVideoId(newVideoId));
  });
}

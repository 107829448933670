import { FC, PropsWithChildren, useReducer } from 'react';
import { PlayerBubbleContext } from './PlayerBubbleContext';
import { PlayerBubbleSettings } from '../../types';

import { initialState, reducer } from './reducer';

export const PlayerBubbleProvider: FC<
  PropsWithChildren<PlayerBubbleSettings>
> = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PlayerBubbleContext.Provider value={{ state, dispatch, config: props }}>
      {children}
    </PlayerBubbleContext.Provider>
  );
};

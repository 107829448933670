import { delay, put, select } from 'redux-saga/effects';

import { RT } from '../../../../shared/types';
import { narrationModel } from '../';
import { formatToSeconds } from '../../../../shared/lib';

import { calculateElapsedRecordingDuration } from '../../lib';
import { subscriptionModel } from '../../../subscription';

export function* recorderDurationTimer() {
  while (true) {
    const {
      maxVideoNarrationDuration,
    }: RT<typeof subscriptionModel.selectors.selectSubscriptionLimits> =
      yield select(subscriptionModel.selectors.selectSubscriptionLimits);

    const { time }: RT<typeof narrationModel.selectors.selectNarration> =
      yield select(narrationModel.selectors.selectNarration);

    if (!Object.keys(time).length) {
      yield delay(1000);
      continue;
    }

    const duration = formatToSeconds(
      calculateElapsedRecordingDuration(time?.start || 0, time?.pauseAll || 0)
    );

    if (duration <= maxVideoNarrationDuration * 60) {
      yield delay(1000);
      continue;
    }

    yield put(narrationModel.actions.stopRecording());
    break;
  }
}

import { FC, useContext } from 'react';
import TextInput from './TextInput';
import context from './context';
import ImageInput from './ImageInput';

const UserInformation: FC = () => {
  const { form } = useContext(context);

  return (
    <div className="flex flex-col gap-y-4 pb-6 border-b border-b-gray-300">
      <h3 className="font-semibold">User information</h3>
      <TextInput label="Name" {...form.register('name')} />
      <TextInput label="Job title" {...form.register('jobTitle')} />
      <TextInput label="Company" {...form.register('company')} />
      <ImageInput />
    </div>
  );
};

export default UserInformation;

import { FC, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { IconMap } from '../../../../shared/sprite';
import { Icon, Loader, SimpleLayout } from '../../../../shared/ui';

import { SKIP_EXTENSION } from '../../../onboarding/config';

import { ChromeExtensionInstall } from './ChromeExtensionInstall';
import { ChromeExtensionGet } from './ChromeExtensionGet';

import { onboardingModel } from '../../../onboarding';

export enum Step {
  Install = 'install',
  Get = 'get',
}

export const ChromeExtensionOnboard: FC = () => {
  const isLoadingSkipExtension = useSelector(
    onboardingModel.selectors.selectIsLoadingSkipExtension
  );
  const [isSkipExtension] = useState(
    () => !!localStorage.getItem(SKIP_EXTENSION)
  );

  const [step, setStep] = useState<Step>(Step.Get);

  const dispatch = useDispatch();

  const onFinish = useCallback(() => {
    localStorage.removeItem(SKIP_EXTENSION);

    dispatch(onboardingModel.actions.finishExtensionOnboarding());

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSkipExtension) {
      onFinish();
    }

    //eslint-disable-next-line
  }, [isSkipExtension]);

  if (isLoadingSkipExtension || isSkipExtension) {
    return <Loader />;
  }

  return (
    <SimpleLayout hideLogo hideImage classNameContent="!pt-24 w-140">
      <div className="flex flex-col items-center">
        <Icon
          glyph={IconMap.GoogleChrome}
          className="text-primary-700 self-center"
          width={64}
        />
        {step === Step.Get && (
          <ChromeExtensionGet
            onFinish={onFinish}
            setStep={setStep}
            loading={isLoadingSkipExtension}
          />
        )}
        {step === Step.Install && (
          <ChromeExtensionInstall
            onFinish={onFinish}
            loading={isLoadingSkipExtension}
          />
        )}
      </div>
    </SimpleLayout>
  );
};
